import React from "react";
import SubmittedAgreement from "../sumitted_agreement/SubmittedAgreement";

const ReplyOrderedAgreement = () => {
  const fileStatus = 24;
  const applicationPath = {
    copyright: "/admin/reply-ordered-copyright",
    agreement: "/admin/reply-ordered-agreement",
  };

  // Add route to details page
  const detailsRoute = "/admin/submitted-agreement-details-verify";
  const pageTitle = "লাইব্রেরিয়ান প্রদত্ত নথি";

  return (
    <SubmittedAgreement
      fileStatus={fileStatus}
      applicationPath={applicationPath}
      detailsRoute={detailsRoute}
      pageTitle={pageTitle}
    />
  );
};

export default ReplyOrderedAgreement;
