import { CheckCircle } from "@mui/icons-material";
import React from "react";
import { Modal, Table } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import { useGetPaymentViewQuery } from "../../../../redux/api/reportApiSlice";
import { convertToBanglaNumerals } from "../../../../utils/convertToBanglaNumerals";
import { localDateTimeFormat } from "../../../../utils/dateFormat";

const ShowPayment = ({ data, show, onHide }) => {
  const {
    data: getView,
    isLoading,
    isError,
  } = useGetPaymentViewQuery(data?.id);

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {Number(getView?.profile?.apptype) === 1
            ? "কপিরাইট আবেদনঃ"
            : "চুক্তিপত্র নিবন্ধনঃ"}{" "}
          ট্রানজেকশনের বিবরণ
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <BeatLoader />
        ) : (
          <>
            <section className="mb-4">
              <h5>ট্রানজেকশন তথ্য</h5>
              <Table bordered striped hover>
                <tbody>
                  <tr>
                    <td>
                      <strong>আবেদন নং</strong>
                    </td>
                    <td>{getView.profile.trackReg}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>ট্রানজেকশন নাম্বার</strong>
                    </td>
                    <td>{getView.trnxId}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>আইপিএন নাম্বার</strong>
                    </td>
                    <td>{getView.ipnTxid}</td>
                  </tr>

                  <tr>
                    <td>
                      <strong>ই-চালান নাম্বার</strong>
                    </td>
                    <td>{getView.profile.echallanId}</td>
                  </tr>

                  <tr>
                    <td>
                      <strong>টাকার পরিমান</strong>
                    </td>
                    <td>৳{convertToBanglaNumerals(getView.amount)}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>স্ট্যাটাস</strong>
                    </td>
                    <td>{getView.status}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>তারিখ</strong>
                    </td>
                    <td>{localDateTimeFormat(getView.trnxDate)}</td>
                  </tr>
                </tbody>
              </Table>
            </section>

            <section className="mb-4">
              <h5>ট্রানজেকশনকারীর তথ্য</h5>
              <Table bordered striped hover>
                <tbody>
                  <tr>
                    <td>
                      <strong>নাম</strong>
                    </td>
                    <td>{getView.name}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>ই-মেইল</strong>
                    </td>
                    <td>{getView.email}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>ঠিকানা</strong>
                    </td>
                    <td>{getView.address}</td>
                  </tr>
                </tbody>
              </Table>
            </section>
            <div className="text-center">
              <CheckCircle
                className={
                  getView?.confirmedIpn ? "text-green" : "text-secondary"
                }
              />
            </div>
          </>
        )}
        {isError && <p>কোন তথ্য পাওয়া যায়নি।</p>}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={onHide}>
          বন্ধ করুন
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ShowPayment;
