import unauthorize from "./images/401.png";
import notFound from "./images/404.jpg";
import avatar from "./images/demo-user.png";
import error from "./images/failed.svg";
import gov from "./images/govt_logo.png";
import echalan from "./images/logo-bangla.png";
import logo from "./images/logo3.png";
import logoo from "./images/logoo.png";
import pay from "./images/paynow.png";
import success from "./images/success.svg";

import approve from "./images/c-2/approve.png";
import arun from "./images/c-2/arun.png";
import chalan from "./images/c-2/chalan.jpg";
import idcard from "./images/c-2/idcard.jpg";
import incorporation from "./images/c-2/incorporation.jpg";
import license from "./images/c-2/license.jpg";
import noc from "./images/c-2/noc.jpg";
import photo from "./images/c-2/photo.jpg";
import sommoti from "./images/c-2/sommoti.png";
import TIN from "./images/c-2/TIN.jpg";
import sign from "./images/signature.png";
import sign2 from "./images/signature2.png";
import procchod from "./images/c-2/procchod.jpg";

export const assets = {
  logo: logo,
  avatar: avatar,
  notFound: notFound,
  unauthorize: unauthorize,
  error: error,
  success: success,
  echalan: echalan,
  pay: pay,
  gov: gov,
  logoBlack: logoo,
  sign: sign,
  sign2: sign2,
  photo: photo,
  idcard: idcard,
  TIN: TIN,
  approve: approve,
  arun: arun,
  chalan: chalan,
  incorporation: incorporation,
  license: license,
  noc: noc,
  sommoti: sommoti,
  procchod,
};
