import { Home, PaymentsOutlined } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";
import debounce from "lodash/debounce";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { FaEye } from "react-icons/fa";
import ReusableTable from "../../../../components/custom/data_table/ReusableTable";
import IconBreadcrumbs from "../../../../components/custom/IconBreadcrumbs";
import {
  useGetAgreementReportsQuery,
  useGetCopyrightReportsQuery,
  useGetPaymentsListQuery,
} from "../../../../redux/api/reportApiSlice";
import { convertToBanglaNumerals } from "../../../../utils/convertToBanglaNumerals";
import { localDateFormat } from "../../../../utils/dateFormat";
import CommonAgreementCopyright from "./CommonAgreementCopyright";
import ShowPayment from "./ShowPayment";

const Payments = () => {
  const currentDate = moment().format("YYYY-MM-DD"); // Current date
  const threeMonthsAgo = moment().subtract(2, "months").format("YYYY-MM-DD");
  const [fromDate, setFromDate] = useState(threeMonthsAgo);
  const [toDate, setToDate] = useState(currentDate);
  const [appType, setAppType] = useState("");
  const [trackReg, setTrackReg] = useState("");
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(1);

  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [viewData, setViewData] = useState(null);
  const [viewModal, setViewModal] = useState(false);

  const { data, isLoading, refetch } = useGetPaymentsListQuery({
    fromDate,
    toDate,
    appType,
    filter: searchValue,
    trackReg,
    status,
  });

  /// copyright application length
  const { data: copyright } = useGetCopyrightReportsQuery();
  /// agreement application length
  const { data: agreement } = useGetAgreementReportsQuery();

  const total = {
    copyright: copyright?.meta?.total ?? 0,
    agreement: agreement?.meta?.total ?? 0,
  };

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: PaymentsOutlined,
      title: "ই-পেমেন্ট",
      active: true,
    },
  ];

  // data table start
  const totalData = data?.meta?.total;
  const lastPage = data?.meta?.last_page;

  const debouncedSearch = useCallback(
    debounce((value) => {
      setPage(1);
      setSearchValue(value);
      refetch();
    }, 400),
    []
  );

  useEffect(() => {
    // Cleanup function to cancel the debounce when the component unmounts or debouncedSearch changes
    return () => {
      debouncedSearch?.cancel();
    };
  }, [debouncedSearch]);

  const handleSearchChange = (value) => {
    setSearchText(value);
    debouncedSearch(value);
  };
  const handlePageChange = (page) => {
    setPage(page);
    refetch();
  };

  const columns = [
    { label: "তারিখ", name: "trnxDate" },
    {
      label: "আবেদন নং",
      name: "trackReg",
    },
    { label: "ধরণ", name: "apptype" },
    {
      label: "ট্রানজেকশন নাম্বার",
      name: "trnxId",
    },
    {
      label: "ই-চালান নাম্বার",
      name: "echallanId",
    },

    { label: "টাকা", name: "tk" },

    {
      label: "ক্রিয়াকলাপ",
      name: "action",
      options: {
        customBodyRender: (value, tableMeta) => (
          <Box display={"flex"} gap={1}>
            <Tooltip title="দেখুন" placement="top" arrow>
              <Badge
                bg="warning"
                className="p-2"
                onClick={() => {
                  setViewData(tableMeta?.rowData);
                  setViewModal(true);
                }}
                style={{ cursor: "pointer" }}
              >
                <FaEye fontSize="8px" />
              </Badge>
            </Tooltip>
          </Box>
        ),
      },
    },
  ];

  const dataTable = data?.data.map((item, i) => ({
    trnxDate: localDateFormat(item?.trnxDate),
    echallanId: item?.profile?.echallanId,
    tk: "৳" + convertToBanglaNumerals(item?.amount),
    trackReg: item?.profile?.trackReg,
    apptype: Number(item?.profile?.apptype) === 1 ? "কপিরাইট " : "চুক্তিপত্র ",
    ...item,
  }));

  const applicationPath = {
    agreement: "/admin/agreement-report",
    copyright: "/admin/copyright-report",
  };

  return (
    <div>
      <div className="py-3">
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </div>
      <div className="slideUp dashboard-layout p-4">
        <div className="pb-1">
          <CommonAgreementCopyright
            total={total}
            applicationPath={applicationPath}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            appType={appType}
            setAppType={setAppType}
            onSearchChange={handleSearchChange}
            searchText={searchText}
            trackReg={trackReg}
            setTrackReg={setTrackReg}
            status={status}
            setStatus={setStatus}
          />
        </div>
        <ReusableTable
          columns={columns}
          data={dataTable}
          isLoading={isLoading}
          currentPage={page}
          lastPage={lastPage}
          totalData={totalData}
          onPageChange={handlePageChange}
        />

        {viewData && (
          <ShowPayment
            show={viewModal}
            data={viewData}
            onHide={() => {
              setViewModal(false);
              setViewData(null);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Payments;
