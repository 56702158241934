// utils.js

export const CopyrightGenerateAttachArray = (forInfo) => {
  const attach = {};

  if (forInfo?.category === 2) {
    attach[1] = "গানের তালিকা";
  }
  attach[2] =
    forInfo.st === 3 ? "ই-চালান নাম্বার" : "ট্রেজারি চালানের স্ক্যান কপি";
  attach[3] = "কর্মের সফট কপি";
  attach[4] = "আবেদনকারীর ছবি";

  if (forInfo?.category === 1) {
    attach[5] = "প্রচ্ছদ কর্মের হস্তান্তর পত্র";
  }

  switch (forInfo.selfType) {
    case 1:
      attach[13] = "অনাপত্তি পত্রের/হস্তান্তর দলিলের স্ক্যান কপি";
      break;
    case 2:
      attach[14] = "অনাপত্তি পত্রের / সম্মতি পত্রের স্ক্যান কপি";
      break;
    case 3:
      attach[7] = "হস্তান্তর দলিলের স্ক্যান কপি";
      break;
    case 4:
      attach[6] = "নিয়োগ পত্রের স্ক্যান কপি";
      break;
    case 5:
      attach[17] = "উত্তরাধিকার সনদপত্রের স্ক্যান কপি";
      attach[18] = "অনাপত্তি পত্রের/সম্মতি পত্রের/হস্তান্তর দলিলের স্ক্যান কপি";
      break;
    case 6:
      attach[20] = "মূল প্রণেতার অনাপত্তিপত্রের/সন্মতিপত্রের স্ক্যান কপি";
      break;
    case 7:
      attach[19] =
        "মূল লেখকের/ প্রকাশকের/বর্তমান কপিরাইট স্বত্বাধিকারীর সন্মতিপত্রের/অনুমতিপত্রের স্ক্যান কপি";
      break;
    case 8:
      attach[21] = "প্রাসঙ্গিক / প্রমানদির স্ক্যান কপি";
      break;
    default:
      break;
  }

  if (forInfo?.category === 7) {
    attach[22] = "চলচ্চিত্র সেন্সর সনদের কপি";
  }

  if (forInfo.type === 1 || forInfo.type === 2) {
    attach[10] = "জাতীয় পরিচয় পত্রের কপি";
  } else if (forInfo.type === 3) {
    attach[8] = "প্রতিষ্ঠানের ট্রেড লাইসেন্সের কপি";
    attach[9] = "প্রতিষ্ঠানের মেমোরান্ডামের কপি";
    attach[10] = "জাতীয় পরিচয় পত্রের কপি";
    attach[12] = "প্রতিষ্ঠানের টিন সার্টিফিকেটের কপি";
    attach[16] = "প্রতিষ্ঠানের গঠনতন্ত্রের স্ক্যান কপি";
  }

  if (forInfo.powerType === 1) {
    attach[11] = "লাইসেন্স প্রদানের ক্ষমতা পত্রের কপি";
  }

  if (forInfo.basicWorktype === 2 && forInfo.authorize === 1) {
    attach[15] = "অনুমোদনের স্ক্যান কপি";
  }

  return attach;
};

export const CopyrightKeyAttachArray = (forInfo) => {
  const attach = {};

  attach[1] = "গানের তালিকা";
  attach[2] =
    forInfo.st === 3 ? "ই-চালান নাম্বার" : "ট্রেজারি চালানের স্ক্যান কপি";
  attach[3] = "কর্মের সফট কপি";
  attach[4] = "আবেদনকারীর ছবি";
  attach[5] = "প্রচ্ছদ কর্মের হস্তান্তর পত্র";
  attach[13] = "অনাপত্তি পত্রের/হস্তান্তর দলিলের স্ক্যান কপি";
  attach[14] = "অনাপত্তি পত্রের / সম্মতি পত্রের স্ক্যান কপি";
  attach[7] = "হস্তান্তর দলিলের স্ক্যান কপি";
  attach[6] = "নিয়োগ পত্রের স্ক্যান কপি";
  attach[17] = "উত্তরাধিকার সনদপত্রের স্ক্যান কপি";
  attach[18] = "অনাপত্তি পত্রের/সম্মতি পত্রের/হস্তান্তর দলিলের স্ক্যান কপি";
  attach[20] = "মূল প্রণেতার অনাপত্তিপত্রের/সন্মতিপত্রের স্ক্যান কপি";
  attach[19] =
    "মূল লেখকের/ প্রকাশকের/বর্তমান কপিরাইট স্বত্বাধিকারীর সন্মতিপত্রের/অনুমতিপত্রের স্ক্যান কপি";
  attach[21] = "প্রাসঙ্গিক / প্রমানদির স্ক্যান কপি";
  attach[22] = "চলচ্চিত্র সেন্সর সনদের কপি";
  attach[10] = "জাতীয় পরিচয় পত্রের কপি";
  attach[8] = "প্রতিষ্ঠানের ট্রেড লাইসেন্সের কপি";
  attach[9] = "প্রতিষ্ঠানের মেমোরান্ডামের কপি";
  attach[12] = "প্রতিষ্ঠানের টিন সার্টিফিকেটের কপি";
  attach[16] = "প্রতিষ্ঠানের গঠনতন্ত্রের স্ক্যান কপি";
  attach[11] = "লাইসেন্স প্রদানের ক্ষমতা পত্রের কপি";
  attach[15] = "অনুমোদনের স্ক্যান কপি";

  // Function to get the title based on the key
  const getAttachmentTitle = (item) => {
    const [key] = item.split(":"); // Split the string 'key:value', extract key
    return attach[Number(key)] || "No title found";
  };

  // Create a single object to store key-value pairs
  const result = {};

  forInfo?.status.forEach((item) => {
    const [key] = item.split(":"); // Extract key
    result[key] = getAttachmentTitle(item); // Set key-value pair in the result object
  });

  return result;
};

export const CopyrightGenerateValue = (forInfo) => {
  const attach = {};

  if (forInfo?.category === 2) {
    attach[1] = forInfo.musicOrFilm;
  }
  attach[2] = forInfo.treasury;
  attach[3] = forInfo.workSoftCopies;
  attach[4] = forInfo.image;

  if (forInfo?.category === 1) {
    attach[5] = forInfo.publish;
  }

  switch (forInfo.selfType) {
    case 1:
      attach[13] = forInfo.selfTypes;
      break;
    case 2:
      attach[14] = forInfo.selfTypes;
      break;
    case 3:
      attach[7] = forInfo.selfTypes;
      break;
    case 4:
      attach[6] = forInfo.selfTypes;
      break;
    case 5:
      attach[17] = forInfo.selfTypes;
      attach[18] = forInfo.selfTypes;
      break;
    case 6:
      attach[20] = forInfo.selfTypes;
      break;
    case 7:
      attach[19] = forInfo.selfTypes;
      break;
    case 8:
      attach[21] = forInfo.selfTypes;
      break;
    default:
      break;
  }

  if (forInfo?.category === 7) {
    attach[22] = forInfo.musicOrFilm;
  }

  if (forInfo.type === 1 || forInfo.type === 2) {
    attach[10] = forInfo.nid;
  } else if (forInfo.type === 3) {
    attach[8] = forInfo?.tradeLicense;
    attach[9] = forInfo.memorandum;
    attach[10] = forInfo.nid;
    attach[12] = forInfo.tinCertificate;
    attach[16] = forInfo.inst_struct;
  }

  if (forInfo.powerType === 1) {
    attach[11] = forInfo.license_pay;
  }

  if (forInfo.basicWorktype === 2 && forInfo.authorize === 1) {
    attach[15] = "অনুমোদনের স্ক্যান কপি";
  }

  return attach;
};
