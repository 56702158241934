import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  application_id: Yup.number().required("আবেদন আইডি প্রযোজ্য"),
  st: Yup.number().required("এসটি প্রযোজ্য"),
  name: Yup.string().when("st", {
    is: 3,
    then: () => Yup.string().required("নাম প্রযোজ্য"),
  }),
  email: Yup.string().when("st", {
    is: 3,
    then: () =>
      Yup.string().email("ইমেইল ঠিকানা সঠিক নয়").required("ইমেইল প্রযোজ্য"),
  }),
  mobile: Yup.string().when("st", {
    is: 3,
    then: () =>
      Yup.string()
        .matches(
          /^01[0-9]{9}$/,
          "মোবাইল নাম্বার সঠিক নয়, ০১ দিয়ে শুরু হওয়া ১১ সংখ্যার হতে হবে"
        )
        .required("মোবাইল নাম্বার প্রযোজ্য"),
  }),
  amountFor: Yup.number().when("st", {
    is: (value) => value === 2 || value === 3,
    then: () => Yup.number().required("টাকার পরিমাণ প্রযোজ্য।"),
  }),
  treasuryNo: Yup.string().when("st", {
    is: 2,
    then: () => Yup.string().required("ট্রেজারি চালান নং প্রযোজ্য।"),
  }),
  treasuryDate: Yup.date().when("st", {
    is: 2,
    then: () => Yup.date().required("ট্রেজারির তারিখ প্রযোজ্য।"),
  }),
  treasuryBank: Yup.string().when("st", {
    is: 2,
    then: () => Yup.string().required("ব্যাংকের নাম প্রযোজ্য।"),
  }),
  bankbranch_id: Yup.string().when("st", {
    is: 2,
    then: () => Yup.string().required("শাখার নাম প্রযোজ্য।"),
  }),
  treasury: Yup.string().when("st", {
    is: 2,
    then: () =>
      Yup.mixed()
        .required("ট্রেজারি চালান কপি  প্রযোজ্য।")
        .test(
          "fileType",
          "শুধুমাত্র ইমেজ ফাইলগুলি অনুমোদিত",
          (value) =>
            value &&
            [
              "image/jpeg",
              "image/png",
              "image/jpg",
              "application/pdf",
            ].includes(value.type)
        ),
  }),
});

export const initialValues = {
  application_id: "",
  st: 3,
  name: "",
  email: "",
  mobile: "",
  amountFor: "",
  treasuryNo: "",
  treasuryDate: "",
  treasuryBank: "",
  bankbranch_id: "",
  treasury: "",
};
