import React from "react";
import { Link, useParams } from "react-router-dom";
import Loader from "../../../../components/custom/Loader";
import { useGetAgreementDetailsQuery } from "../../../../redux/api/agreementsApiSlice";
import { localDateFormat } from "../../../../utils/dateFormat";
import styles from "./ContractCopyTwo.module.css";

const ContractTwoPreview = () => {
  const contractId = useParams();
  const { data, isLoading } = useGetAgreementDetailsQuery(contractId?.id);
  const alldata = data?.data;

  if (isLoading) {
    return <Loader />;
  }

  const {
    profiletypea = [],
    profiletypeb = [],
    profiletypec = [],
    profiletyped = [],
  } = data?.data || {};

  return (
    <div>
      <section className="section pt-3 pb-5 chuktipotro2 service-single">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <div className="card w-100">
              <nav>
                <div className="nav nav-tabs " id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-application-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-application"
                    type="button"
                    role="tab"
                    aria-controls="nav-application"
                    aria-selected="true"
                  >
                    দ্বিতীয় অংশ
                  </button>
                </div>
              </nav>
              <div
                className="tab-content  p-4 border border-top-0 "
                id="nav-tabContent"
              >
                <div
                  className="tab-pane fade active show"
                  id="nav-application"
                  role="tabpanel"
                  aria-labelledby="nav-application-tab"
                >
                  <div className="applicationForm">
                    <div
                      className={`${styles.applicationFormheader} text-center`}
                    >
                      <div class="applicationForm-header text-center add2-application-header">
                        <h4>বিষয়াবলীর বিবরণী</h4>{" "}
                        <p>
                          {" "}
                          <b>(দুটি প্রতিলিপি পাঠাতে হবে) </b>{" "}
                        </p>
                      </div>
                    </div>

                    <div className="my-2">
                      <div className={styles.add2sectiontitle}>
                        <div className="d-flex">
                          <p className="mt-2">১. নিবন্ধন সংখ্যাঃ </p>
                          <p className="ms-5 px-3 py-2 rounded border text-success">
                            {" "}
                            কপিরাইট অফিস কর্তৃক পূরণীয়
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className={styles.add2sectiontitle}>
                        <p className="mb-1"> ২. আবেদনকারীর তথ্যঃ</p>
                      </div>
                      <div className="table-responsive">
                        <table className="table text-center table-striped mb-2 table-bordered hover">
                          <thead>
                            <tr>
                              <th>আবেদনকারীর নাম</th>
                              <th>ই-মেইল</th>
                              <th>মোবাইল নাম্বার</th>
                              <th>ঠিকানা</th>
                              <th>জাতীয়তা</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{alldata?.profile?.puname}</td>
                              <td>{alldata?.profile?.user?.email}</td>
                              <td>{alldata?.profile?.mobileNo}</td>
                              <td>{alldata?.profile?.communicationAddress}</td>
                              <td>{alldata?.nationality}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="my-2">
                      <div className={styles.add2sectiontitle}>
                        <p className="mb-1">৩.পক্ষদ্বয়ের নাম ও ঠিকানাঃ</p>
                      </div>

                      <fieldset className="">
                        <div className="table-responsive">
                          <table className="table text-center table-striped mb-2 table-bordered hover">
                            <thead>
                              <tr className="text-start">
                                <th colSpan={7}>ক. ১ম পক্ষ হস্তান্তর দাতা</th>
                              </tr>
                            </thead>
                            <thead>
                              <tr>
                                <th>পূর্ণ নাম</th>
                                <th>পিতার নাম</th>
                                <th>মাতার নাম</th>
                                <th>ঠিকানা</th>
                                <th>ভোটার আইডি নং</th>
                                <th>মোবাইল নং</th>
                                <th>ই-মেইল</th>
                              </tr>
                            </thead>
                            <tbody>
                              {profiletypea?.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.name}</td>
                                  <td>{item.fathername}</td>
                                  <td>{item.mothername}</td>
                                  <td>{item.address}</td>
                                  <td>{item.nidno}</td>
                                  <td>{item.mobile}</td>
                                  <td>{item.email}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </fieldset>

                      <fieldset className="mt-3">
                        <div className="table-responsive">
                          <table className="table text-center table-striped mb-2 table-bordered hover">
                            <thead>
                              <tr className="text-start">
                                <th colSpan={7}>
                                  খ. ২য় পক্ষ সত্মনিয়োগী / লাইসেন্স গ্রহীতা
                                </th>
                              </tr>
                            </thead>

                            <thead>
                              <tr>
                                <th>পূর্ণ নাম</th>
                                <th>পিতার নাম</th>
                                <th>মাতার নাম</th>
                                <th>ঠিকানা</th>
                                <th>ভোটার আইডি নং</th>
                                <th>মোবাইল নং</th>
                                <th>ই-মেইল</th>
                              </tr>
                            </thead>
                            <tbody>
                              {profiletypeb?.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.name}</td>
                                  <td>{item.fathername}</td>
                                  <td>{item.mothername}</td>
                                  <td>{item.address}</td>
                                  <td>{item.nidno}</td>
                                  <td>{item.mobile}</td>
                                  <td>{item.email}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </fieldset>
                    </div>

                    <div className="my-4">
                      <div className={styles.add2sectiontitle}>
                        <p className="mb-1">৪. সাক্ষীর নাম ও ঠিকানাঃ</p>
                      </div>

                      <fieldset className="">
                        <div className="table-responsive">
                          <table className="table text-center table-striped mb-2 table-bordered hover">
                            <thead>
                              <tr className="text-start">
                                <th colSpan={7}> ক. ১ম সাক্ষী </th>
                              </tr>
                            </thead>

                            <thead>
                              <tr>
                                <th>পূর্ণ নাম</th>
                                <th>পিতার নাম</th>
                                <th>মাতার নাম</th>
                                <th>ঠিকানা</th>
                                <th>ভোটার আইডি নং</th>
                                <th>মোবাইল নং</th>
                                <th>ই-মেইল</th>
                              </tr>
                            </thead>
                            <tbody>
                              {profiletypec && profiletypec.length > 0 ? (
                                profiletypec.map((item) => (
                                  <tr key={item.id}>
                                    <td>{item.name || "N/A"}</td>
                                    <td>{item.fathername || "N/A"}</td>
                                    <td>{item.mothername || "N/A"}</td>
                                    <td>{item.address || "N/A"}</td>
                                    <td>{item.nidno || "N/A"}</td>
                                    <td>{item.mobile || "N/A"}</td>
                                    <td>{item.email || "N/A"}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={7}>No data available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </fieldset>

                      <fieldset className="mt-3">
                        <div className="table-responsive">
                          <table className="table text-center table-striped mb-2 table-bordered hover">
                            <thead>
                              <tr className="text-start">
                                <th colSpan={7}> খ. ২য় সাক্ষী </th>
                              </tr>
                            </thead>

                            <thead>
                              <tr>
                                <th>পূর্ণ নাম</th>
                                <th>পিতার নাম</th>
                                <th>মাতার নাম</th>
                                <th>ঠিকানা</th>
                                <th>ভোটার আইডি নং</th>
                                <th>মোবাইল নং</th>
                                <th>ই-মেইল</th>
                              </tr>
                            </thead>
                            <tbody>
                              {profiletyped && profiletyped.length > 0 ? (
                                profiletyped.map((item) => (
                                  <tr key={item.id}>
                                    <td>{item.name || "N/A"}</td>
                                    <td>{item.fathername || "N/A"}</td>
                                    <td>{item.mothername || "N/A"}</td>
                                    <td>{item.address || "N/A"}</td>
                                    <td>{item.nidno || "N/A"}</td>
                                    <td>{item.mobile || "N/A"}</td>
                                    <td>{item.email || "N/A"}</td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={7}>No data available</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </fieldset>
                    </div>

                    <div className="my-2 mt-5">
                      <div className="row">
                        <div class="col-sm-4">
                          <p className="mb-0">
                            {" "}
                            ৫. কর্মটির শ্রেণি/উপশ্রেণি এবং নাম (যদি থাকে)-
                          </p>
                        </div>

                        <div class="col-sm-2">
                          <p className="mb-0 unbold">
                            {" "}
                            শ্রেণিঃ {alldata?.category?.name}{" "}
                          </p>
                        </div>

                        <div class="col-sm-3">
                          <p className="mb-0 unbold">
                            উপশ্রেণিঃ {alldata?.subCategory?.name}
                          </p>
                        </div>

                        <div class="col-sm-3">
                          <p className="mb-0 unbold">
                            {" "}
                            নামঃ {alldata?.worktitle}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="my-2">
                      <div className="row">
                        <div class="col-sm-4">
                          <p className="mb-0">
                            {" "}
                            ৬. সত্ত্বাধিকারী স্বত্ব প্রাপ্তির উৎসঃ
                          </p>
                        </div>

                        <div className="col-sm-8">
                          <label className="radio-inline">
                            <input
                              name="source"
                              type="radio"
                              value="1"
                              checked={alldata?.source === "1"}
                              readOnly
                            />{" "}
                            প্রণেতা
                          </label>
                          <label className="radio-inline ms-3">
                            <input
                              name="source"
                              type="radio"
                              value="2"
                              checked={alldata?.source === "2"}
                              readOnly
                            />{" "}
                            বিনিয়োগকারী প্রযোজক
                          </label>
                          <label className="radio-inline ms-3">
                            <input
                              name="source"
                              type="radio"
                              value="3"
                              checked={alldata?.source === "3"}
                              readOnly
                            />{" "}
                            উত্তরাধিকার
                          </label>
                          <label className="radio-inline ms-3">
                            <input
                              name="source"
                              type="radio"
                              value="4"
                              checked={alldata?.source === "4"}
                              readOnly
                            />{" "}
                            ক্রয়সূত্রে
                          </label>
                          <label className="radio-inline ms-3">
                            <input
                              name="source"
                              type="radio"
                              value="5"
                              checked={alldata?.source === "5"}
                              readOnly
                            />{" "}
                            সম্পাদনকারী
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="my-2">
                      <div className="row">
                        <div class="col-sm-4">
                          <p className="mb-0">
                            {" "}
                            ৭. যে নির্দিষ্ট স্বত্বের অধিকার প্রদান করা হয়েছে
                            (প্রযোজ্য ক্ষেত্রে)-{" "}
                          </p>
                        </div>
                        <div class="col-sm-8">
                          <p className="mb-0 unbold"> {alldata?.onwer} </p>
                        </div>
                      </div>
                    </div>

                    <div className="my-2">
                      <div className="row">
                        <div class="col-sm-4">
                          <p className="mb-0"> ৮. বিনিময় মূল্যঃ </p>
                        </div>
                        <div class="col-sm-8">
                          <p className="mb-0 unbold">
                            {" "}
                            {alldata?.exchangerate}{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="my-2">
                      <div className="row">
                        <div class="col-sm-4">
                          <p className="mb-0"> ৯. দলিল / লাইসেন্সের মেয়াদঃ</p>
                        </div>
                        <div class="col-sm-8">
                          <p className="mb-0 unbold">
                            {" "}
                            {alldata?.documentexpire}{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="my-2">
                      <div className="row">
                        <div class="col-sm-4">
                          <p className="mb-0"> ১০. দলিল সম্পাদনের তারিখঃ</p>
                        </div>
                        <div class="col-sm-8">
                          <p className="mb-0 unbold">
                            {" "}
                            {localDateFormat(alldata?.contractdate)}{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="my-2">
                      <div className="row">
                        <div class="col-sm-4">
                          <p className="mb-0"> ১১. রয়্যালিটির পরিমাণঃ</p>
                        </div>
                        <div class="col-sm-8">
                          <p className="mb-0 unbold"> {alldata?.quantity} </p>
                        </div>
                      </div>
                    </div>

                    <div className="my-2">
                      <div className="row">
                        <div class="col-sm-4">
                          <p className="mb-0">
                            {" "}
                            ১২. স্বত্ব নিয়োগের ভৌগোলিক পরিধিঃ{" "}
                          </p>
                        </div>
                        <div class="col-sm-8">
                          <p className="mb-0 unbold">
                            {" "}
                            {alldata?.geoperimeter}{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="my-2">
                      <div className="row">
                        <div class="col-sm-4">
                          <p className="mb-0">
                            {" "}
                            ১৩. পুনঃপরীক্ষণ, বর্ধিতকরণ ও বন্ধকরণ (প্রযোজ্য নয়)-
                          </p>
                        </div>
                        <div class="col-sm-8">
                          <p className="mb-0 unbold"> {alldata?.extensions} </p>
                        </div>
                      </div>
                    </div>

                    <div className="my-2 mt-5">
                      <div className="row ">
                        <div class="col-lg-11 border m-auto p-2 rounded text-center">
                          <p className="mb-0 unbold">
                            <input
                              type="checkbox"
                              className="me-2"
                              checked={Number(alldata?.form_status) === 2}
                            />
                            আমি / আমরা ঘোষণা করছি যে, কপিরাইট রেজিস্ট্রেশনের
                            আবেদনে আমার পূরণকৃত সকল তথ্য ও সংযুক্ত দলিলসমূহ সত্য
                            ও নির্ভুল এবং এ কর্মটিকে কেন্দ্র করে কোন মামলা কোন
                            বিজ্ঞ আদালত বা ট্রাইব্যুনালে বিচারাধীন নেই ।
                            উল্লিখিত তথ্য বা দলিলে কোন ভুল ও অসত্য তথ্য
                            উদ্‌ঘাটিত হলে আমি / আমরা এর জন্য ব্যক্তিগতভাবে দায়ী
                            থাকব এবং দেশের প্রচলিত আইন অনুযায়ী যেকোন দন্ড গ্রহণে
                            বাধ্য হবো ।
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="submit-area mt-4">
                      {Number(alldata?.form_status) !== 2 && (
                        <div className="text-center d-flex gap-3 justify-content-center">
                          <div>
                            <Link
                              to={`/agreement-form-add-two/${alldata?.profile_id}/${alldata?.id}/edit`}
                              className="custombtn5"
                            >
                              পরিবর্তন করুন
                            </Link>
                          </div>
                          <div>
                            <Link
                              to={`/applicant/application-payment/${contractId?.id}`}
                              className="custombtn5"
                            >
                              পেমেন্ট
                            </Link>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContractTwoPreview;
