import { Close } from "@mui/icons-material";
import { format } from "date-fns";
import { bn } from "date-fns/locale"; // Bengali locale
import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./BanglaCalender.css";

const englishToBengaliNumber = (num) => {
  const banglaNumbers = {
    0: "০",
    1: "১",
    2: "২",
    3: "৩",
    4: "৪",
    5: "৫",
    6: "৬",
    7: "৭",
    8: "৮",
    9: "৯",
  };
  return num
    .toString()
    .split("")
    .map((digit) => banglaNumbers[digit] || digit)
    .join("");
};

const banglaWeekdays = [
  "রবি",
  "সোম",
  "মঙ্গল",
  "বুধ",
  "বৃহস্পতি",
  "শুক্র",
  "শনি",
];

const BanglaCalender = ({ placeholder, value, onChange }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);

  const handleDateChange = (newDate) => {
    onChange(newDate);
    setShowCalendar(false);
  };

  const formatBanglaDate = (date) => {
    return format(date, "dd-MM-yyyy", { locale: bn })
      .split("")
      .map((char) => englishToBengaliNumber(char))
      .join("");
  };

  const clearDate = () => {
    onChange(null); // Clear the date
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="" ref={calendarRef}>
      <div className="input-group">
        <input
          type="text"
          className="form-control bg-white"
          readOnly
          placeholder={value ? "" : placeholder} // Show placeholder only when no date is selected
          value={value ? formatBanglaDate(value) : ""}
          onClick={() => setShowCalendar(!showCalendar)} // Toggle calendar visibility
        />
        {value && (
          <button className="btn btn-outline-secondary" onClick={clearDate}>
            <Close />
          </button>
        )}
      </div>
      {showCalendar && (
        <div className="calendar-container">
          <Calendar
            onChange={handleDateChange}
            value={value}
            formatDay={(locale, date) => englishToBengaliNumber(date.getDate())}
            formatMonth={(locale, date) => format(date, "LLLL", { locale: bn })}
            formatYear={(locale, date) =>
              englishToBengaliNumber(date.getFullYear())
            }
            formatShortWeekday={
              (locale, date) => banglaWeekdays[date.getDay()] // Get Bengali weekday name
            }
          />
        </div>
      )}
    </div>
  );
};

export default BanglaCalender;
// import { format } from "date-fns";

// import { bn } from "date-fns/locale"; // Bengali locale
// import React, { useEffect, useRef, useState } from "react";
// import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";
// import "./BanglaCalender.css";

// const englishToBengaliNumber = (num) => {
//   const banglaNumbers = {
//     0: "০",
//     1: "১",
//     2: "২",
//     3: "৩",
//     4: "৪",
//     5: "৫",
//     6: "৬",
//     7: "৭",
//     8: "৮",
//     9: "৯",
//   };
//   return num
//     .toString()
//     .split("")
//     .map((digit) => banglaNumbers[digit] || digit)
//     .join("");
// };
// const banglaWeekdays = [
//   "রবি",
//   "সোম",
//   "মঙ্গল",
//   "বুধবার",
//   "বৃহস্পতি",
//   "শুক্র",
//   "শনিবার",
// ];

// const BanglaCalender = ({ placeholder, value, onChange }) => {
//   const [showCalendar, setShowCalendar] = useState(false);
//   const calendarRef = useRef(null);

//   const handleDateChange = (newDate) => {
//     onChange(newDate);
//     setShowCalendar(false);
//   };

//   const formatBanglaDate = (date) => {
//     return format(date, "dd-MM-yyyy", { locale: bn })
//       .split("")
//       .map((char) => englishToBengaliNumber(char))
//       .join("");
//   };

//   const handleClickOutside = (event) => {
//     if (calendarRef.current && !calendarRef.current.contains(event.target)) {
//       setShowCalendar(false);
//     }
//   };

//   useEffect(() => {
//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   return (
//     <div className="" ref={calendarRef}>
//       <input
//         type="text"
//         className="form-control bg-white"
//         readOnly
//         placeholder={value ? "" : placeholder} // Show placeholder only when no date is selected
//         value={value ? formatBanglaDate(value) : ""}
//         onClick={() => setShowCalendar(!showCalendar)} // Toggle calendar visibility
//       />
//       {showCalendar && (
//         <div className="calendar-container">
//           <Calendar
//             onChange={handleDateChange}
//             value={value}
//             formatDay={(locale, date) => englishToBengaliNumber(date.getDate())}
//             formatMonth={(locale, date) => format(date, "LLLL", { locale: bn })}
//             formatYear={(locale, date) =>
//               englishToBengaliNumber(date.getFullYear())
//             }
//             formatShortWeekday={
//               (locale, date) => banglaWeekdays[date.getDay()] // Get Bengali weekday name
//             }
//             calendarType="hebrew"
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default BanglaCalender;
