import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import { assets } from "../../../../assets";
import { convertToBanglaNumerals } from "../../../../utils/convertToBanglaNumerals";
import {
  localDateFormat,
  localDateFormatName,
} from "../../../../utils/dateFormat";

const PreviewAndPrint = ({
  isLoading,
  data,
  printRef,
  onContentReady,
  date,
}) => {
  useEffect(() => {
    if (!isLoading && data) {
      onContentReady(); // Notify parent that content is ready
    }
  }, [isLoading, data, onContentReady]);

  const currentDateTime = new Date().toLocaleString(); // Get current date and time

  return (
    <>
      {isLoading ? (
        <div>
          <BeatLoader />
        </div>
      ) : (
        <div ref={printRef} className="text-center show-print">
          <img
            style={{
              height: "60px",
              margin: "auto",
            }}
            src={assets.logoBlack}
            className="mt-3 mb-4"
            alt=""
          />
          <h5>{date?.title} প্রতিবেদন</h5>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <label htmlFor="">
                মোট {date?.title?.split(" ")?.[1] || ""}ঃ
              </label>{" "}
              {convertToBanglaNumerals(data?.length)}
            </div>
            <p className="m-0">
              {localDateFormat(date?.fromDate)} হইতে{" "}
              {localDateFormat(date?.toDate)} পর্যন্ত
            </p>
          </div>
          <Table bordered striped>
            <thead>
              <tr>
                <th>তারিখ</th>
                <th>আবেদন নং</th>
                <th>আবেদনকারির নাম</th>
                <th>শিরোনাম</th>
                <th>বিভাগ</th>
                <th>উপবিভাগ</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.sendDate}</td>
                    <td>{item.trackReg}</td>
                    <td>{item.applicant}</td>
                    <td>{item.workTitle}</td>
                    <td>{item.category}</td>
                    <td>{item.subCategory}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
          {/* Footer for print with developer name and date */}
          <div className="print-footer">
            <div className="d-flex justify-content-between align-items-center">
              <p className="m-0">Developed and Maintained by Ipsita</p>
              <p className="m-0">{localDateFormatName(currentDateTime)}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreviewAndPrint;
