import { Print } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useFormik } from "formik";
import JoditEditor from "jodit-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { assets } from "../../assets";
import BanglaCalender from "../../pages/frontend/userPanel/form_two/BanglaCalender";
import { useLazyGetByRoleQuery } from "../../redux/api/applicationCopyrightApiSlice";
import { useGetRoleInQuery } from "../../redux/api/categoryApiSlice";
import {
  useDeleteComplainantMutation,
  useHearingApprovalMutation,
} from "../../redux/api/submittedCopyrightAgreementApiSlice";
import { useAuth } from "../../utils/authorization";
import { config } from "../../utils/config";
import {
  globalDateFormat,
  localDateFormat,
  localDateFormatName,
  localDateFormatNameBN,
  localTimeFormat,
} from "../../utils/dateFormat";
import { bnTimeFormat } from "../../utils/englishToBengaliNumber";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";

const HearingContent = ({ info }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { authUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [errors, setErrors] = useState(false);
  const editor = useRef(null);
  const printLetter = useRef();
  const [
    Verify,
    {
      isLoading: isLoadingVerify,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = useHearingApprovalMutation();

  const [deleteComplainant, { isLoading: isLoadingDelete }] =
    useDeleteComplainantMutation();

  const roleMapping = "[2, 4, 5, 11]";

  const { data: roles } = useGetRoleInQuery(roleMapping);
  const rolesOption =
    roles?.data?.map((data) => ({
      value: data.id,
      label: data.bn_name,
    })) || [];

  const [findUser, { data: users }] = useLazyGetByRoleQuery();

  const usersOption =
    (users?.data &&
      Object.entries(users.data)
        .filter(([key]) => authUser.id !== Number(key))
        .map(([key, value]) => ({
          value: Number(key),
          label: value,
        }))) ||
    [];

  const validationSchema = Yup.object().shape({
    record_no: Yup.string().required("রেকর্ড নাম্বার প্রযোজ্য।"),
    record_date: Yup.date().required("রেকর্ড তারিখ প্রযোজ্য।"),
    subject: Yup.string().required("বিষয় প্রযোজ্য।"),
    role_id: Yup.number().required("পদবী প্রযোজ্য।"),
    user_id: Yup.number().required("প্রাপক প্রযোজ্য।"),
    date: Yup.string().required("তারিখ প্রযোজ্য।"),
    time: Yup.string().required("সময় প্রযোজ্য।"),
    complainants: Yup.array().when(["isComplainant"], {
      is: (isComplainant) => isComplainant === 1,
      then: () =>
        Yup.array().of(
          Yup.object().shape({
            name: Yup.string().required("নাম প্রযোজ্য।"),
            mobile: Yup.string().required("মোবাইল প্রযোজ্য।"),
            email: Yup.string()
              .email("ইমেইল সঠিক নয় ")
              .required("ইমেইল প্রযোজ্য।"),
            address: Yup.string().required("ঠিকানা প্রযোজ্য।"),
          })
        ),
      otherwise: () =>
        Yup.array().of(
          Yup.object().shape({
            name: Yup.string().nullable(),
            email: Yup.string().nullable(),
            mobile: Yup.string().nullable(),
            address: Yup.string().nullable(),
          })
        ),
    }),
  });

  const pageAleart =
    info.fileStatus === 10
      ? "আপনি কি চিঠি অনুমোদনের জন্য পাঠাতে চান?"
      : info.fileStatus === 11
      ? "আপনি কি চিঠি অনুমোদন দিতে চান?"
      : "আপনি কি চিঠি প্রেরণ করতে চান?";

  const formik = useFormik({
    initialValues: {
      record_no: "",
      record_date: globalDateFormat(new Date()),
      subject: "",
      detail: "",
      role_id: "",
      user_id: "",
      date: "",
      time: "",
      isComplainant: 0,
      complainants: [
        {
          name: "",
          email: "",
          mobile: "",
          address: "",
        },
      ],
    },
    validationSchema,
    onSubmit: (values) => {
      if (!content) {
        return setErrors("প্রযোজ্য।");
      }
      setErrors("");

      // Prepare the updated values
      const updatedValues = {
        ...values,
        detail: content,
      };

      // Remove complainants if isComplainant is 0
      if (values.isComplainant === 0) {
        delete updatedValues.complainants;
      }

      Swal.fire({
        text: pageAleart,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          Verify({ id, formData: updatedValues, fileStatus: info.fileStatus });
        }
      });
    },
  });
  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      navigate(-1);
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
      formik.setErrors(
        transformErrorsToObjectStructure(errorSubmit.data?.data || {})
      );
    }
    // eslint-disable-next-line
  }, [isErrorSubmit, isSuccessSubmit]);

  useEffect(() => {
    if (info?.hearing?.subject) {
      if (info?.hearing?.user?.role?.id) {
        findUser(info?.hearing?.user?.role?.id);
      }
      const sub = `${info.workTitle} নামক ${info.category}কর্মের কপিরাইট রেজিস্ট্রেশন সংশোধন ।`;
      formik.setValues((prev) => ({
        ...prev,
        record_no: info?.hearing?.record_no,
        record_date: info?.hearing?.record_date ?? globalDateFormat(new Date()),
        subject: info?.hearing?.subject ?? sub,
        role_id: info?.hearing?.user?.role?.id ?? "",
        user_id: info?.hearing?.user?.id ?? "",
        complainants: info?.hearing?.complainants ?? [],
        date: info?.hearing?.date ?? "",
        time: info?.hearing?.time ?? "",
        isComplainant: Number(info?.hearing?.isComplainant) ?? 0,
        detail: info?.hearing?.detail ?? "",
      }));
      setContent(info?.hearing?.detail);
    } else {
      if (info?.hearing?.user?.role?.id) {
        findUser(info?.hearing?.user?.role?.id);
      }
      const sub =
        info.fileStatus === 10
          ? `${info.workTitle} নামক ${info.category}কর্মের কপিরাইট রেজিস্ট্রেশন সংশোধন ।`
          : "";
      formik.setValues((prev) => ({
        ...prev,
        subject: info?.hearing?.subject ?? sub,
        role_id: info?.hearing?.user?.role?.id ?? "",
        user_id: info?.hearing?.user?.id ?? "",
      }));
      setIsEdit(true);
    }
    // eslint-disable-next-line
  }, [info?.hearing]);

  const defaultValue = {
    value: "",
    label: "নির্বাচন করুন",
  };

  const handleEditorChange = useCallback((newContent) => {
    setContent(newContent);
  }, []);

  const isAuthorized = [2, 4, 11, 3].includes(authUser.role_id);
  const user = info?.hearing?.user;

  const pageTitle =
    info.fileStatus === 10
      ? "শুনানীর চিঠি"
      : info.fileStatus === 11
      ? "শুনানীর নথি"
      : "শুনানীর চিঠি (অনুমোদিত)";

  const banglaTime = bnTimeFormat(formik.values.time);

  const handleIsComplainantChange = (e) => {
    formik.setValues((prev) => ({
      ...prev,
      isComplainant: Number(e.target.checked),
    }));
  };

  const handleAddComplainants = () => {
    const complainants = [...formik.values.complainants];
    complainants.push({
      name: "",
      email: "",
      mobile: "",
      address: "",
    });
    formik.setValues((prev) => ({ ...prev, complainants }));
  };

  const handleDeleteComplainants = (index, id) => {
    if (id) {
      Swal.fire({
        text: "আপনি কি এটি মুছতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteComplainant(id);
        }
      });
    }
    const complainants = formik.values.complainants.filter(
      (_, i) => i !== index
    );
    formik.setValues((prev) => ({ ...prev, complainants }));
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center bg-slate p-2">
        <div className="fw-semibold">{pageTitle}</div>
        <ReactToPrint
          trigger={() => <Print className="fs-4 text-primary cursor-pointer" />}
          content={() => printLetter.current}
        />
      </div>
      {info.fileStatus === 10 && (
        <div className="card p-2 mt-2">
          <div>
            <label htmlFor="">শুনানীর আদেশ</label>
            <p>{info?.hearing?.comment}</p>
          </div>
          <div className="d-flex justify-content-end">
            <div className="text-center">
              <div className="fw-semibold">আদেশক্রমে</div>
              <p className="m-0">{info?.hearing?.user?.name}</p>
              <p className="m-0">{info?.hearing?.user?.role?.bnName}</p>
              <div>তারিখ : {localDateFormat(info?.hearing?.updated_at)}</div>
              <div>সময় : {localTimeFormat(info?.hearing?.updated_at)}</div>
            </div>
          </div>
        </div>
      )}
      {info.fileStatus === 11 && (
        <div className="card p-2 mt-2">
          <span className="fw-semibold">হইতে, </span>{" "}
          {info?.hearing?.sendBy.name} ({info?.hearing?.sendBy?.role?.bnName})
        </div>
      )}

      <form onSubmit={formik.handleSubmit}>
        <div className="py-2" ref={printLetter}>
          <div className="card p-3">
            <div ref={printLetter}>
              <div className="text-center">
                <img
                  className="my-3"
                  src={assets.logoBlack}
                  style={{ width: "350px" }}
                  alt=""
                />
                <p className="m-0">কপিরাইট অফিস, সংস্কৃতি বিষয়ক মন্ত্রণালয়</p>

                <p className="m-0">
                  জাতীয় গ্রন্থাগার ভবন, আগারগাঁও, শেরেবাংলা নগর, ঢাকা।
                </p>

                <p className="m-0">
                  ওয়েবসাইট :{" "}
                  <Link target="_blank" to={"//www.copyrightoffice.gov.bd"}>
                    www.copyrightoffice.gov.bd
                  </Link>
                </p>
              </div>
              <div
                className={`d-flex justify-content-between py-3 mt-2 align-items-center`}
              >
                <div
                  className={`d-flex gap-2 ${!isEdit && "align-items-center "}`}
                >
                  {!isEdit ? (
                    <> {formik.values.record_no}</>
                  ) : (
                    <span>
                      {" "}
                      <Form.Control
                        placeholder="নথি / স্বারক / রেজিঃ নং "
                        value={formik.values.record_no}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        errors={formik.errors}
                        touched={formik.touched}
                        name="record_no"
                      />
                      {formik.errors.record_no && formik.touched.record_no && (
                        <span className="text-danger">
                          {formik.errors.record_no}
                        </span>
                      )}
                    </span>
                  )}
                </div>
                <div
                  className={`d-flex gap-3 ${!isEdit && "align-items-center "}`}
                >
                  <div
                    className={`d-flex gap-3 ${isEdit && "mt-2"} fw-semibold`}
                  >
                    তারিখঃ
                  </div>
                  <div>
                    {!isEdit ? (
                      <>
                        {localDateFormatNameBN(formik.values.record_date)}
                        <hr className="m-0" />
                        {localDateFormatName(formik.values.record_date)}
                      </>
                    ) : (
                      <>
                        {localDateFormatNameBN(formik.values.record_date)}
                        <BanglaCalender
                          placeholder={"তারিখ"}
                          value={formik.values.record_date}
                          onChange={(val) => {
                            formik.setFieldValue(
                              "record_date",
                              globalDateFormat(val)
                            );
                          }}
                        />
                        {formik.errors?.record_date ? (
                          <span className="text-danger">
                            {formik.errors.record_date}
                          </span>
                        ) : (
                          <div style={{ height: "24px" }}></div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <p
                  className={`fw-semibold mt-4 w-100 d-flex gap-2 ${
                    !isEdit && "align-items-center "
                  }`}
                >
                  <span className={`${isEdit && "mt-2"} `}>বিষয়ঃ </span>
                  {!isEdit ? (
                    <> {formik.values.subject}</>
                  ) : (
                    <div className="w-100">
                      <Form.Control
                        value={formik.values.subject}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        errors={formik.errors}
                        touched={formik.touched}
                        name="subject"
                      />
                      {formik.errors.subject && formik.touched.subject && (
                        <span className="text-danger">
                          {formik.errors.subject}
                        </span>
                      )}
                    </div>
                  )}
                </p>
              </div>
              <p className="pb-4" style={{ textAlign: "justify" }}>
                {!isEdit ? (
                  <p
                    className="m-0"
                    dangerouslySetInnerHTML={{ __html: content }}
                  ></p>
                ) : (
                  <>
                    <JoditEditor
                      ref={editor}
                      value={content}
                      config={config}
                      tabIndex={1}
                      onBlur={handleEditorChange}
                    />
                    {errors ? (
                      <span className="text-danger">{errors}</span>
                    ) : (
                      <div style={{ height: "24px" }}></div>
                    )}
                  </>
                )}
              </p>
              <div className="d-flex justify-content-between my-2">
                <div>
                  <div
                    className={`d-flex gap-3 ${
                      !isEdit && "align-items-center "
                    }`}
                  >
                    <div className={`${isEdit && "mt-2"} fw-semibold`}>
                      শুনানীর তারিখ -
                    </div>
                    <div>
                      {!isEdit ? (
                        <>{localDateFormatName(formik.values.date)}</>
                      ) : (
                        <>
                          <BanglaCalender
                            placeholder={"তারিখ"}
                            value={formik.values.date}
                            onChange={(val) => {
                              formik.setFieldValue(
                                "date",
                                globalDateFormat(val)
                              );
                            }}
                          />
                          {formik.errors?.date ? (
                            <span className="text-danger">
                              {formik.errors.date}
                            </span>
                          ) : (
                            <div style={{ height: "24px" }}></div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className={`d-flex gap-3 ${
                      !isEdit && "align-items-center "
                    }`}
                  >
                    <div className={`${isEdit && "mt-2"} fw-semibold`}>
                      শুনানীর সময় -
                    </div>
                    <div>
                      {!isEdit ? (
                        <>{banglaTime}</>
                      ) : (
                        <>
                          <Form.Control
                            value={formik.values.time}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            errors={formik.errors}
                            touched={formik.touched}
                            name="time"
                            type="time"
                          />
                          {formik.errors.time && formik.touched.time && (
                            <span className="text-danger">
                              {formik.errors.time}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="mt-3">
                    {info.name && (
                      <>
                        <p className="m-0">{info?.name}</p>
                        <p className="m-0">{info?.email}</p>
                        <p className="m-0">{info?.address}</p>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <div className="mt-3 text-end">
                    {(isAuthorized || info.fileStatus === 20) && (
                      <>
                        {user?.sign && (
                          <>
                            <img
                              style={{ height: "40px" }}
                              src={user?.sign}
                              alt=""
                            />
                          </>
                        )}
                        <p className="m-0">{user?.name}</p>
                        <p className="m-0">{user?.role?.bnName}</p>
                        <p className="m-0">{user?.email}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex gap-3 mt-3">
                  {info.fileStatus !== 20 ? (
                    <>
                      <span className="fw-semibold">
                        বাদীঃ (যদি থাকে টিক দিন){" "}
                      </span>
                      <div>
                        <Tooltip
                          title={
                            Number(formik.values.isComplainant) === 1
                              ? "না"
                              : "হ্যাঁ"
                          }
                          placement="top"
                        >
                          <Form.Check
                            type="switch"
                            id={`custom-switch${formik.values.isComplainant}`}
                            value={formik.values.isComplainant}
                            className="text-success"
                            isValid
                            checked={Number(formik.values.isComplainant) === 1}
                            onChange={(e) => handleIsComplainantChange(e)}
                          />
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    <>
                      {formik.values.isComplainant === 1 && (
                        <span className="fw-semibold">বাদীঃ</span>
                      )}
                    </>
                  )}
                  {formik.values.isComplainant === 1 && isEdit && (
                    <>
                      <Tooltip placement="top" title="যোগ করুন">
                        <Button
                          variant="success"
                          className="btn-sm"
                          onClick={handleAddComplainants}
                          size="sm"
                        >
                          <FaPlus />
                        </Button>
                      </Tooltip>
                    </>
                  )}
                </div>
                <div>
                  {formik.values.isComplainant === 1 && (
                    <div className="mt-3 row">
                      {formik.values.complainants.map((complainant, i) => (
                        <div key={i} className="col-md-12 mt-2">
                          <div className="mb-2">
                            {isEdit ? (
                              <>
                                <Form.Control
                                  placeholder="নাম"
                                  value={complainant?.name}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  isInvalid={
                                    formik.touched.complainants &&
                                    formik.touched.complainants[i] &&
                                    formik.touched.complainants[i].name &&
                                    !!formik.errors.complainants &&
                                    !!formik.errors.complainants[i] &&
                                    !!formik.errors.complainants[i].name
                                  }
                                  name={`complainants[${i}].name`}
                                  size="sm"
                                />
                                {formik.touched.complainants &&
                                  formik.touched.complainants[i] &&
                                  formik.touched.complainants[i].name &&
                                  formik.errors.complainants &&
                                  formik.errors.complainants[i] &&
                                  formik.errors.complainants[i].name && (
                                    <span className="text-danger">
                                      {formik.errors.complainants[i].name}
                                    </span>
                                  )}
                              </>
                            ) : (
                              <span>{complainant?.name}</span>
                            )}
                          </div>
                          <div className="mb-2">
                            {isEdit ? (
                              <>
                                <Form.Control
                                  placeholder="মোবাইল"
                                  value={complainant?.mobile}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  isInvalid={
                                    formik.touched.complainants &&
                                    formik.touched.complainants[i] &&
                                    formik.touched.complainants[i].mobile &&
                                    !!formik.errors.complainants &&
                                    !!formik.errors.complainants[i] &&
                                    !!formik.errors.complainants[i].mobile
                                  }
                                  name={`complainants[${i}].mobile`}
                                  size="sm"
                                />
                                {formik.touched.complainants &&
                                  formik.touched.complainants[i] &&
                                  formik.touched.complainants[i].mobile &&
                                  formik.errors.complainants &&
                                  formik.errors.complainants[i] &&
                                  formik.errors.complainants[i].mobile && (
                                    <span className="text-danger">
                                      {formik.errors.complainants[i].mobile}
                                    </span>
                                  )}
                              </>
                            ) : (
                              <span>{complainant?.mobile}</span>
                            )}
                          </div>
                          <div className="mb-2">
                            {isEdit ? (
                              <>
                                <Form.Control
                                  placeholder="ইমেইল"
                                  value={complainant?.email}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  isInvalid={
                                    formik.touched.complainants &&
                                    formik.touched.complainants[i] &&
                                    formik.touched.complainants[i].email &&
                                    !!formik.errors.complainants &&
                                    !!formik.errors.complainants[i] &&
                                    !!formik.errors.complainants[i].email
                                  }
                                  name={`complainants[${i}].email`}
                                  type="email"
                                  size="sm"
                                />
                                {formik.touched.complainants &&
                                  formik.touched.complainants[i] &&
                                  formik.touched.complainants[i].email &&
                                  formik.errors.complainants &&
                                  formik.errors.complainants[i] &&
                                  formik.errors.complainants[i].email && (
                                    <span className="text-danger">
                                      {formik.errors.complainants[i].email}
                                    </span>
                                  )}
                              </>
                            ) : (
                              <span>{complainant?.email}</span>
                            )}
                          </div>
                          <div className="mb-2">
                            {isEdit ? (
                              <>
                                <Form.Control
                                  placeholder="ঠিকানা"
                                  value={complainant?.address}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  isInvalid={
                                    formik.touched.complainants &&
                                    formik.touched.complainants[i] &&
                                    formik.touched.complainants[i].address &&
                                    !!formik.errors.complainants &&
                                    !!formik.errors.complainants[i] &&
                                    !!formik.errors.complainants[i].address
                                  }
                                  name={`complainants[${i}].address`}
                                  size="sm"
                                />
                                {formik.touched.complainants &&
                                  formik.touched.complainants[i] &&
                                  formik.touched.complainants[i].address &&
                                  formik.errors.complainants &&
                                  formik.errors.complainants[i] &&
                                  formik.errors.complainants[i].address && (
                                    <span className="text-danger">
                                      {formik.errors.complainants[i].address}
                                    </span>
                                  )}
                              </>
                            ) : (
                              <span>{complainant?.address}</span>
                            )}
                          </div>
                          {formik.values.complainants.length > 1 && isEdit && (
                            <div className="text-center">
                              <Tooltip placement="top" title="যোগ করুন">
                                <Button
                                  variant="danger"
                                  className="btn-sm"
                                  disabled={isLoadingDelete}
                                  onClick={() =>
                                    handleDeleteComplainants(i, complainant.id)
                                  }
                                  size="sm"
                                >
                                  <FaMinus />
                                </Button>
                              </Tooltip>
                            </div>
                          )}
                          {isEdit && (
                            <>
                              <hr />
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isAuthorized && info.fileStatus !== 20 && (
          <div className="row">
            <div className="col-md-6">
              <label className="my-2 fw-semibold" htmlFor="role_id">
                পদবী
              </label>
              <Select
                name="role_id"
                options={rolesOption}
                value={
                  formik.values.role_id
                    ? rolesOption.find(
                        (option) => option.value === formik.values.role_id
                      )
                    : defaultValue
                }
                onChange={(selectedOption) => {
                  findUser(selectedOption.value);
                  formik.setValues((prev) => {
                    return {
                      ...prev,
                      user_id: "",
                    };
                  });
                  formik.setFieldValue("role_id", selectedOption.value);
                }}
                onBlur={formik.handleBlur}
                classNamePrefix="react-select"
              />
              {formik.touched.role_id && formik.errors.role_id ? (
                <div className="text-danger">{formik.errors.role_id}</div>
              ) : null}
            </div>
            <div className="col-md-6">
              <label className="my-2 fw-semibold" htmlFor="user_id">
                প্রাপক
              </label>
              <Select
                name="user_id"
                options={usersOption}
                value={
                  formik.values.user_id
                    ? usersOption.find(
                        (option) => option.value === formik.values.user_id
                      )
                    : defaultValue
                }
                onChange={(selectedOption) =>
                  formik.setFieldValue("user_id", selectedOption.value)
                }
                onBlur={formik.handleBlur}
                classNamePrefix="react-select"
              />
              {formik.touched.user_id && formik.errors.user_id ? (
                <div className="text-danger">{formik.errors.user_id}</div>
              ) : null}
            </div>
          </div>
        )}
        <div className="my-4 text-center d-flex justify-content-center gap-3">
          {info.fileStatus !== 20 && (
            <>
              <div className="mr-3">
                <Button
                  onClick={() => setIsEdit(!isEdit)}
                  disabled={isLoadingVerify}
                >
                  {isEdit ? "প্রিভিউ" : "সম্পাদনা করুন"}
                </Button>
              </div>
            </>
          )}
          <div className="mr-3">
            <Button variant="success" type="submit" disabled={isLoadingVerify}>
              {isAuthorized
                ? "অনুমোদন"
                : info.fileStatus === 20
                ? "চিঠি পাঠান"
                : "দাখিল করুন"}
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default HearingContent;
