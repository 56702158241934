import React, { useState } from "react";
import { assets } from "../../../assets";
import styles from "../../../pages/frontend/userPanel/form_two/Add2.module.css";
import ShowFilePreviewModal from "../../../pages/frontend/userPanel/form_two/ShowPreviewFiles";
import { localDateFormat } from "../../../utils/dateFormat";

const PaymentPreview = ({ data }) => {
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const publicUrl = process.env.PUBLIC_URL;
  const applicationFormOne = data;
  return (
    <div className="row">
      <div className="col-md-4 col-sm-5 col-lg-4">
        <p className="mb-0">রেজিস্ট্রেশন ফি এবং পেমেন্ট অপশন</p>
      </div>

      <div className="col-md-8 col-sm-7 col-lg-8">
        <span className="d-flex mx-3">
          <div className="form-check mx-3">
            <input
              className="form-check-input"
              type="radio"
              name="echalan"
              id=""
              checked={applicationFormOne?.st === "3"}
              readOnly
            />
            <label className="form-check-label" htmlFor="">
              ই-চালান
            </label>
          </div>
          <div className="form-check me-3">
            <input
              className="form-check-input"
              type="radio"
              name="trejari"
              id=""
              checked={applicationFormOne?.st === "2"}
              readOnly
            />
            <label className="form-check-label" htmlFor="">
              ট্রেজারি
            </label>
          </div>
        </span>
      </div>

      {applicationFormOne?.st === "3" && (
        <div className="col-md-12 mt-2">
          <div className="table-responsive">
            <table className="table text-center table-striped mb-2 table-bordered hover">
              <thead>
                <tr>
                  <th>অনলাইন পেমেন্ট</th>
                  <th>ট্রানজ্যাকশন নাম্বার</th>
                  <th>টাকার পরিমাণ</th>
                  <th>ই-চালান নাম্বার</th>
                  <th>ট্রানজ্যাকশন তারিখ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <img src={assets?.echalan} alt="Logo" />{" "}
                  </td>
                  <td> {applicationFormOne?.transactionNo} </td>
                  <td> {applicationFormOne?.amount} </td>
                  <td>{applicationFormOne?.echallanId}</td>
                  <td>{localDateFormat(applicationFormOne?.treasuryDate)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      {applicationFormOne?.st === "2" && (
        <div className="col-md-12 mt-2">
          <div className="table-responsive">
            <table className="table text-center table-striped mb-2 table-bordered hover">
              <thead>
                <tr>
                  <th>ট্রেজারি চালান নং</th>
                  <th>ব্যাংকের নাম</th>
                  <th>শাখা</th>
                  <th>টাকার পরিমাণ</th>
                  <th>ট্রেজারি তারিখ</th>
                  <th>ট্রেজারি চালান ( স্ক্যান কপি )</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{applicationFormOne?.treasuryNo}</td>
                  <td>{applicationFormOne?.bankbranch?.bank?.name}</td>
                  <td>{applicationFormOne?.bankbranch?.name}</td>
                  <td>{applicationFormOne?.amount}</td>
                  <td> {localDateFormat(applicationFormOne?.treasuryDate)} </td>
                  <td>
                    <div className="form-group text-center">
                      <div className="form-group add2-form-group-image-preview-box">
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setPreviewModal(
                              applicationFormOne?.treasury ? true : false
                            );
                            setPreviewFile(applicationFormOne?.treasury);
                          }}
                        >
                          <div className={`${styles.imagePreviewAdd2}`}>
                            {applicationFormOne?.treasury ? (
                              <span className="custombtn55 text-center d-none printiconn">
                                <img
                                  src={`${publicUrl}/assets/images/check.png`}
                                  alt="Check Icon"
                                />
                              </span>
                            ) : (
                              <span className="custombtn55 text-center d-none printiconn">
                                <img
                                  src={`${publicUrl}/assets/images/cros.png`}
                                  alt="cros Icon"
                                />
                              </span>
                            )}

                            <span className="custombtn55 text-center printnone">
                              {applicationFormOne?.treasury
                                ? "এখানে দেখুন"
                                : "কোনো ফাইল নেই"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      <ShowFilePreviewModal
        setPreviewModal={setPreviewModal}
        previewModal={previewModal}
        previewUrl={previewFile}
        setPreviewUrl={setPreviewFile}
      />
    </div>
  );
};

export default PaymentPreview;
