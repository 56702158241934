import { Print } from "@mui/icons-material";
import { useFormik } from "formik";
import JoditEditor from "jodit-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form, Tab, Tabs } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { assets } from "../../assets";
import BanglaCalender from "../../pages/frontend/userPanel/form_two/BanglaCalender";
import { useLazyGetByRoleQuery } from "../../redux/api/applicationCopyrightApiSlice";
import { useGetRoleInQuery } from "../../redux/api/categoryApiSlice";
import { useUnauthorizedApprovalMutation } from "../../redux/api/submittedCopyrightAgreementApiSlice";
import { accessRoles } from "../../utils/accessRoles";
import { useAuth } from "../../utils/authorization";
import { config } from "../../utils/config";
import {
  globalDateFormat,
  localDateFormat,
  localDateFormatName,
  localDateFormatNameBN,
  localTimeFormat,
} from "../../utils/dateFormat";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";
import UnauthorizedNothiTransfer from "./unauthorized/UnauthorizedNothiTransfer";

const UnauthorizedContent = ({ info }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { authUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const [content, setContent] = useState("");
  const [errors, setErrors] = useState(false);
  const editor = useRef(null);
  const printLetter = useRef();
  const [
    Verify,
    {
      isLoading: isLoadingVerify,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = useUnauthorizedApprovalMutation();

  const roleMapping = `[${accessRoles.DeputyRegister}, ${accessRoles.Register}]`;

  const { data: roles } = useGetRoleInQuery(roleMapping);
  const rolesOption =
    roles?.data?.map((data) => ({
      value: data.id,
      label: data.bn_name,
    })) || [];

  const [findUser, { data: users }] = useLazyGetByRoleQuery();

  const usersOption =
    (users?.data &&
      Object.entries(users.data)
        .filter(([key]) => authUser.id !== Number(key))
        .map(([key, value]) => ({
          value: Number(key),
          label: value,
        }))) ||
    [];

  const pageTitle =
    info.fileStatus === 6
      ? "অননুমোদনের চিঠি প্রস্তুত"
      : info.fileStatus === 29
      ? "অননুমোদনের চিঠি অনুমোদন "
      : "অননুমোদনের চিঠি প্রেরণ";

  const pageAleart =
    info.fileStatus === 6
      ? "আপনি কি চিঠি অনুমোদনের জন্য পাঠাতে চান?"
      : info.fileStatus === 29
      ? "আপনি কি চিঠি অনুমোদন দিতে চান?"
      : "আপনি কি চিঠি প্রেরণ করতে চান?";

  const validationSchema = Yup.object().shape({
    record_no: Yup.string().required("রেকর্ড নাম্বার প্রযোজ্য।"),
    record_date: Yup.date().required("রেকর্ড তারিখ প্রযোজ্য।"),
    subject: Yup.string().required("বিষয় প্রযোজ্য।"),
    role_id: Yup.number().required("পদবী প্রযোজ্য।"),
    user_id: Yup.number().required("প্রাপক প্রযোজ্য।"),
    comments: Yup.string().when("record_no", {
      is: (val) => info.fileStatus === 30,
      then: () => Yup.string().required("আপনার মন্তব্য লিখুন।"),
      otherwise: () => Yup.string().nullable(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      record_no: "",
      record_date: globalDateFormat(new Date()),
      subject: "",
      detail: "",
      role_id: "",
      user_id: "",
      comments: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (!content) {
        return setErrors("প্রযোজ্য।");
      }
      setErrors("");
      const formData = { ...values, detail: content };
      Swal.fire({
        text: pageAleart,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          Verify({ id, formData, fileStatus: info.fileStatus });
        }
      });
    },
  });
  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      navigate(-1);
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
      formik.setErrors(
        transformErrorsToObjectStructure(errorSubmit.data?.data || {})
      );
    }
    // eslint-disable-next-line
  }, [isErrorSubmit, isSuccessSubmit]);

  useEffect(() => {
    if (info?.unauthorized?.detail) {
      if (info?.unauthorized?.user?.role?.id) {
        findUser(info?.unauthorized?.user?.role?.id);
      }
      const sub = `${info.workTitle} নামক ${info.category} কর্মের কপিরাইট রেজিস্ট্রেশন সংশোধন ।`;
      formik.setValues((prev) => ({
        ...prev,
        record_no: info?.unauthorized?.record_no,
        record_date:
          info?.unauthorized?.record_date ?? globalDateFormat(new Date()),
        subject: info?.unauthorized?.subject ?? sub,
        role_id: info?.unauthorized?.user?.role?.id ?? "",
        user_id: info?.unauthorized?.user?.id ?? "",
      }));
      setContent(info?.unauthorized?.detail);
    } else {
      if (info?.unauthorized?.user?.role?.id) {
        findUser(info?.unauthorized?.user?.role?.id);
      }
      const sub =
        info.fileStatus === 6
          ? `${info.workTitle} নামক ${info.category} কর্মের কপিরাইট রেজিস্ট্রেশন সংশোধন ।`
          : "";
      formik.setValues((prev) => ({
        ...prev,
        subject: info?.unauthorized?.subject ?? sub,
        role_id: info?.unauthorized?.user?.role?.id ?? "",
        user_id: info?.unauthorized?.user?.id ?? "",
      }));
      setIsEdit(true);
    }
  }, [info?.unauthorized]);

  const defaultValue = {
    value: "",
    label: "নির্বাচন করুন",
  };

  const user = info?.unauthorized?.user;

  const handleEditorChange = useCallback((newContent) => {
    setContent(newContent);
  }, []);

  const [valueTab, setValueTab] = useState("one");

  const handleTabChange = (key) => {
    setValueTab(key);
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center bg-slate p-2 mb-3">
        <div className="fw-semibold">{pageTitle}</div>
        <ReactToPrint
          trigger={() => <Print className="fs-4 text-primary cursor-pointer" />}
          content={() => printLetter.current}
        />
      </div>
      <Tabs
        activeKey={valueTab}
        onSelect={handleTabChange}
        aria-label="secondary tabs example"
      >
        <Tab eventKey="one" title={"চিঠি প্রস্তুত"}>
          {info.fileStatus === 6 && (
            <div className="card p-2 mt-2">
              <div>
                <label htmlFor="">নথিজাত আদেশ</label>
                <p>{info?.unauthorized?.comment}</p>
              </div>
              <div className="d-flex justify-content-end">
                <div className="text-center">
                  <div className="fw-semibold">আদেশক্রমে</div>
                  <p className="m-0">{info?.unauthorized?.user?.name}</p>
                  <p className="m-0">
                    {info?.unauthorized?.user?.role?.bnName}
                  </p>
                  <div>তারিখ : {localDateFormat(info?.unauthorized?.date)}</div>
                  <div>সময় : {localTimeFormat(info?.unauthorized?.date)}</div>
                </div>
              </div>
            </div>
          )}
          {info.fileStatus === 29 && (
            <div className="card p-2 mt-2">
              <span className="fw-semibold">হইতে, </span>{" "}
              {info?.unauthorized?.sendBy.name} (
              {info?.unauthorized?.sendBy?.role?.bnName})
            </div>
          )}

          <form onSubmit={formik.handleSubmit}>
            <div className="py-2" ref={printLetter}>
              <div className="card p-3">
                <div ref={printLetter}>
                  <div className="text-center">
                    <img
                      className="my-3"
                      src={assets.logoBlack}
                      style={{ width: "350px" }}
                      alt=""
                    />
                    <p className="m-0">
                      কপিরাইট অফিস, সংস্কৃতি বিষয়ক মন্ত্রণালয়
                    </p>

                    <p className="m-0">
                      জাতীয় গ্রন্থাগার ভবন, আগারগাঁও, শেরেবাংলা নগর, ঢাকা।
                    </p>

                    <p className="m-0">
                      ওয়েবসাইট :{" "}
                      <Link target="_blank" to={"//www.copyrightoffice.gov.bd"}>
                        www.copyrightoffice.gov.bd
                      </Link>
                    </p>
                  </div>
                  <div
                    className={`d-flex justify-content-between py-3 mt-2 align-items-center`}
                  >
                    <div
                      className={`d-flex gap-2 ${
                        !isEdit && "align-items-center "
                      }`}
                    >
                      {/* <span className={`${isEdit && "mt-2"} fw-semibold`}>
                  নথি নং -{" "}
                </span> */}
                      {!isEdit ? (
                        <> {formik.values.record_no}</>
                      ) : (
                        <span>
                          {" "}
                          <Form.Control
                            placeholder="নথি / স্বারক / রেজিঃ নং "
                            value={formik.values.record_no}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            errors={formik.errors}
                            touched={formik.touched}
                            name="record_no"
                          />
                          {formik.errors.record_no &&
                            formik.touched.record_no && (
                              <span className="text-danger">
                                {formik.errors.record_no}
                              </span>
                            )}
                        </span>
                      )}
                    </div>
                    <div
                      className={`d-flex gap-3 ${
                        !isEdit && "align-items-center "
                      }`}
                    >
                      <div
                        className={`d-flex gap-3 ${
                          isEdit && "mt-4"
                        } fw-semibold`}
                      >
                        তারিখঃ
                      </div>
                      <div>
                        {!isEdit ? (
                          <>
                            {localDateFormatNameBN(formik.values.record_date)}
                            <hr className="m-0" />
                            {localDateFormatName(formik.values.record_date)}
                          </>
                        ) : (
                          <>
                            {localDateFormatNameBN(formik.values.record_date)}
                            <BanglaCalender
                              placeholder={"তারিখ"}
                              value={formik.values.record_date}
                              onChange={(val) => {
                                formik.setFieldValue(
                                  "record_date",
                                  globalDateFormat(val)
                                );
                              }}
                            />
                            {formik.errors?.record_date ? (
                              <span className="text-danger">
                                {formik.errors.record_date}
                              </span>
                            ) : (
                              <div style={{ height: "24px" }}></div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className={`fw-semibold mt-4 w-100 d-flex gap-2`}>
                      <span className={`${isEdit && "mt-2"} `}>বিষয়ঃ </span>
                      {!isEdit ? (
                        <> {formik.values.subject}</>
                      ) : (
                        <div className="w-100">
                          {" "}
                          <Form.Control
                            value={formik.values.subject}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            errors={formik.errors}
                            touched={formik.touched}
                            name="subject"
                          />
                          {formik.errors.subject && formik.touched.subject && (
                            <span className="text-danger">
                              {formik.errors.subject}
                            </span>
                          )}
                        </div>
                      )}
                    </p>
                  </div>
                  <p className="pb-4" style={{ textAlign: "justify" }}>
                    {!isEdit ? (
                      <p
                        className="m-0"
                        dangerouslySetInnerHTML={{ __html: content }}
                      ></p>
                    ) : (
                      <>
                        <JoditEditor
                          ref={editor}
                          value={content}
                          config={config}
                          tabIndex={1}
                          onBlur={handleEditorChange}
                        />
                        {errors ? (
                          <span className="text-danger">{errors}</span>
                        ) : (
                          <div style={{ height: "24px" }}></div>
                        )}
                      </>
                    )}
                  </p>
                  <div className="d-flex justify-content-between my-2">
                    <div>
                      <div className="mt-3">
                        {info.name && (
                          <>
                            <p className="m-0">{info?.name}</p>
                            <p className="m-0">{info?.email}</p>
                            <p className="m-0">{info?.address}</p>
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="mt-3 text-end">
                        {info.fileStatus !== 6 && (
                          <>
                            {user?.sign && (
                              <>
                                <img
                                  style={{ height: "40px" }}
                                  src={user?.sign}
                                  alt=""
                                />
                              </>
                            )}
                            <p className="m-0">{user?.name}</p>
                            <p className="m-0">{user?.role?.bnName}</p>
                            <p className="m-0">{user?.email}</p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {[6].includes(info.fileStatus) && (
              <div className="row">
                <div className="col-md-6">
                  <label className="my-2 fw-semibold" htmlFor="role_id">
                    পদবী
                  </label>
                  <Select
                    name="role_id"
                    options={rolesOption}
                    value={
                      formik.values.role_id
                        ? rolesOption.find(
                            (option) => option.value === formik.values.role_id
                          )
                        : defaultValue
                    }
                    onChange={(selectedOption) => {
                      findUser(selectedOption.value);
                      formik.setValues((prev) => {
                        return {
                          ...prev,
                          user_id: "",
                        };
                      });
                      formik.setFieldValue("role_id", selectedOption.value);
                    }}
                    onBlur={formik.handleBlur}
                    classNamePrefix="react-select"
                  />
                  {formik.touched.role_id && formik.errors.role_id ? (
                    <div className="text-danger">{formik.errors.role_id}</div>
                  ) : null}
                </div>
                <div className="col-md-6">
                  <label className="my-2 fw-semibold" htmlFor="user_id">
                    প্রাপক
                  </label>
                  <Select
                    name="user_id"
                    options={usersOption}
                    value={
                      formik.values.user_id
                        ? usersOption.find(
                            (option) => option.value === formik.values.user_id
                          )
                        : defaultValue
                    }
                    onChange={(selectedOption) =>
                      formik.setFieldValue("user_id", selectedOption.value)
                    }
                    onBlur={formik.handleBlur}
                    classNamePrefix="react-select"
                  />
                  {formik.touched.user_id && formik.errors.user_id ? (
                    <div className="text-danger">{formik.errors.user_id}</div>
                  ) : null}
                </div>
              </div>
            )}
            {info.fileStatus === 30 && (
              <>
                <Form.Control
                  name="comments"
                  placeholder="এখানে লিখুন"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  as={"textarea"}
                  rows={6}
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={formik.touched.comments && formik.errors.comments}
                />
                {formik.touched.comments && formik.errors.comments ? (
                  <div className="text-danger">{formik.errors.comments}</div>
                ) : null}
              </>
            )}
            <div className="my-4 text-center d-flex justify-content-center gap-3">
              {[6, 29].includes(info.fileStatus) && (
                <>
                  <div className="mr-3">
                    <Button
                      onClick={() => setIsEdit(!isEdit)}
                      disabled={isLoadingVerify}
                    >
                      {isEdit ? "প্রিভিউ" : "সম্পাদনা করুন"}
                    </Button>
                  </div>
                </>
              )}
              <div className="mr-3">
                <Button
                  variant="success"
                  type="submit"
                  disabled={isLoadingVerify}
                >
                  {[29].includes(info.fileStatus)
                    ? "অনুমোদন"
                    : info.fileStatus === 30
                    ? "চিঠি পাঠান"
                    : "দাখিল করুন"}
                </Button>
              </div>
            </div>
          </form>
        </Tab>
        {info.fileStatus === 6 && (
          <Tab eventKey="two" title={"নথি স্থানান্তর"}>
            <UnauthorizedNothiTransfer info={info} />
          </Tab>
        )}
      </Tabs>
    </>
  );
};

export default UnauthorizedContent;
