// export const BASE_URL = "http://127.0.0.1:8000";
// export const BASE_URL = "http://192.168.0.175:8000";
export const BASE_URL = "https://server.safaresidencybd.com";

// Local storage
export const LOCAL_STORAGE_TOKEN = "token";
export const LOCAL_STORAGE_USER_INFO = "userInfo";

// AUTHENTICATION
export const USER_LOGIN = "/api/login";
export const USER_LOGOUT = "/api/logout";
export const USER_REGISTER = "/api/sign-up";
export const EMAIL_VERIFICATION = "/api/email-verification";
export const RESEND_EMAIL = "/api/resend-verify-email";
export const FORGOT_PASSWORD = "/api/forgot-password";
export const RESET_PASSWORD = "/api/reset-password";

// roles
export const ROLE_URL = "/api/roles";
export const ROLE_NOT_IN = "/api/roles/find-role-not-in";
export const ROLE_IN = "/api/roles/find-role-in";

// user information
export const USER_URL = "/api/users";
export const USER_FIND = "/api/users/find";
export const USER_PROFILE = "/api/users/profile";
export const USER_IMAGE = "/api/users/user-image";
export const USER_ADMIN_UPDATE = "/api/users/update-admin-user";
export const USER_ADMIN_UPDATE_PROFILE = "/api/users/update-admin-user-profile";
// change-password
export const CHANGE_PASSWORD = "/api/change-password";

// category
export const CATEGORY = "/api/copyright/categories";
export const CATEGORY_SHOW = "/api/copyright/category";

/// copyright application form one
export const APPLICATION_FORM_ONE_CREATE = "/api/applicant/applications";

// copyright application form two
export const APPLICATION_FORM_TWO_CREATE = "/api/applicant/application-details";
// copyright application list

export const APPLICATION_LIST = "/api/applicant/my-applications";

// agreement agreement

export const AGREEMENT_AGREEMENTS = "/api/applicant/agreements";
// agreement agreement details
export const APPLICATION_FORM_TWO_DETAILS_CREATE =
  "/api/applicant/agreements-details";

// payment
export const PAYMENT_STATUS = "/api/fees/payment-status";
export const PAYMENT_RESPONSE = "/api/fees/payment-response";

// website settings
export const WEBSITE_SETTINGS_FILE_SIZE_SHOW = "/api/settings/show/Filesize";
export const WEBSITE_SETTINGS_FILE_SIZE_UPDATE = "/api/settings/file-size";

export const WEBSITE_GENERAL_SETTINGS_SHOW = "/api/settings/show/General";
export const WEBSITE_GENERAL_SETTINGS_UPDATE = "/api/settings/general";

export const WEBSITE_SETTINGS_COPYRIGHT_SHOW = "/api/settings/show/Copyright";
export const WEBSITE_SETTINGS_COPYRIGHT_UPDATE = "/api/settings/copyright";

export const WEBSITE_SETTINGS_CONTRACT_SHOW = "/api/settings/show/Contract";
export const WEBSITE_SETTINGS_CONTRACT_UPDATE = "/api/settings/contract";

export const WEBSITE_SETTINGS_EMAIL_SHOW = "/api/settings/show/Email";
export const WEBSITE_SETTINGS_EMAIL_UPDATE = "/api/settings/email";

export const WEBSITE_SETTINGS_CONTACT_SHOW = "/api/settings/show/CONTACT";
export const WEBSITE_SETTINGS_CONTACT_UPDATE = "/api/settings/contact";

// category
export const CATEGORY_CATALOG = "/api/catalog/categories";
export const CATEGORY_CATALOG_UPDATE = "/api/catalog/categories/update-all";
export const COPYRIGHT_RELATEDRIGHTS =
  "/api/catalog/copyright-relatedright-types";
export const UPDATE_SORTING_CATALOG = "/api/catalog/update-sorting";
export const OTHER_SUB_CATEGORY = "/api/catalog/other-sub-categories";

//registration-regulations
export const CATALOG_REGISTRATION = "/api/copyright/rules-list";

// registration systems
export const REGISTRATION_SYSTEMS = "/api/catalog/registration-regulations";

// faq
export const FAQ = "/api/faq";
export const FAQ_CLIENT = "/api/copyright/faq";

// sample attachment

export const SAMPLE_ATTACHMENT = "/api/sample-attachment";
export const SAMPLE_ATTACHMENT_CLIENT = "/api/copyright/sample-attachment";

// copyright office

export const COPYRIGHT_OFFICE = "/api/copyright/copyright-office";
export const COPYRIGHT_OFFICE_UPDATE = "/api/copyright-office";

// contact
export const CONTACT_US = "/api/contacts";
export const CONTACT_US_CLIENT = "/api/copyright/contacts";
export const CONTACT_MESSAGE_REPLY = "/api/reply-contact-message";

// user role

//documents
export const CATALOG_DOCUMENT = "/api/copyright/documents";

// applications process
export const APPLICATION_PAYMENT_PROCESS = "/api/fees/process-payment";
export const APPLICATION_FREES = "/api/fees/initial-payment";
export const BANK_BRANCH = "/api/catalog/bankbranches";
export const BANK = "/api/catalog/banks";

// digital rights

// application copyright
export const GET_BY_ROLE = "/api/users/get-by-role";
export const APPLICATION_GET = "/api/distribution/applications";
export const APPLICATION_SEND = "/api/distribution/application-send";

// application agreements
export const APPLICATION_AGREEMENT_GET = "/api/distribution/agreements";
export const APPLICATION_AGREEMENT_SEND = "/api/distribution/agreement-send";

// copyright and agreement

export const COPYRIGHT_AGREEMENT_COUNT =
  "/api/base/users-by-role-with-file-count";

// submitted copyright and agreement

export const SUBMITTED_APPLICATION_COPYRIGHT = "/api/admin/applications";
export const SUBMITTED_APPLICATION_AGREEMENT = "/api/admin/agreements";
// submitted details

export const SUBMITTED_APPLICATION_DETAILS_COPYRIGHT = "/api/admin/application";
export const SUBMITTED_APPLICATION_DETAILS_AGREEMENT = "/api/admin/agreement";

// inspector verify

export const INSPECTOR_VERIFY_APPLICATIONS = "/api/admin/inspector/verify";

// indexer verify

export const INDEXER_VERIFY = "/api/admin/indexer/verify";

// verify assistant examiner

export const ASSISTANT_EXAMINER_VERIFY = "/api/admin/assistant-examiner/verify";

///admin/examiner/verify/

export const EXAMINER_VERIFY = "/api/admin/examiner/verify";

// /admin/programmer/verify/

export const PROGRAMMER_VERIFY = "/api/admin/programmer/verify";
// /admin/assistant-registrar/verify/

export const ASSISTANT_REGISTRAR_VERIFY =
  "/api/admin/assistant-registrar/verify";
// /admin/deputy-registrar/verify/

export const DEPUTY_REGISTRAR_VERIFY = "/api/admin/deputy-registrar/verify";
// /admin/registrar/verify/

export const REGISTRAR_VERIFY = "/api/admin/registrar/verify";

// echalan verify

export const ECHALAN_VERIFY = "/api/admin/echalan/verify";

// /admin/reply/additional-explanation/

export const REPLY_ADDITIONAL_EXPLANATION =
  "/api/admin/reply/additional-explanation";

// transferring/additional-explanation
export const TRANSFER_ADDITIONAL_EXPLANATION =
  "/api/admin/transferring/additional-explanation";

// originality testing

export const ORIGINALITY_TESTING = "/api/admin/agreement/originality-test";

// copyright originality testing

export const ORIGINALITY_TESTING_COPYRIGHT =
  "/api/admin/application/originality-test";

// indexer initial verify

export const INDEXER_INITIAL_VERIFY = "/api/admin/indexer/initial-verify";

// agreement treasury verify

export const AGREEMENT_TREASURY_VERIFY = "/api/admin/agreement/treasury/verify";
// /admin/application/treasury/verify treasury verify

export const COPYRIGHT_TREASURY_VERIFY =
  "/api/admin/application/treasury/verify";

// letter correction ================================================

// correction letter show copyright
export const CORRECTION_LETTER_SHOW_COPYRIGHT =
  "/api/admin/application/correction/letter-show";
// correction letter show agreement
export const CORRECTION_LETTER_SHOW_AGREEMENT =
  "/api/admin/agreement/correction/letter-show";

// /admin/correction/application-show
export const CORRECTION_COPYRIGHT_SHOW =
  "/api/admin/correction/application-show";
// /admin/correction/agreement-show
export const CORRECTION_AGREEMENT_SHOW = "/api/admin/correction/agreement-show";
// admin/forward-sent-application

export const FORWARD_SENT_APPLICATION = "/api/admin/forward-sent-application";

///admin/correction/letter-send-for-approval
export const CORRECTION_LETTER_SEND_FOR_APPROVAL =
  "/api/admin/correction/letter-send-for-approval";
// /admin/correction/letter-approved
export const CORRECTION_LETTER_APPROVED =
  "/api/admin/correction/letter-approved";

// /admin/correction/letter-send-to-applicant
export const CORRECTION_LETTER_SEND_TO_APPLICANT =
  "/api/admin/correction/letter-send-to-applicant";

// /correction/application-sent
export const CORRECTION_COPYRIGHT_SENT =
  "/api/admin/correction/application-sent";

// /correction/agreement-sent
export const CORRECTION_AGREEMENT_SENT = "/api/admin/correction/agreement-sent";

//admin/application-show-full-form

export const APPLICATION_SHOW_FULL_FORM =
  "/api/admin/application-show-full-form";

//admin/agreement-show-full-form/

export const AGREEMENT_SHOW_FULL_FORM = "/api/admin/agreement-show-full-form";

// letter correction =======================================================

// hearing =================================================================
// /admin/correction/agreement-sent
export const HEARING_AGREEMENT_SENT = "/api/admin/correction/agreement-sent";

// /admin/correction/application-sent
export const HEARING_COPYRIGHT_SENT = "/api/admin/correction/application-sent";

// /admin/application/hearing/letter-show/

export const HEARING_LETTER_SHOW_COPYRIGHT =
  "/api/admin/application/hearing/letter-show";

// /admin/agreement/hearing/letter-show/
export const HEARING_LETTER_SHOW_AGREEMENT =
  "/api/admin/agreement/hearing/letter-show";

// /admin/hearing/letter-send-for-approval/
export const HEARING_LETTER_SEND_FOR_APPROVAL =
  "/api/admin/hearing/letter-send-for-approval";

// /admin/correction/letter-approved/
export const HEARING_LETTER_APPROVED = "/api/admin/hearing/letter-approved";

// admin/correction/letter-send-to-applicant/
export const HEARING_LETTER_SEND_TO_APPLICANT =
  "/api/admin/hearing/letter-send-to-applicant";

// /admin/hearing/complainant/
export const HEARING_COMPLAINANT_DELETE = "/api/admin/hearing/complainant";

// /admin/hearing/schedule-sent
export const HEARING_SCHEDULE_SENT = "/api/admin/hearing/schedule-sent";
// /admin/hearing/letter-show/
export const HEARING_LETTER_SHOW_COMPLAINANT = "/api/admin/hearing/letter-show";

// =============================================================================

// admin/investigation/report-send

export const INVESTIGATION_REPORT_SEND = "/api/admin/investigation/report-send";

// /admin/transferring-investigation

export const TRANSFER_INVESTIGATION_SEND =
  "/api/admin/transferring-investigation";

// ========================Recorded========================================

// /admin/application/recorded/letter-show

export const RECORD_LETTER_SHOW_COPYRIGHT =
  "/api/admin/application/recorded/letter-show";

// admin/agreement/recorded/letter-show
export const RECORD_LETTER_SHOW_AGREEMENT =
  "/api/admin/agreement/recorded/letter-show";

// /admin/recorded/letter-send-for-approval
export const RECORD_LETTER_SEND_FOR_APPROVAL =
  "/api/admin/recorded/letter-send-for-approval";

// admin/recorded/letter-approved
export const RECORD_LETTER_APPROVED = "/api/admin/recorded/letter-approved";

// /admin/recorded/letter-send-to-applicant
export const RECORD_LETTER_SEND_TO_APPLICANT =
  "/api/admin/recorded/letter-send-to-applicant";

// =================================================================

// ========================unauthorized========================================

// /admin/application/unauthorized/letter-show

export const UNAUTHORIZED_LETTER_SHOW_COPYRIGHT =
  "/api/admin/application/unauthorized/letter-show";

// admin/agreement/unauthorized/letter-show
export const UNAUTHORIZED_LETTER_SHOW_AGREEMENT =
  "/api/admin/agreement/unauthorized/letter-show";

// /admin/unauthorized/letter-send-for-approval
export const UNAUTHORIZED_LETTER_SEND_FOR_APPROVAL =
  "/api/admin/unauthorized/letter-send-for-approval";

// admin/unauthorized/letter-approved
export const UNAUTHORIZED_LETTER_APPROVED =
  "/api/admin/unauthorized/letter-approved";

// /admin/unauthorized/letter-send-to-applicant
export const UNAUTHORIZED_LETTER_SEND_TO_APPLICANT =
  "/api/admin/unauthorized/letter-send-to-applicant";

// /admin/application-register-books
export const APPLICATION_REGISTER_BOOKS =
  "/api/admin/application-registry-books";

// /admin/agreement-register-books
export const AGREEMENT_REGISTER_BOOKS = "/api/admin/agreement-registry-books";

// =================================================================

// /admin/certificates
export const CERTIFICATES_LIST = "/api/admin/certificates";

// /admin/endorses
export const ENDORSES_LIST = "/api/admin/endorses";

// category count for copyright and agreement

// /admin/application-with-categories
export const CATEGORY_COUNT_COPYRIGHT =
  "/api/admin/application-with-categories";

// /admin/agreement-with-categories
export const CATEGORY_COUNT_AGREEMENT = "/api/admin/agreement-with-categories";

// /catalog/attention-messages
export const CATALOG_ATTENTION_MESSAGES = "/api/catalog/attention-messages";

// admin/certificate-prepare
export const CERTIFICATE_PREPARE = "/api/admin/certificate-prepare";
// admin/endorse-prepare
export const ENDORSE_PREPARE = "/api/admin/endorse-prepare";

// admin/certificate-language
export const CERTIFICATE_LANGUAGE = "/api/admin/certificate-language";

// admin/certificate
export const CERTIFICATE_SHOW = "/api/admin/certificate";

// admin/endorse
export const ENDORSE_SHOW = "/api/admin/endorse";

///admin/certificate-edit-permission
export const CERTIFICATE_EDIT_PERMISSION =
  "/api/admin/certificate-edit-permission";

// admin/certificate-type-update
export const CERTIFICATE_TYPE_UPDATE = "/api/admin/certificate-type-update";

// certificate-language-update
export const CERTIFICATE_LANGUAGE_UPDATE =
  "/api/admin/certificate-language-update";

// admin/certificate-attention-update
export const CERTIFICATE_ATTENTION_UPDATE =
  "/api/admin/certificate-attention-update";

// admin/certificate-artistic-work-update
export const CERTIFICATE_ARTIST_WORK =
  "/api/admin/certificate-artistic-work-update";

// /admin/certificate-issue-endorse/
export const CERTIFICATE_ISSUE_ENDORSE = "/api/admin/certificate-issue-endorse";

// admin/file-location-search
export const FILE_LOCATION_SEARCH = "/api/admin/file-location-search";
// /admin/comment-and-ordered-search
export const COMMENT_AND_ORDERED_SEARCH =
  "/api/admin/comment-and-ordered-search";

// /admin/email-for-correction
export const EMAIL_FOR_CORRECTION = "/api/admin/email-for-correction";

// /admin/restore-sent-application
export const RESTORE_SENT_APPLICATION = "/api/admin/restore-sent-application";

// from edit
// copyright first part
// application-edit/first-part-show
export const APPLICATION_EDIT_FIRST_PART_SHOW =
  "/api/application-edit/first-part-show";

//application-edit/first-part-update
export const APPLICATION_EDIT_FIRST_PART_UPDATE =
  "/api/application-edit/first-part-update";

// "/api/admin/file-forward-with-note/"
export const FILE_FORWARD_WITH_NOTE = "/api/admin/file-forward-with-note";

// applicant/applications/DELETE
export const APPLICANT_APPLICATIONS_DELETE = "/api/applicant/applications";

// copyright second part
// application-edit/second-part-show

export const APPLICATION_EDIT_SECOND_PART_SHOW =
  "/api/application-edit/second-part-show";

// report
// report/file-activity-list
export const REPORT_FILE_ACTIVITY_LIST = "/api/report/file-activity-list";

// /base/users-by-role-with-file-count
export const USERS_BY_ROLE_WITH_FILE_COUNT =
  "/api/base/users-by-role-with-file-count";

// /report/activity-count-history
export const ACTIVITY_COUNT_HISTORY = "/api/report/activity-count-history";

// /base/count-by-file-status-group

export const COUNT_BY_FILE_STATUS_GROUP =
  "/api/base/count-by-file-status-group";

// destroy anything
// /delete/exists-files
export const DELETE_EXISTS_FILES = "/api/delete/exists-files";
// delete/profile-detail-type
export const DELETE_PROFILE_DETAIL_TYPE = "/api/delete/profile-detail-type";
// delete/profile-detail-self-type
export const DELETE_PROFILE_DETAIL_SELF_TYPE =
  "/api/delete/profile-detail-self-type";

// delete/basic-work-type
export const DELETE_BASIC_WORK_TYPE = "/api/delete/basic-work-type";

// delete/work-type
export const DELETE_WORK_TYPE = "/api/delete/work-type";

// delete/basic-publish
export const DELETE_BASIC_PUBLISH = "/api/delete/basic-publish";

// delete/authorize-type
export const DELETE_AUTHORIZE_TYPE = "/api/delete/authorize-type";

// delete/writer-details
export const DELETE_WRITER_DETAILS = "/api/delete/writer-details";

// delete/publish-details
export const DELETE_PUBLISH_DETAILS = "/api/delete/publish-details";

// delete/owner-type
export const DELETE_OWNER_TYPE = "/api/delete/owner-type";

// delete/power-type
export const DELETE_POWER_TYPE = "/api/delete/power-type";

// application-edit/second-part-update

export const APPLICATION_EDIT_SECOND_PART_UPDATE =
  "/api/application-edit/second-part-update";

// delete/work-soft-copy
export const DELETE_WORK_SOFT_COPY = "/api/delete/work-soft-copy";

// application-edit/payment-show
export const APPLICATION_EDIT_PAYMENT_SHOW =
  "/api/application-edit/payment-show";

// agreement-edit/first-part-show
export const AGREEMENT_EDIT_FIRST_PART_SHOW =
  "/api/agreement-edit/first-part-show";

// agreement-edit/first-part-update
export const AGREEMENT_EDIT_FIRST_PART_UPDATE =
  "/api/agreement-edit/first-part-update";

// /agreement-edit/second-part-show

export const AGREEMENT_EDIT_SECOND_PART_SHOW =
  "/api/agreement-edit/second-part-show";
// /agreement-edit/second-part-update
export const AGREEMENT_EDIT_SECOND_PART_UPDATE =
  "/api/agreement-edit/second-part-update";

// application-edit/tresary-payment-update
export const APPLICATION_EDIT_TRESARY_PAYMENT_UPDATE =
  "/api/application-edit/tresary-payment-update";

// correction form update
// applicant/correction-show
export const CORRECTION_SHOW = "/api/applicant/correction-show";

// copyright form data show
// applicant/application-first-part-correction-show
export const APPLICATION_FIRST_PART_CORRECTION_SHOW =
  "/api/applicant/application-first-part-correction-show";

// applicant/application-first-part-correction-update
export const APPLICATION_FIRST_PART_CORRECTION_UPDATE =
  "/api/applicant/application-first-part-correction-update";

// applicant/application-second-part-correction-show
export const APPLICATION_SECOND_PART_CORRECTION_SHOW =
  "/api/applicant/application-second-part-correction-show";

// applicant/application-second-part-correction-update
export const APPLICATION_SECOND_PART_CORRECTION_UPDATE =
  "/api/applicant/application-second-part-correction-update";

// agreement form data show
// /applicant/agreement-first-part-correction-show
export const AGREEMENT_FIRST_PART_CORRECTION_SHOW =
  "/api/applicant/agreement-first-part-correction-show";
// applicant/agreement-first-part-correction-update
export const AGREEMENT_FIRST_PART_CORRECTION_UPDATE =
  "/api/applicant/agreement-first-part-correction-update";

// /applicant/agreement-second-part-correction-show
export const AGREEMENT_SECOND_PART_CORRECTION_SHOW =
  "/api/applicant/agreement-second-part-correction-show";
// applicant/agreement-second-part-correction-update
export const AGREEMENT_SECOND_PART_CORRECTION_UPDATE =
  "/api/applicant/agreement-second-part-correction-update";

// copyright and agreement payment data show
// applicant/payment-correction-show
export const PAYMENT_CORRECTION_SHOW = "/api/applicant/payment-correction-show";

// applicant/treasury-payment-correction
export const TREASURY_PAYMENT_CORRECTION =
  "/api/applicant/treasury-payment-correction";

// admin/comment-and-ordered-search-list
export const COMMENT_AND_ORDERED_SEARCH_LIST =
  "/api/admin/comment-and-ordered-search-list";

// /admin/reply-ordered-search
export const REPLY_ORDERED_SEARCH = "/api/admin/reply-ordered-search";

// admin/user-to-user-file-transfer

export const USER_TO_USER_FILE_TRANSFER =
  "/api/admin/user-to-user-file-transfer";

// /admin/dashboard/calendar-of-hearings
export const CALENDAR_OF_HEARINGS = "/api/admin/dashboard/calendar-of-hearings";
// /admin/dashboard/show-date-of-hearing
export const SHOW_DATE_OF_HEARING = "/api/admin/dashboard/show-date-of-hearing";

// /report/agreements-report
export const AGREEMENTS_REPORT = "/api/report/agreements-report";
///report/agreements-report-print
export const AGREEMENTS_REPORT_PRINT = "/api/report/agreements-report-print";
// /report/applications-report
export const APPLICATIONS_REPORT = "/api/report/applications-report";
// /report/applications-report-print
export const APPLICATIONS_REPORT_PRINT =
  "/api/report/applications-report-print";

///applicant/applications
export const APPLICATIONS_TRACK = "/api/applicant/applications";

// /admin/latest-reply-ordered-show/
export const LATEST_REPLY_ORDERED_SHOW = "/api/admin/latest-reply-ordered-show";

// /admin/payments/list
export const PAYMENTS_LIST = "/api/admin/payments/list";

// /admin/payments/view/
export const PAYMENTS_VIEW = "/api/admin/payments/view";
