import { Print } from "@mui/icons-material";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { assets } from "../../assets";
import { useLazyGetByRoleQuery } from "../../redux/api/applicationCopyrightApiSlice";
import { useGetRoleInQuery } from "../../redux/api/categoryApiSlice";
import { useHearingScheduleSentMutation } from "../../redux/api/submittedCopyrightAgreementApiSlice";
import { useAuth } from "../../utils/authorization";
import {
  localDateFormat,
  localDateFormatName,
  localDateFormatNameBN,
  localTimeFormat,
} from "../../utils/dateFormat";
import { bnTimeFormat } from "../../utils/englishToBengaliNumber";
import { transformErrorsToObjectStructure } from "../../utils/transformErrorsToObjectStructure";

const HearingScheduleContent = ({ info }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { authUser } = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const printLetter = useRef();
  const [
    Verify,
    {
      isLoading: isLoadingVerify,
      isSuccess: isSuccessSubmit,
      error: errorSubmit,
      isError: isErrorSubmit,
      data: dataSubmit,
    },
  ] = useHearingScheduleSentMutation();

  const roleMapping = "[2, 4]";

  const { data: roles } = useGetRoleInQuery(roleMapping);
  const rolesOption =
    roles?.data?.map((data) => ({
      value: data.id,
      label: data.bn_name,
    })) || [];

  const [findUser, { data: users }] = useLazyGetByRoleQuery();

  const usersOption =
    (users?.data &&
      Object.entries(users.data)
        .filter(([key]) => authUser.id !== Number(key))
        .map(([key, value]) => ({
          value: Number(key),
          label: value,
        }))) ||
    [];

  const validationSchema = Yup.object().shape({
    role_id: Yup.number().required("পদবী প্রযোজ্য।"),
    user_id: Yup.number().required("প্রাপক প্রযোজ্য।"),
    comments: Yup.string().required("মন্তব্য প্রযোজ্য।"),
  });

  const formik = useFormik({
    initialValues: {
      role_id: "",
      user_id: "",
      comments: "",
    },
    validationSchema,
    onSubmit: (values) => {
      Swal.fire({
        text: "আপনি কি নিশ্চিত?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      }).then((result) => {
        if (result.isConfirmed) {
          Verify({ id, formData: values });
        }
      });
    },
  });
  useEffect(() => {
    if (isSuccessSubmit) {
      toast.success(dataSubmit?.message);
      formik.resetForm();
      navigate(-1);
    }
    if (isErrorSubmit) {
      toast.error(errorSubmit?.data?.message);
      formik.setErrors(
        transformErrorsToObjectStructure(errorSubmit.data?.data || {})
      );
    }
    // eslint-disable-next-line
  }, [isErrorSubmit, isSuccessSubmit]);

  const isAuthorized = [2, 4, 11, 3].includes(authUser.role_id);
  const user = info?.hearing?.user;

  const hearing = info?.hearing;
  const banglaTime = bnTimeFormat(hearing?.time);

  const defaultValue = {
    value: "",
    label: "নির্বাচন করুন",
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center bg-slate p-2">
        <div className="fw-semibold">শুনানীর চিঠি</div>
        <ReactToPrint
          trigger={() => <Print className="fs-4 text-primary cursor-pointer" />}
          content={() => printLetter.current}
        />
      </div>
      {info.fileStatus === 10 && (
        <div className="card p-2 mt-2">
          <div>
            <label htmlFor="">সংশোধনের আদেশ</label>
            <p>{info?.hearing?.comment}</p>
          </div>
          <div className="d-flex justify-content-end">
            <div className="text-center">
              <div className="fw-semibold">আদেশক্রমে</div>
              <p className="m-0">{info?.hearing?.user?.name}</p>
              <p className="m-0">{info?.hearing?.user?.role?.bnName}</p>
              <div>তারিখ : {localDateFormat(info?.hearing?.updated_at)}</div>
              <div>সময় : {localTimeFormat(info?.hearing?.updated_at)}</div>
            </div>
          </div>
        </div>
      )}
      {info.fileStatus === 11 && (
        <div className="card p-2 mt-2">
          <span className="fw-semibold">হইতে, </span>{" "}
          {info?.hearing?.sendBy.name} ({info?.hearing?.sendBy?.role?.bnName})
        </div>
      )}

      <form onSubmit={formik.handleSubmit}>
        <div className="py-2" ref={printLetter}>
          <div className="card p-3">
            <div ref={printLetter}>
              <div className="text-center">
                <img
                  className="my-3"
                  src={assets.logoBlack}
                  style={{ width: "350px" }}
                  alt=""
                />
                <p className="m-0">কপিরাইট অফিস, সংস্কৃতি বিষয়ক মন্ত্রণালয়</p>

                <p className="m-0">
                  জাতীয় গ্রন্থাগার ভবন, আগারগাঁও, শেরেবাংলা নগর, ঢাকা।
                </p>

                <p className="m-0">
                  ওয়েবসাইট :{" "}
                  <Link target="_blank" to={"//www.copyrightoffice.gov.bd"}>
                    www.copyrightoffice.gov.bd
                  </Link>
                </p>
              </div>
              <div
                className={`d-flex justify-content-between py-3 mt-2 align-items-center`}
              >
                <div
                  className={`d-flex gap-2 ${!isEdit && "align-items-center "}`}
                >
                  <> {hearing?.record_no}</>
                </div>
                <div
                  className={`d-flex gap-3 ${!isEdit && "align-items-center "}`}
                >
                  <div
                    className={`d-flex gap-3 ${isEdit && "mt-2"} fw-semibold`}
                  >
                    তারিখঃ
                  </div>
                  <div>
                    <>
                      {localDateFormatNameBN(hearing?.record_date)}
                      <hr className="m-0" />
                      {localDateFormatName(hearing?.record_date)}
                    </>
                  </div>
                </div>
              </div>
              <div>
                <p
                  className={`fw-semibold mt-4 w-100 d-flex gap-2 ${
                    !isEdit && "align-items-center "
                  }`}
                >
                  <span className={`${isEdit && "mt-2"} `}>বিষয়ঃ </span>
                  <> {hearing?.subject}</>
                </p>
              </div>
              <p className="pb-4" style={{ textAlign: "justify" }}>
                <p
                  className="m-0"
                  dangerouslySetInnerHTML={{ __html: hearing?.detail }}
                ></p>
              </p>
              <div className="d-flex justify-content-between my-2">
                <div>
                  <div className={`d-flex gap-3 align-items-center`}>
                    <div className={`mt-2 fw-semibold`}>
                      শুনানীর তারিখ ও সময়ঃ
                    </div>
                  </div>
                  <div className={`d-flex gap-3 align-items-center`}>
                    <div>
                      <>
                        {localDateFormatName(hearing?.date) +
                          " ও " +
                          bnTimeFormat(hearing?.time)}
                      </>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <>
                    {user?.sign && (
                      <>
                        <img
                          style={{ height: "40px" }}
                          src={user?.sign}
                          alt=""
                        />
                      </>
                    )}
                    <p className="m-0">{user?.name}</p>
                    <p className="m-0">{user?.role?.bnName}</p>
                    <p className="m-0">{user?.email}</p>
                  </>
                </div>
              </div>
              <div className="row">
                <div className="mt-3 col-md-6">
                  {info.name && (
                    <>
                      {info?.sign && (
                        <>
                          <img
                            style={{ height: "40px" }}
                            src={info?.sign}
                            alt=""
                          />
                        </>
                      )}
                      <p className="m-0">{info?.name}</p>
                      <p className="m-0">{info?.email}</p>
                      <p className="m-0">{info?.address}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex gap-3 mt-3">
                {hearing?.isComplainant === 1 && (
                  <span className="fw-semibold">বাদীঃ</span>
                )}
              </div>
              <div>
                {hearing?.isComplainant === 1 && (
                  <div className="mt-3 row">
                    {hearing?.complainants.map((complainant, i) => (
                      <div key={i} className="col-md-12 mt-2">
                        <div className="mb-2">
                          <span>{complainant?.name}</span>
                        </div>
                        <div className="mb-2">
                          <span>{complainant?.mobile}</span>
                        </div>
                        <div className="mb-2">
                          <span>{complainant?.email}</span>
                        </div>
                        <div className="mb-2">
                          <span>{complainant?.address}</span>
                        </div>

                        <hr />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-2">
            <label className="my-2 fw-semibold" htmlFor="comments">
              শুনানীর আদেশ{" "}
            </label>
            <Form.Control
              name="comments"
              placeholder="এখানে লিখুন"
              aria-label="Username"
              aria-describedby="basic-addon1"
              as={"textarea"}
              rows={6}
              value={formik.values.comments}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.comments && formik.errors.comments}
            />
            {formik.touched.comments && formik.errors.comments ? (
              <div className="text-danger">{formik.errors.comments}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <label className="my-2 fw-semibold" htmlFor="role_id">
              পদবী
            </label>
            <Select
              name="role_id"
              options={rolesOption}
              value={
                formik.values.role_id
                  ? rolesOption.find(
                      (option) => option.value === formik.values.role_id
                    )
                  : defaultValue
              }
              onChange={(selectedOption) => {
                findUser(selectedOption.value);
                formik.setValues((prev) => {
                  return {
                    ...prev,
                    user_id: "",
                  };
                });
                formik.setFieldValue("role_id", selectedOption.value);
              }}
              onBlur={formik.handleBlur}
              classNamePrefix="react-select"
            />
            {formik.touched.role_id && formik.errors.role_id ? (
              <div className="text-danger">{formik.errors.role_id}</div>
            ) : null}
          </div>
          <div className="col-md-6">
            <label className="my-2 fw-semibold" htmlFor="user_id">
              প্রাপক
            </label>
            <Select
              name="user_id"
              options={usersOption}
              value={
                formik.values.user_id
                  ? usersOption.find(
                      (option) => option.value === formik.values.user_id
                    )
                  : defaultValue
              }
              onChange={(selectedOption) =>
                formik.setFieldValue("user_id", selectedOption.value)
              }
              onBlur={formik.handleBlur}
              classNamePrefix="react-select"
            />
            {formik.touched.user_id && formik.errors.user_id ? (
              <div className="text-danger">{formik.errors.user_id}</div>
            ) : null}
          </div>
        </div>

        <div className="my-4 text-center d-flex justify-content-center gap-3">
          <div className="mr-3">
            <Button variant="success" type="submit" disabled={isLoadingVerify}>
              দাখিল
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default HearingScheduleContent;
