import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ImPlus } from "react-icons/im";
import { IoCloseSharp } from "react-icons/io5";
import Select from "react-select";
import Swal from "sweetalert2";
import { assets } from "../../../../../assets";
import ClientFormikFormFieldMultiple from "../../../../../components/custom/form/ClientFormikFormFieldMultiple";
import ClientFormikYearSelector from "../../../../../components/custom/form/year/ClientFormikYearSelector";
import { useGetCategoriesQuery } from "../../../../../redux/api/categoryApiSlice";
import {
  useGetCopyrightQuery,
  useGetOtherSubCategoryQuery,
  useGetRelatedRightQuery,
  useLazyGetSubCategoryCatalogQuery,
} from "../../../../../redux/api/categoryCatalogApiSlice";
import {
  useDeleteExistsFilesMutation,
  useDeleteOwnerTypeMutation,
  useDeletePowerTypeMutation,
  useDeleteProfileDetailsSelfTypeMutation,
  useDeleteProfileDetailsTypeMutation,
} from "../../../../../redux/api/editFormApiSlice";
import { applicationFormTitle } from "../../../../../utils/applicationFormTitle";
import WorkMoulikBox from "../../../userPanel/form_two/WorkMoulikBox";
import styles from "./../../../userPanel/form_two/Add2.module.css";
import {
  initialValues,
  validationSchema,
} from "./validation_second_part/validation";

const CopyrightTwo = ({ category, subCategory }) => {
  const [checkIsAgree, setCheckIsAgree] = useState(false);
  const [checkBoxKeyType, setCheckBoxKeyType] = useState([]);

  const { data: categoryData, isSuccess: isSuccessCategory } =
    useGetCategoriesQuery();
  const { data: otherSubCategory, isLoading } = useGetOtherSubCategoryQuery();
  const [
    fetchSubCategory,
    { data: subCategoryData, isLoading: isLoadingSubCat, isFetching },
  ] = useLazyGetSubCategoryCatalogQuery();

  // copyright and related right
  const { data: copyrightData, isLoading: isLoadingCopyright } =
    useGetCopyrightQuery();
  const { data: relatedRightData, isLoading: isLoadingRelatedRight } =
    useGetRelatedRightQuery();

  // handle deleting files
  const [deleteProfileType] = useDeleteProfileDetailsTypeMutation();

  // handle deleting self type
  const [deleteSelfType] = useDeleteProfileDetailsSelfTypeMutation();

  const [deleteOwnerType] = useDeleteOwnerTypeMutation();
  const [deletePowerType] = useDeletePowerTypeMutation();
  const [deleteExistsFile] = useDeleteExistsFilesMutation();

  // Copyright

  const publicUrl = process.env.PUBLIC_URL;

  /// formik start
  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema({
      otherSubCategoryData: otherSubCategory?.data || [],
      checkBoxKeyType,
    }),
    context: { keytype: initialValues.basicworktypes[0].keytype },
    onSubmit: async (values, { setSubmitting }) => {
      //
    },
  });

  useEffect(() => {
    if (category) {
      fetchSubCategory({ parent_id: values.category });
      setValues((prevValues) => ({
        ...prevValues,
        category_id: Number(category),
        subCategory: Number(subCategory) || "",
      }));
    }
  }, [category, subCategory]);

  console.log(values);

  // step 2
  const handleAddFieldSet = () => {
    const newFieldSet = {
      name: "",
      email: "",
      phone: "",
      address: "",
      nid: "",
      image: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      profiledetailtypes: [
        ...(prevValues.profiledetailtypes || []),
        newFieldSet,
      ],
    }));
  };

  const removeJoutho = (idToRemove) => {
    const updatedFieldSets = values.profiledetailtypes.filter(
      (_, i) => i !== idToRemove
    );
    setValues((preValues) => ({
      ...preValues,
      profiledetailtypes: updatedFieldSets,
    }));
  };

  const handleRemoveFieldSet = async (idToRemove, itemId) => {
    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        // TODO: Remove
      }
    } else {
      removeJoutho(idToRemove);
    }
  };

  // step 4
  const handleAddFieldSetWorkSoftCopy = () => {
    const newFieldSet = {
      workSoftCopy: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      workSoftCopies: [...prevValues.workSoftCopies, newFieldSet],
    }));
  };

  const handleRemoveFieldSetWorkSoftCopy = async (idToRemove, item) => {
    const updateCheckboxState = () => {
      const updatedFieldSets = values.workSoftCopies.filter(
        (_, i) => i !== idToRemove
      );
      setValues((preValues) => ({
        ...preValues,
        workSoftCopies: updatedFieldSets,
      }));
    };

    if (item?.name) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  // step 4
  const handleAddFieldSetBoxPartSeven = () => {
    const newFieldSet = {
      writerName: "",
      writerAddress: "",
      writerNationality: "বাংলাদেশী",
      writerDeadDate: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      writerdetails: [...prevValues.writerdetails, newFieldSet],
    }));
  };

  const handleRemoveFieldSetBoxPartSeven = async (idToRemove, itemId) => {
    const updateCheckboxState = () => {
      const updatedFieldSets = values.writerdetails.filter(
        (_, i) => i !== idToRemove
      );
      setValues((preValues) => ({
        ...preValues,
        writerdetails: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  // step 10
  const handleAddFieldSetBoxPartNine = () => {
    const newFieldSet = {
      year: "",
      place: "",
      name: "",
      address: "",
      nationality: "বাংলাদেশী",
      literature_more_doc: "",
      photo: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      publishdetails: [...prevValues.publishdetails, newFieldSet],
    }));
  };

  const handleRemoveFieldSetBoxPartNine = async (idToRemove, itemId) => {
    const updateCheckboxState = () => {
      const updatedFieldSets = values.publishdetails.filter(
        (_, i) => i !== idToRemove
      );
      setValues((preValues) => ({
        ...preValues,
        publishdetails: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  // step 11
  const handleAddFieldSetBoxPartTen = () => {
    const newFieldSet = {
      ownerName: "",
      ownerAddress: "",
      ownerNationality: "বাংলাদেশী",
      partTitle: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      ownertypes: [...prevValues.ownertypes, newFieldSet],
    }));
  };
  const handleRemoveFieldSetBoxPartTen = async (idToRemove, itemId) => {
    const updateCheckboxState = () => {
      const updatedFieldSets = values.ownertypes.filter(
        (_, i) => i !== idToRemove
      );
      setValues((preValues) => ({
        ...preValues,
        ownertypes: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteOwnerType({
            id: itemId,
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  // step 12
  const handleAddFieldSetBoxPartEleven = () => {
    const newFieldSet = {
      name: "",
      address: "",
      nationality: "বাংলাদেশী",
    };
    setValues((prevValues) => ({
      ...prevValues,
      powertypes: [...prevValues.powertypes, newFieldSet],
    }));
  };

  const handleRemoveFieldSetBoxPartEleven = async (idToRemove, itemId) => {
    const updateCheckboxState = () => {
      const updatedFieldSets = values.powertypes.filter(
        (_, i) => i !== idToRemove
      );
      setValues((preValues) => ({
        ...preValues,
        powertypes: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deletePowerType({
            id: itemId,
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  // step 14
  const handleAddFieldSetEngCertificate = () => {
    const newFieldSet = {
      author_creator: "",
      owner_right: "",
      address: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      certificatelanguages: [...prevValues.certificatelanguages, newFieldSet],
    }));
  };

  const handleRemoveFieldSetEngCertificate = async (idToRemove, itemId) => {
    const updateCheckboxState = () => {
      const updatedFieldSets = values.certificatelanguages.filter(
        (_, i) => i !== idToRemove
      );
      setValues((preValues) => ({
        ...preValues,
        certificatelanguages: updatedFieldSets,
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  // step 3
  const handleAddFieldSetProneta = () => {
    const newFieldSet = {
      relatedright_id: 13,
      noc: "",
      sphoto: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      selftypes: [...prevValues.selftypes, newFieldSet],
    }));
  };

  // self type
  const removeSelftTypes = (idToRemove) => {
    const updatedFieldSets = values.selftypes.filter(
      (_, i) => i !== idToRemove
    );
    setValues((preValues) => ({
      ...preValues,
      selftypes: updatedFieldSets,
    }));
  };

  const handleRemoveFieldSetProneta = async (idToRemove, itemId) => {
    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteSelfType({
            id: itemId,
          }).unwrap();
          removeJoutho(idToRemove);
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      removeSelftTypes(idToRemove);
    }
  };

  // Toggole Dropdown
  const toggleSection2 = (side) => {
    setValues((prevValues) => ({
      ...prevValues,
      type: side,
    }));
  };

  const toggleSection3 = (side) => {
    setValues((prevState) => ({
      ...prevState,
      selfType: Number(side),
    }));
  };

  const handleCheckboxClick = async (box, itemId) => {
    // Update the checkbox state regardless of itemId
    const updateCheckboxState = () => {
      setCheckBoxKeyType((prev) =>
        prev.includes(box)
          ? prev.filter((item) => item !== box)
          : [...prev, box]
      );

      setValues((prevValues) => {
        const updatedSelftypes = prevValues.basicworktypes.map((item, i) => {
          // Ensure the item exists before attempting to update it
          if (!item) return item;

          const iniData = {
            title: "",
            language: "",
            worktypes: [
              { name: "", address: "", nationality: "বাংলাদেশী", deadDate: "" },
            ],
            basicpublishes: [
              { name: "", address: "", nationality: "বাংলাদেশী" },
            ],
            authorize: 2,
            authorizetypes: [
              {
                name: "",
                address: "",
                nationality: "বাংলাদেশী",
                approval_sc: "",
              },
            ],
          };

          if (i === box) {
            // Toggle keytype between box and null if already selected
            const updatedKeytype = item.keytype === box ? "" : box;
            return { ...item, keytype: updatedKeytype, ...iniData };
          } else {
            // Keep existing item unchanged
            return item;
          }
        });

        return {
          ...prevValues,
          basicworktypes: updatedSelftypes,
        };
      });
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  const toggleSection7 = (bool) => {
    setValues((prevState) => ({
      ...prevState,
      workPublishtype: bool,
      pub_unpub: bool,
      publishyear: "",
      publishplace: "",
      publishname: "",
      publishaddress: "",
      publishnationality: "বাংলাদেশী",
      literature_doc: "",
      year: "",
      month: "",
      day: "",
    }));
  };

  const toggleSection9 = (bool) => {
    setValues((prevState) => ({
      ...prevState,
      otherPublishtype: bool,
    }));
  };

  const toggleSection10 = (bool) => {
    setValues((prevState) => ({
      ...prevState,
      ownerType: bool,
    }));
  };

  const toggleSection11 = (bool) => {
    setValues((prevState) => ({
      ...prevState,
      powerType: bool,
    }));
  };

  const toggleSection14 = (bool) => {
    setValues((prevState) => ({
      ...prevState,
      certificate: bool,
    }));
  };

  const handleWorkMoulik = (val) => {
    setValues((prevState) => ({
      ...prevState,
      basicWorktype: val,
    }));
  };

  const RelatedRightList = relatedRightData?.data?.map((data) => {
    return {
      value: data.id,
      label: data.name,
    };
  });

  // Selected defoult items
  const CopyrightListRR = copyrightData?.data?.map((data) => {
    return {
      value: data.id,
      label: data.name,
    };
  });

  const finOptionData =
    values?.selfType === 1 ? CopyrightListRR : RelatedRightList;

  const handleSelectChange = (selectedOption) => {
    setValues((prevValues) => ({
      ...prevValues,
      rightTypes: selectedOption.value,
    }));
  };

  const textSection3 = applicationFormTitle(values.rightTypes);

  useEffect(() => {
    if (isSuccessCategory) {
      fetchSubCategory({ parent_id: values.category_id });
    }
  }, [isSuccessCategory]);

  const WorkTypeList = categoryData?.data?.map((data) => {
    return {
      value: data.id,
      label: data.name,
    };
  });

  const WorkTypeSubList = subCategoryData?.data?.map((data) => {
    return {
      value: data.id,
      label: data.name,
    };
  });

  const basicWorktypeId = (val) => {
    return values.basicworktypes[val]?.id || "";
  };

  const toggleCheckIsAgree = (e) => {
    const val = e.target.checked;
    setValues((prev) => ({
      ...prev,
      form_status: val ? 2 : 1,
    }));
    setCheckIsAgree((prevState) => !prevState);
  };

  return (
    <div>
      <section className="section py-5  service-single">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <div className="card border-0  w-100">
              <div
                className="tab-content rounded border py-4 px-4 paddddingnone border-1"
                id="nav-tabContent"
              >
                <div className="text-end me-4">
                  <div className="">
                    {" "}
                    <img
                      src={`${publicUrl}/assets/images/p2.png`}
                      alt="Logo"
                      style={{
                        height: "60px",
                        width: "60px",
                        marginTop: "-47px",
                      }}
                      className="img-fluid pataresponsive"
                    />{" "}
                  </div>
                </div>

                <div
                  className="tab-pane fade active show"
                  id="nav-application"
                  role="tabpanel"
                  aria-labelledby="nav-application-tab"
                >
                  <div className="applicationForm">
                    <div
                      style={{ marginTop: "-20px" }}
                      className={`${styles.add2ApplicationHeader} applicationForm-header text-center`}
                    >
                      <h4 className="mb-0 form2fontsizeee">
                        <b>বিষয়াবলীর বিবরণী </b>
                      </h4>
                      <p className="form2fontsizeee2 mb-4">
                        <b>কপিরাইট রেজিস্ট্রেশনের আবেদন ফরম</b>
                      </p>
                    </div>

                    <div className="mb-3 mt-4 form2fontsizeee3 pt-2">
                      <div className={styles.add2sectiontitle}>
                        <div className="add2-section-title">
                          <div className="d-flex widtghh ">
                            <h5 className="mt-2">
                              <b>১. নিবন্ধন সংখ্যাঃ </b>
                            </h5>

                            <h5 className="px-3 widtghhh ms-3  py-2 text-center rounded border text-success">
                              <b> কপিরাইট অফিস কর্তৃক পূরণীয় </b>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={`${styles.add2Section2}`}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-3 mt-2 mtop0 col-sm-12 col-12">
                                <label>২. প্রনেতা/স্বত্বাধিকারীর ধরনঃ</label>
                              </div>
                              <div className="col-md-9 my-1 col-sm-12 col-12">
                                <span className="d-flex ptop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sideRadio"
                                      id="sideRadio1"
                                      checked={values.type === 1}
                                      onClick={() => toggleSection2(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="sideRadio1"
                                    >
                                      ব্যক্তি
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sideRadio"
                                      id="sideRadio2"
                                      checked={values.type === 2}
                                      onClick={() => toggleSection2(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="sideRadio2"
                                    >
                                      যৌথ
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="sideRadio"
                                      id="sideRadio3"
                                      checked={values.type === 3}
                                      onClick={() => toggleSection2(3)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="sideRadio3"
                                    >
                                      প্রতিষ্ঠান
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>

                          {values.type === 1 && (
                            <div
                              className={`${styles.add2PanelBackground} mb-2`}
                            >
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="row align-items-end">
                                    {/* Profile Picture */}
                                    <div className="col-md-6 col-sm-12 mtop0 my-1">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          প্রনেতা/স্বত্বাধিকারীর ছবি <br /> (
                                          আবশ্যিক )
                                        </label>
                                        <br />
                                        <div
                                          className={`${styles.imagePreviewAdd2}`}
                                        >
                                          <span>
                                            <img src={assets.photo} alt="" />
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    {/* NID/Passport */}
                                    <div className="col-md-6 col-sm-12 my-1">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          জাতীয় পরিচয় পত্র/জন্ম
                                          নিবন্ধন/পাসপোর্টের স্ক্যান কপি (
                                          আবশ্যিক )
                                        </label>
                                        <br />
                                        <div
                                          className={styles.imagePreviewAdd2}
                                        >
                                          {" "}
                                          <img src={assets.idcard} alt="" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-8">
                                  <div className="row">
                                    <div className="col-md-4 my-1">
                                      <div className="form-group">
                                        <label>আবেদনকারীর নাম</label>
                                        <ClientFormikFormFieldMultiple
                                          value={"মোঃ শাহনেওয়াজ চৌধুরী"}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-8 my-1">
                                      <div className="form-group">
                                        <label>ঠিকানা</label>
                                        <ClientFormikFormFieldMultiple
                                          value={
                                            "গ্রামঃ রামনগরের চর, পোঃ আদদিয়া, থানাঃ নড়াইল, জেলাঃ নড়াইল"
                                          }
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-4 my-1">
                                      <div className="form-group">
                                        <label>জাতীয়তা</label>
                                        <ClientFormikFormFieldMultiple
                                          value={"বাংলাদেশী"}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-4 my-1">
                                      <div className="form-group">
                                        <label>মোবাইল নাম্বার</label>
                                        <ClientFormikFormFieldMultiple
                                          placeholder="মোবাইল নাম্বার"
                                          value={"+৮৮০১৫৩৪-২৪৯৮৩৩"}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-4 my-1">
                                      <div className="form-group">
                                        <label>ই-মেইল</label>
                                        <ClientFormikFormFieldMultiple
                                          value={"np_123@gmail.com"}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {values.type === 2 && (
                            <div>
                              {values?.profiledetailtypes.map((item, index) => (
                                <div
                                  key={index}
                                  className={`${styles.add2PanelBackground} mb-2`}
                                >
                                  <div className="row">
                                    <div className="col-md-4 col-sm-12">
                                      <div className="row align-items-end">
                                        <div className="col-md-6 col-sm-12 mtop0 my-1">
                                          <div
                                            className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                          >
                                            <label>
                                              প্রনেতা/স্বত্বাধিকারীর ছবি <br />{" "}
                                              ( আবশ্যিক )
                                            </label>
                                            <br />
                                            <div
                                              className={`${styles.imagePreviewAdd2}`}
                                            >
                                              <span>
                                                <img
                                                  src={assets.photo}
                                                  alt=""
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-6 col-sm-12 my-1">
                                          <div
                                            className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                          >
                                            <label>
                                              জাতীয় পরিচয় পত্র/জন্ম
                                              নিবন্ধন/পাসপোর্টের স্ক্যান কপি (
                                              আবশ্যিক )
                                            </label>
                                            <br />
                                            <div
                                              className={
                                                styles.imagePreviewAdd2
                                              }
                                            >
                                              {" "}
                                              <img src={assets.idcard} alt="" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-8">
                                      <div className="row">
                                        <div className="col-md-4 my-1">
                                          <div className="form-group">
                                            <label>আবেদনকারীর নাম</label>
                                            <ClientFormikFormFieldMultiple
                                              value={"মোঃ শাহনেওয়াজ চৌধুরী"}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-4 my-1">
                                          <div className="form-group">
                                            <label>ঠিকানা</label>
                                            <ClientFormikFormFieldMultiple
                                              value={
                                                "গ্রামঃ রামনগরের চর, পোঃ আদদিয়া, থানাঃ নড়াইল, জেলাঃ নড়াইল"
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-4 my-1">
                                          <div className="form-group">
                                            <label>জাতীয়তা</label>
                                            <ClientFormikFormFieldMultiple
                                              value={"বাংলাদেশী"}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-4 my-1">
                                          <div className="form-group">
                                            <label>মোবাইল নাম্বার</label>
                                            <ClientFormikFormFieldMultiple
                                              value={"+৮৮০১৫৩৪-২৪৯৮৩৩"}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-4 my-1">
                                          <div className="form-group">
                                            <label>ই-মেইল</label>
                                            <ClientFormikFormFieldMultiple
                                              value={"np_123@gmail.com"}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-4 centeraddremovebtn my-1">
                                          <div className="form-group">
                                            <div className="mtop2 mt-4">
                                              {(values.profiledetailtypes
                                                .length ===
                                                index + 1 ||
                                                values.profiledetailtypes
                                                  .length === 1) && (
                                                <ImPlus
                                                  className="customAddButton  rounded"
                                                  onClick={handleAddFieldSet}
                                                />
                                              )}
                                              {values.profiledetailtypes
                                                .length > 1 &&
                                                index !== 0 && (
                                                  <IoCloseSharp
                                                    className="customRemoveButton rounded"
                                                    onClick={() =>
                                                      handleRemoveFieldSet(
                                                        index,
                                                        item?.id
                                                      )
                                                    }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}

                          {values.type === 3 && (
                            <div
                              className={`${styles.add2PanelBackground} mb-2`}
                            >
                              <div className="row">
                                <div className="col-md-3 mtop0 my-1">
                                  <div className="form-group">
                                    <label>প্রতিষ্ঠানের নাম</label>
                                    <ClientFormikFormFieldMultiple
                                      value={"ইপ্সিতা কম্পিউটার্স প্রাইভেট লিঃ"}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>প্রতিষ্ঠানের ঠিকানা</label>
                                    <ClientFormikFormFieldMultiple
                                      value={"ঢাকা, বাংলাদেশ"}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>
                                      প্রতিষ্ঠানের পক্ষে আবেদনকারীর নাম
                                    </label>
                                    <ClientFormikFormFieldMultiple
                                      value={"মোঃ শাহনেওয়াজ চৌধুরী"}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>আবেদনকারীর ঠিকানা</label>
                                    <ClientFormikFormFieldMultiple
                                      value={
                                        "গ্রামঃ রামনগরের চর, পোঃ আদদিয়া, থানাঃ নড়াইল, জেলাঃ নড়াইল"
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>আবেদনকারীর পদবী</label>
                                    <ClientFormikFormFieldMultiple
                                      value={"ব্যবস্থাপনা পরিচালক"}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>মোবাইল নাম্বার</label>
                                    <ClientFormikFormFieldMultiple
                                      value={"+৮৮০১৫৩৪-২৪৯৮৩৩"}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>ই-মেইল</label>
                                    <ClientFormikFormFieldMultiple
                                      value={"np_123@gmail.com"}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-3 my-1">
                                  <div className="form-group">
                                    <label>
                                      রেজিস্ট্রেশন নাম্বার ( সামাজিক সংগঠনের
                                      ক্ষেত্রে)
                                    </label>
                                    <ClientFormikFormFieldMultiple
                                      value={"০১২৩৪৫৬"}
                                    />
                                  </div>
                                </div>

                                <div className="col-md-12 mt-3">
                                  <div className="row align-items-end">
                                    <div className="col-md-2">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          প্রনেতা/স্বত্বাধিকারীর ছবি <br /> (
                                          আবশ্যিক )
                                        </label>
                                        <br />
                                        <div
                                          className={`${styles.imagePreviewAdd2}`}
                                        >
                                          <img src={assets.photo} alt="" />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-2">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          জাতীয় পরিচয় পত্র/জন্ম
                                          নিবন্ধন/পাসপোর্টের স্ক্যান কপি (
                                          আবশ্যিক )
                                        </label>
                                        <br />
                                        <div
                                          className={styles.imagePreviewAdd2}
                                        >
                                          <img src={assets.idcard} alt="" />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-2">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          প্রতিষ্ঠানের ট্রেড লাইসেন্সের স্ক্যান
                                          কপি ( ব্যবসায়িক প্রতিষ্ঠানের ক্ষেত্রে
                                          প্রযোজ্য )
                                        </label>
                                        <br />

                                        <div
                                          className={styles.imagePreviewAdd2}
                                        >
                                          <img src={assets.license} alt="" />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-2">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          প্রতিষ্ঠানের টিন সার্টিফিকেটের স্ক্যান
                                          কপি ( প্রযোজ্য ক্ষেত্রে )
                                        </label>
                                        <br />

                                        <div
                                          className={styles.imagePreviewAdd2}
                                        >
                                          <>
                                            <img src={assets.TIN} alt="" />
                                          </>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-2">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                      >
                                        <label>
                                          প্রতিষ্ঠানের মেমোর‍্যান্ডামের স্ক্যান
                                          কপি (প্রযোজ্য ক্ষেত্রে)
                                        </label>
                                        <br />
                                        <div
                                          className={styles.imagePreviewAdd2}
                                        >
                                          <img src={assets.arun} alt="" />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-2">
                                      <div
                                        className={`${styles.add2FormGroupImagePreviewBox} form-group mbottom0 imgmffargin text-center`}
                                      >
                                        <label>
                                          প্রতিষ্ঠানের গঠনতন্ত্রের স্ক্যান কপি (
                                          সামাজিক সংগঠনের ক্ষেত্রে প্রযোজ্য )
                                        </label>
                                        <br />
                                        <div
                                          className={styles.imagePreviewAdd2}
                                        >
                                          <img
                                            src={assets.incorporation}
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-3 my-4">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-3 mt-2 mtop0 col-12">
                                <label>৩. কপিরাইটে স্বার্থের ধরনঃ</label>
                              </div>
                              <div className="col-md-6 my-1 col-12">
                                <span className="d-flex ptop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section3Radio"
                                      id="section3Radio1"
                                      checked={values?.selfType === 1}
                                      onClick={() => toggleSection3(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section3Radio1"
                                    >
                                      কপিরাইট
                                    </label>
                                  </div>

                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section3Radio"
                                      id="section3Radio2"
                                      checked={values?.selfType === 2}
                                      onClick={() => toggleSection3(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section3Radio2"
                                    >
                                      রিলেটেড রাইট
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className={`${styles.add2PanelBackground} mb-2`}>
                            <div className="row">
                              <div className="col-md-3 col-12 mtop0 my-1">
                                <div className="form-group">
                                  <label>
                                    {values?.selfType === 1
                                      ? "কপিরাইটের ধরন"
                                      : "রিলেটেড রাইট"}
                                  </label>
                                  <div>
                                    <Select
                                      options={finOptionData}
                                      value={finOptionData?.find(
                                        (item) =>
                                          item.value === values.rightTypes
                                      )}
                                      onChange={handleSelectChange}
                                      classNamePrefix="react-select"
                                      placeholder="-- শ্রেণি --"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-9 my-1">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="row">
                                      {[10, 16].includes(values.rightTypes) && (
                                        <div className="col-md-12 mtop3">
                                          <div
                                            className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin2 text-center`}
                                          >
                                            <label className="text-center">
                                              উত্তরাধিকার সনদপত্রের স্ক্যান কপি
                                              ( আবশ্যিক )
                                            </label>
                                            <br />
                                            <div
                                              className={
                                                styles.imagePreviewAdd2
                                              }
                                            >
                                              <img
                                                src={assets.sommoti}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      {[6, 13, 19].includes(
                                        values.rightTypes
                                      ) && (
                                        <div className="col-md-12 col-sm-12">
                                          <div className="form-group">
                                            <label>
                                              {values.rightTypes === 13
                                                ? "প্রণেতা / রচয়িতার ধরন"
                                                : values.rightTypes === 19
                                                ? "অন্যান্য সূত্রে ধরন"
                                                : ""}
                                            </label>
                                            <div className="input">
                                              <ClientFormikFormFieldMultiple
                                                value={"ব্যবসায়িক পার্টনার"}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-8 mtop3">
                                    {values?.selftypes.map((item, index) => (
                                      <div className="row" key={index}>
                                        <div className="col-md-9">
                                          <div
                                            className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin2 imgmffargin3 text-center text-start`}
                                          >
                                            <label className="text-center">
                                              {textSection3}
                                            </label>
                                            <br />
                                            <div
                                              className={
                                                styles.imagePreviewAdd2
                                              }
                                            >
                                              <img src={assets.noc} alt="" />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-3  centeraddremovebtn addremovemargin">
                                          <div className="form-group">
                                            <div className="">
                                              {(values.selftypes.length ===
                                                index + 1 ||
                                                values.selftypes.length ===
                                                  1) && (
                                                <ImPlus
                                                  className="customAddButton  rounded"
                                                  onClick={
                                                    handleAddFieldSetProneta
                                                  }
                                                />
                                              )}

                                              {values.selftypes.length > 1 &&
                                                index !== 0 && (
                                                  <IoCloseSharp
                                                    className="customRemoveButton rounded"
                                                    onClick={() =>
                                                      handleRemoveFieldSetProneta(
                                                        index,
                                                        item?.id
                                                      )
                                                    }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-4">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <h5>
                              <b>৪. কর্মটির প্রকৃতি এবং বিবরণঃ</b>
                            </h5>
                          </div>
                          <div className={`${styles.add2PanelBackground}`}>
                            <div className="form-group mb-3">
                              <div className="row">
                                <div className="col-md-2">
                                  <label>ক. কর্মটির প্রকৃতি</label>
                                </div>

                                <div className="col-md-5 col-12 mb-2">
                                  <Select
                                    onBlur={handleBlur}
                                    options={WorkTypeList}
                                    value={
                                      WorkTypeList?.find(
                                        (item) =>
                                          item.value === values.category_id
                                      ) || {
                                        value: "",
                                        label: " --- বিভাগ ---",
                                        isDisabled: true,
                                      }
                                    }
                                    onChange={(selectedOption) => {
                                      setValues((prevValues) => ({
                                        ...prevValues,
                                        category_id: selectedOption.value,
                                        subCategory: "",
                                      }));
                                      fetchSubCategory({
                                        parent_id: selectedOption.value,
                                      });
                                    }}
                                    classNamePrefix="react-select"
                                  />
                                  <div>
                                    {errors.category_id &&
                                      touched.category_id && (
                                        <small className="my-2 text-danger">
                                          {errors.category_id}
                                        </small>
                                      )}
                                  </div>
                                </div>
                                <div className="col-md-5 col-12">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <Select
                                        onChange={(selectedOption) => {
                                          setValues((prevValues) => ({
                                            ...prevValues,
                                            subCategory: selectedOption.value,
                                          }));
                                        }}
                                        onBlur={handleBlur}
                                        options={WorkTypeSubList}
                                        value={
                                          WorkTypeSubList?.find(
                                            (item) =>
                                              item.value === values.subCategory
                                          ) || {
                                            value: "",
                                            label: "--- উপবিভাগ ---",
                                            isDisabled: true,
                                          }
                                        }
                                        classNamePrefix="react-select"
                                        isLoading={
                                          isLoadingSubCat || isFetching
                                        }
                                        isDisabled={
                                          isLoadingSubCat || isFetching
                                        }
                                      />
                                      <div>
                                        {errors.subCategory &&
                                          touched.subCategory && (
                                            <small className="my-2 text-danger">
                                              {errors.subCategory}
                                            </small>
                                          )}
                                      </div>
                                    </div>
                                    {otherSubCategory?.data.includes(
                                      values?.subCategory
                                    ) && (
                                      <div className="col-md-12 mt-3">
                                        <ClientFormikFormFieldMultiple
                                          placeholder={"এখানে লিখুন ......"}
                                          value={values?.otherSubCategory}
                                          name={`otherSubCategory`}
                                          errors={
                                            errors?.otherSubCategory &&
                                            errors?.otherSubCategory
                                          }
                                          touched={
                                            touched?.otherSubCategory &&
                                            touched?.otherSubCategory
                                          }
                                          handleChange={handleChange}
                                          handleBlur={handleBlur}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group mb-2">
                              <div className="row">
                                <div className="col-md-2">
                                  <label>খ. কর্মটির বিবরণ</label>
                                </div>
                                <div className="col-md-10 mb-2">
                                  <ClientFormikFormFieldMultiple
                                    value={
                                      "বাংলা আমার দেশ, সবুজ শ্যামলের দেশ, আমার প্রানের বাংলাদেশ ।"
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group mt-3 mtop0">
                              <div className="row">
                                <div className="col-md-3 col-lg-2 col-sm-12">
                                  <label>গ. কর্মের সফ্‌ট কপি</label>
                                </div>

                                <div className="col-md-10 mb-2">
                                  <div className="row">
                                    <div className="col-md-6">
                                      {values?.workSoftCopies?.map(
                                        (item, index) => (
                                          <div key={index} className="row">
                                            <div className="col-md-7 col-sm-12 mbottom0 mb-2">
                                              <div className="border bordercolorr rounded ptop0 paddingg0 p-3 pt-2">
                                                <div
                                                  className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                                >
                                                  <label>
                                                    <strong className="text-primary">
                                                      নমুনাঃ{" "}
                                                    </strong>
                                                  </label>
                                                  <br />
                                                  <div
                                                    className={
                                                      styles.imagePreviewAdd2
                                                    }
                                                  >
                                                    drama.mp4, drama.pdf,
                                                    drama.doc
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-5 centeraddremovebtn">
                                              <div className="form-group">
                                                <div className="mt-4 mtop0 mb-2 pb-1 mtop2 ptop0 pt-3">
                                                  {(values.workSoftCopies
                                                    .length ===
                                                    index + 1 ||
                                                    values.workSoftCopies
                                                      .length === 1) && (
                                                    <ImPlus
                                                      className="customAddButton rounded"
                                                      title="আরও যোগ করুন"
                                                      onClick={
                                                        handleAddFieldSetWorkSoftCopy
                                                      }
                                                    />
                                                  )}
                                                  {values.workSoftCopies
                                                    .length > 1 &&
                                                    index !== 0 && (
                                                      <IoCloseSharp
                                                        className="customRemoveButton rounded"
                                                        title="বন্ধ করুন"
                                                        onClick={() =>
                                                          handleRemoveFieldSetWorkSoftCopy(
                                                            index,
                                                            item
                                                          )
                                                        }
                                                      />
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>

                                    {(values.category_id === 2 ||
                                      values.category_id === 7) && (
                                      <div className="col-md-6">
                                        <div className="border bordercolorr rounded ptop0 paddingg0 p-3 pt-2">
                                          <div
                                            className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center text-start`}
                                          >
                                            <label className="text-start">
                                              {values.category_id === 2
                                                ? "গানের তালিকা ( গানের ক্ষেত্রে প্রযোজ্য )"
                                                : "চলচ্চিত্র সেন্সর সনদ ( চলচ্চিত্রের ক্ষেত্রে প্রযোজ্য )"}
                                            </label>
                                            <br />

                                            <div
                                              className={
                                                styles.imagePreviewAdd2
                                              }
                                            >
                                              <img
                                                src={assets.sommoti}
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group mt-2">
                              <div className="row">
                                <div className="col-md-3 mtop0 mt-2">
                                  <label>ঘ. ইহা কি একটি মৌলিক কর্ম ?</label>
                                </div>
                                <div className="col-md-9 my-1 col-12">
                                  <span className="d-flex mtop0 pt-1">
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sideRadioMoulik"
                                        id="sideRadioMoulik1"
                                        checked={values.basicWorktype === 1}
                                        onClick={() => handleWorkMoulik(1)}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="sideRadioMoulik1"
                                      >
                                        হ্যাঁ
                                      </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="sideRadioMoulik"
                                        id="sideRadioMoulik2"
                                        checked={values.basicWorktype === 2}
                                        onClick={() => handleWorkMoulik(2)}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor="sideRadioMoulik2"
                                      >
                                        না
                                      </label>
                                    </div>
                                  </span>
                                </div>
                                <div className="col-md-12">
                                  {(values.basicWorktype === 2 ||
                                    [7, 17, 18].includes(
                                      values.rightTypes
                                    )) && (
                                    <div>
                                      {![18].includes(values.rightTypes) && (
                                        <>
                                          {/* Part One */}
                                          {values.rightTypes !== 6 &&
                                            values.rightTypes !== 12 && (
                                              <div className="work-moulik-box mb-2">
                                                <div className="form-check form-check-inline">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="add2-checkbox1"
                                                    id="add2-checkbox1"
                                                    checked={checkBoxKeyType.includes(
                                                      0
                                                    )}
                                                    onChange={() =>
                                                      handleCheckboxClick(
                                                        0,
                                                        basicWorktypeId(0)
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="add2-checkbox1"
                                                  >
                                                    সকলের জন্য প্রকাশ্য কোন
                                                    কর্মের অনুবাদ ?
                                                  </label>
                                                </div>
                                                {(checkBoxKeyType.includes(0) ||
                                                  [7, 17].includes(
                                                    values.rightTypes
                                                  )) && (
                                                  <WorkMoulikBox
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    baseIndex={0}
                                                    setValues={setValues}
                                                  />
                                                )}
                                              </div>
                                            )}

                                          {/* Part Two */}
                                          {values.rightTypes !== 6 &&
                                            values.rightTypes !== 12 && (
                                              <div className="work-moulik-box mb-2">
                                                <div className="form-check form-check-inline">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="add2-checkbox2"
                                                    id="add2-checkbox2"
                                                    checked={checkBoxKeyType.includes(
                                                      1
                                                    )}
                                                    onChange={() =>
                                                      handleCheckboxClick(
                                                        1,
                                                        basicWorktypeId(1)
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="add2-checkbox2"
                                                  >
                                                    কপিরাইটকৃত কোন কর্মের
                                                    অনুবাদ?
                                                  </label>
                                                </div>
                                                {(checkBoxKeyType.includes(1) ||
                                                  [7, 17].includes(
                                                    values.rightTypes
                                                  )) && (
                                                  <WorkMoulikBox
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    baseIndex={1}
                                                    setValues={setValues}
                                                  />
                                                )}
                                              </div>
                                            )}
                                        </>
                                      )}
                                      {![7, 17].includes(values.rightTypes) && (
                                        <>
                                          {/* Part Three */}
                                          {values.rightTypes !== 5 &&
                                            values.rightTypes !== 11 && (
                                              <div className="work-moulik-box mb-2">
                                                <div className="form-check form-check-inline">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="add2-checkbox3"
                                                    id="add2-checkbox3"
                                                    checked={checkBoxKeyType.includes(
                                                      2
                                                    )}
                                                    onChange={() =>
                                                      handleCheckboxClick(
                                                        2,
                                                        basicWorktypeId(2)
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="add2-checkbox3"
                                                  >
                                                    সকলের জন্য প্রকাশ্য কোন
                                                    কর্মের অভিযোজন ?
                                                  </label>
                                                </div>
                                                {(checkBoxKeyType.includes(2) ||
                                                  [18].includes(
                                                    values.rightTypes
                                                  )) && (
                                                  <WorkMoulikBox
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    baseIndex={2}
                                                    setValues={setValues}
                                                  />
                                                )}
                                              </div>
                                            )}

                                          {/* Part Four */}
                                          {values.rightTypes !== 5 &&
                                            values.rightTypes !== 11 && (
                                              <div className="work-moulik-box mb-2">
                                                <div className="form-check form-check-inline">
                                                  <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="add2-checkbox4"
                                                    id="add2-checkbox4"
                                                    checked={checkBoxKeyType.includes(
                                                      3
                                                    )}
                                                    onChange={() =>
                                                      handleCheckboxClick(
                                                        3,
                                                        basicWorktypeId(3)
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor="add2-checkbox4"
                                                  >
                                                    কপিরাইটকৃত কোন কর্মের
                                                    অভিযোজন ?
                                                  </label>
                                                </div>
                                                {(checkBoxKeyType.includes(3) ||
                                                  [18].includes(
                                                    values.rightTypes
                                                  )) && (
                                                  <WorkMoulikBox
                                                    handleChange={handleChange}
                                                    handleBlur={handleBlur}
                                                    values={values}
                                                    errors={errors}
                                                    touched={touched}
                                                    baseIndex={3}
                                                    setValues={setValues}
                                                  />
                                                )}
                                              </div>
                                            )}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-5 my-3">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="add2-section-title">
                            <h5>
                              <b>৫. কর্মটির শিরোনামঃ</b>
                            </h5>
                          </div>
                          <div className={`${styles.add2PanelBackgrounddd}`}>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <ClientFormikFormFieldMultiple
                                    value={"বাংলার স্বাধীনতা"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6 mtop3">
                          <div className="add2-section-title">
                            <h5>
                              <b>৬. কর্মটির ভাষাঃ</b>
                            </h5>
                          </div>
                          <div className={`${styles.add2PanelBackgrounddd}`}>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-md-12">
                                  <ClientFormikFormFieldMultiple
                                    value={"বাংলা"}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-6 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <h5>
                              <b>
                                ৭. লেখক / রচয়িতা / প্রণেতার নাম, ঠিকানা ও
                                জাতীয়তা এবং লেখক / রচয়িতা / প্রণেতা মৃত হইলে
                                মৃত্যুর তারিখ -
                              </b>
                            </h5>
                          </div>
                          {values?.writerdetails?.map((item, index) => (
                            <div key={index} className="row mb-3">
                              <div className="col-md-12">
                                <div
                                  className={`${styles.add2PanelBackgrounddd}`}
                                >
                                  <div className="row">
                                    <div className="col-md-10">
                                      <div className="row">
                                        <div className="col-md-3 my-1">
                                          <div className="form-group">
                                            <ClientFormikFormFieldMultiple
                                              value={"মোঃ শাহনেওয়াজ চৌধুরী"}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3 my-1">
                                          <div className="form-group">
                                            <ClientFormikFormFieldMultiple
                                              placeholder="লেখকের ঠিকানা লিখুন"
                                              value={
                                                "গ্রামঃ রামনগরের চর, পোঃ আদদিয়া, থানাঃ নড়াইল, জেলাঃ নড়াইল"
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3 my-1">
                                          <div className="form-group">
                                            <ClientFormikFormFieldMultiple
                                              placeholder="লেখকের জাতীয়তা লিখুন"
                                              value={"বাংলাদেশী"}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-3 my-1">
                                          <ClientFormikFormFieldMultiple
                                            placeholder="লেখকের জাতীয়তা লিখুন"
                                            value={"১০/১২/১৯৯৯"}
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-md-2 my-1 centeraddremovebtn">
                                      <div className="form-group">
                                        <div className="">
                                          {(values.writerdetails.length ===
                                            index + 1 ||
                                            values.writerdetails.length ===
                                              1) && (
                                            <ImPlus
                                              className="customAddButton rounded"
                                              onClick={
                                                handleAddFieldSetBoxPartSeven
                                              }
                                            />
                                          )}
                                          {values.writerdetails.length > 1 &&
                                            index !== 0 && (
                                              <IoCloseSharp
                                                className="customRemoveButton rounded"
                                                onClick={() =>
                                                  handleRemoveFieldSetBoxPartSeven(
                                                    index,
                                                    item?.id
                                                  )
                                                }
                                              />
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-7">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 mtop0 mt-2 col-12">
                                <label>
                                  {" "}
                                  ৮. কর্মটি ইতোমধ্যে প্রকাশিত হইয়াছে কি না ?
                                </label>
                              </div>
                              <div className="col-md-6 my-1 col-12">
                                <span className="d-flex mtop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section7Radio"
                                      id="section7Radio1"
                                      checked={values.workPublishtype === 1}
                                      onClick={() => toggleSection7(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section7Radio1"
                                    >
                                      হ্যাঁ
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section7Radio"
                                      id="section7Radio2"
                                      checked={values.workPublishtype === 2}
                                      onClick={() => toggleSection7(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section7Radio2"
                                    >
                                      না
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-8 mt-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-12 col-12">
                                <h5>
                                  <b>
                                    ৯. প্রথম প্রকাশনার বছর, স্থান এবং প্রকাশকের
                                    নাম, ঠিকানা ও জাতীয়তা
                                  </b>
                                </h5>
                              </div>
                              {values.workPublishtype === 1 && (
                                <div className="col-md-12">
                                  <div
                                    className={`${styles.add2PanelBackground}`}
                                  >
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label>প্রথম প্রকাশনার বছর</label>
                                          <ClientFormikFormFieldMultiple
                                            value={"২০১৭"}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>প্রথম প্রকাশনার স্থান</label>
                                          <ClientFormikFormFieldMultiple
                                            value={"ঢাকা, বাংলাদেশ"}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>প্রকাশকের নাম</label>
                                          <ClientFormikFormFieldMultiple
                                            value={"মাহিন সরকার"}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>প্রকাশকের ঠিকানা</label>
                                          <ClientFormikFormFieldMultiple
                                            value={"মিরপুর-১০, ঢাকা"}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>প্রকাশকের জাতীয়তা</label>
                                          <ClientFormikFormFieldMultiple
                                            value={"বাংলাদেশী"}
                                          />
                                        </div>
                                      </div>

                                      {values.category_id === 1 && (
                                        <div className="col-md-4">
                                          <div className="form-group border bordercolorr paddingg0 rounded p-1">
                                            <div
                                              className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                            >
                                              <label>
                                                প্রচ্ছদ কর্মের হস্তান্তর দলিলের
                                                স্ক্যান কপি
                                              </label>
                                              <br />

                                              <div
                                                className={
                                                  styles.imagePreviewAdd2
                                                }
                                              >
                                                <img
                                                  src={assets.procchod}
                                                  alt=""
                                                />
                                              </div>

                                              <br />
                                              <small>
                                                ( প্রণেতা ব্যতীত অন্য কেউ
                                                প্রচ্ছদকারী হলে সেক্ষেত্রে
                                                প্রযোজ্য )
                                              </small>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-9 mt-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 mtop0 mt-2 col-12">
                                <label>
                                  ১০ . পরবর্তীকালে / সর্বশেষ কর্মটি আরো প্রকাশিত
                                  হইয়াছে কি ?
                                </label>
                              </div>

                              <div className="col-md-6 my-1 col-12">
                                <span className="d-flex mtop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section9Radio"
                                      id="section9Radio1"
                                      checked={values.otherPublishtype === 1}
                                      onClick={() => toggleSection9(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section9Radio1"
                                    >
                                      হ্যাঁ
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section9Radio"
                                      id="section9Radio2"
                                      checked={values.otherPublishtype === 2}
                                      onClick={() => toggleSection9(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section7Radio2"
                                    >
                                      না
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>

                            <div className="row">
                              {values.otherPublishtype === 1 &&
                                values.publishdetails.map((item, index) => (
                                  <div key={index} className="col-md-12 mb-3">
                                    <div
                                      className={`${styles.add2PanelBackground}`}
                                    >
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="form-group mb-2">
                                            <label>
                                              পরবর্তীকালে / সর্বশেষ প্রকাশনার
                                              বছর
                                            </label>
                                            <ClientFormikFormFieldMultiple
                                              value={"২০১৪"}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group mb-2">
                                            <label>
                                              পরবর্তীকালে / সর্বশেষ প্রকাশনার
                                              স্থান
                                            </label>
                                            <ClientFormikFormFieldMultiple
                                              value={"নীলফামারী"}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group mb-2">
                                            <label>
                                              পরবর্তীকালে / সর্বশেষ প্রকাশকের
                                              নাম
                                            </label>
                                            <ClientFormikFormFieldMultiple
                                              value={"মাহিন সরকার"}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group mb-2">
                                            <label>
                                              পরবর্তীকালে / সর্বশেষ প্রকাশকের
                                              ঠিকানা
                                            </label>
                                            <ClientFormikFormFieldMultiple
                                              value={"মিরপুর-১০, ঢাকা"}
                                            />
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="form-group mb-2">
                                            <label>
                                              পরবর্তীকালে / সর্বশেষ প্রকাশকের
                                              জাতীয়তা
                                            </label>
                                            <ClientFormikFormFieldMultiple
                                              value={"বাংলাদেশী"}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-2 centeraddremovebtn">
                                          <div className="form-group">
                                            <div className="mt-4 mtop3">
                                              {(values.publishdetails.length ===
                                                index + 1 ||
                                                values.publishdetails.length ===
                                                  1) && (
                                                <ImPlus
                                                  className="customAddButton  rounded"
                                                  onClick={
                                                    handleAddFieldSetBoxPartNine
                                                  }
                                                />
                                              )}
                                              {values.publishdetails.length >
                                                1 &&
                                                index !== 0 && (
                                                  <IoCloseSharp
                                                    className="customRemoveButton rounded"
                                                    onClick={() =>
                                                      handleRemoveFieldSetBoxPartNine(
                                                        index,
                                                        item?.id
                                                      )
                                                    }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-10 mt-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 mtop0 mt-2 col-12">
                                <label>
                                  {" "}
                                  ১১. কর্মটির কপিরাইটভুক্ত অন্য কোন
                                  স্বত্বাধিকারী আছে কি ?
                                </label>
                              </div>

                              <div className="col-md-6 py-1 col-12">
                                <span className="d-flex mtop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section10Radio"
                                      id="section10Radio1"
                                      checked={values.ownerType === 1}
                                      onClick={() => toggleSection10(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section10Radio1"
                                    >
                                      হ্যাঁ
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section10Radio"
                                      id="section10Radio2"
                                      checked={values.ownerType === 2}
                                      onClick={() => toggleSection10(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section10Radio2"
                                    >
                                      না
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>

                            <div className="row">
                              {values.ownerType === 1 &&
                                values.ownertypes.map((item, index) => (
                                  <div key={index} className="col-md-12 mb-3">
                                    <div
                                      className={`${styles.add2PanelBackground}`}
                                    >
                                      <div className="row">
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>
                                                  স্বত্বাধিকারীর নাম
                                                </label>
                                                <ClientFormikFormFieldMultiple
                                                  value={"মাহিন সরকার"}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>
                                                  স্বত্বাধিকারীর ঠিকানা
                                                </label>
                                                <ClientFormikFormFieldMultiple
                                                  value={"মিরপুর-১০, ঢাকা"}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>
                                                  স্বত্বাধিকারীর জাতীয়তা
                                                </label>
                                                <ClientFormikFormFieldMultiple
                                                  value={"বাংলাদেশী"}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>স্বত্বের অংশ</label>
                                                <ClientFormikFormFieldMultiple
                                                  value={"১/২"}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-2 centeraddremovebtn">
                                          <div className="form-group">
                                            <div className="mt-4 mtop3">
                                              {(values.ownertypes.length ===
                                                index + 1 ||
                                                values.ownertypes.length ===
                                                  1) && (
                                                <ImPlus
                                                  className="customAddButton  rounded"
                                                  onClick={
                                                    handleAddFieldSetBoxPartTen
                                                  }
                                                />
                                              )}
                                              {values.ownertypes.length > 1 &&
                                                index !== 0 && (
                                                  <IoCloseSharp
                                                    className="customRemoveButton rounded"
                                                    onClick={() =>
                                                      handleRemoveFieldSetBoxPartTen(
                                                        index,
                                                        item?.id
                                                      )
                                                    }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-11 mt-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 mtop0 mt-2 col-12">
                                <label>
                                  {" "}
                                  ১২. কপিরাইটভুক্ত বিভিন্ন স্বত্ব নিয়োগ বা
                                  লাইসেন্স প্রদানের ক্ষমতাপ্রাপ্ত ব্যক্তি আছে
                                  কি?
                                </label>
                              </div>

                              <div className="col-md-6 py-1 col-12">
                                <span className="d-flex mtop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section11Radio"
                                      id="section11Radio1"
                                      checked={values.powerType === 1}
                                      onClick={() => toggleSection11(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section11Radio1"
                                    >
                                      হ্যাঁ
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section11Radio"
                                      id="section11Radio2"
                                      checked={values.powerType === 2}
                                      onClick={() => toggleSection11(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section11Radio2"
                                    >
                                      না
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>

                            <div className="row">
                              {values.powerType === 1 &&
                                values?.powertypes.map((item, index) => (
                                  <div key={index} className="col-md-12 mb-3">
                                    <div
                                      className={`${styles.add2PanelBackground}`}
                                    >
                                      <div className="row">
                                        <div className="col-md-10">
                                          <div className="row">
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>ব্যক্তির নাম</label>
                                                <ClientFormikFormFieldMultiple
                                                  value={"মাহিন সরকার"}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>ব্যক্তির ঠিকানা</label>
                                                <ClientFormikFormFieldMultiple
                                                  value={"মিরপুর-১০, ঢাকা"}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-3">
                                              <div className="form-group mb-2">
                                                <label>ব্যক্তির জাতীয়তা</label>
                                                <ClientFormikFormFieldMultiple
                                                  value={"বাংলাদেশী"}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-md-3">
                                              <div className="form-group border bordercolorr paddingg0 rounded p-1">
                                                <div
                                                  className={`${styles.add2FormGroupImagePreviewBox} form-group imgmffargin text-center`}
                                                >
                                                  <label>
                                                    লাইসেন্স প্রদানের ক্ষমতা
                                                    পত্র
                                                    <br />
                                                    (প্রযোজ্য ক্ষেত্রে)
                                                  </label>
                                                  <br />

                                                  <div
                                                    className={
                                                      styles.imagePreviewAdd2
                                                    }
                                                  >
                                                    <img
                                                      src={assets.chalan}
                                                      alt=""
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-2 centeraddremovebtn">
                                          <div className="form-group">
                                            <div className="mt-4 mtop3">
                                              {(values.powertypes.length ===
                                                index + 1 ||
                                                values.powertypes.length ===
                                                  1) && (
                                                <ImPlus
                                                  className="customAddButton  rounded"
                                                  onClick={
                                                    handleAddFieldSetBoxPartEleven
                                                  }
                                                />
                                              )}
                                              {values.powertypes.length > 1 &&
                                                index !== 0 && (
                                                  <IoCloseSharp
                                                    className="customRemoveButton rounded"
                                                    onClick={() =>
                                                      handleRemoveFieldSetBoxPartEleven(
                                                        index,
                                                        item?.id
                                                      )
                                                    }
                                                  />
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-12 mt-2">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 col-12">
                                <h5>
                                  <b>
                                    ১৩. শিল্পকর্ম ( শুধু শিল্পকর্মের জন্য
                                    প্রযোজ্য )
                                  </b>
                                </h5>
                              </div>

                              {values.category_id === 3 && (
                                <div className="col-md-12">
                                  <div
                                    className={`${styles.add2PanelBackground}`}
                                  >
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>
                                            ক. মূল শিল্পকর্মটি কোথায় আছে ?
                                          </label>
                                          <ClientFormikFormFieldMultiple
                                            value={"বাংলাদেশ জাতীয় জাদুঘর"}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>
                                            খ. যাদের আয়ত্বে আছে তাদের নাম
                                          </label>
                                          <ClientFormikFormFieldMultiple
                                            value={
                                              "গণপ্রজাতন্ত্রী বাংলাদেশ সরকার"
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>
                                            গ. যাদের আয়ত্বে আছে তাদের ঠিকানা
                                          </label>
                                          <ClientFormikFormFieldMultiple
                                            value={"শাহবাগ, ঢাকা, বাংলাদেশ"}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>
                                            ঘ. যাদের আয়ত্বে আছে তাদের জাতীয়তা
                                          </label>
                                          <ClientFormikFormFieldMultiple
                                            value={"বাংলাদেশী"}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group mb-2">
                                          <label>
                                            ঙ. কর্ম সমাপ্তির বছর ( স্থাপত্য
                                            শিল্পের ক্ষেত্রে উল্লেখ করতে হবে )
                                          </label>
                                          <ClientFormikYearSelector
                                            value={"২০১৫"}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-13 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 col-12">
                                <h5>
                                  <b>১৪. মন্তব্য লিখুন ( যদি থাকে )</b>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className={`${styles.add2PanelBackground} pb-2`}>
                            <div className="form-group mb-2">
                              <textarea
                                className="form-control"
                                rows="4"
                                name="comment"
                                value={"শিল্পকর্মের উপর আপনার মন্তব্য ।"}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add2-section-14 mt-3">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="add2-section-title">
                            <div className="row">
                              <div className="col-md-5 mtop0 mt-2 col-12">
                                <label> ** সার্টিফিকেট কোন ভাষায় চান ?</label>
                              </div>

                              <div className="col-md-6 py-1 col-12">
                                <span className="d-flex mtop0 pt-1">
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section14Radio"
                                      id="section14Radio1"
                                      checked={values.certificate === 1}
                                      onClick={() => toggleSection14(1)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section14Radio1"
                                    >
                                      বাংলা
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="section14Radio"
                                      id="section14Radio2"
                                      checked={values.certificate === 2}
                                      onClick={() => toggleSection14(2)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="section14Radio2"
                                    >
                                      ইংরেজি
                                    </label>
                                  </div>
                                </span>
                              </div>
                            </div>

                            {values.certificate === 1 && (
                              <div className="row">
                                <div className="col-md-12">
                                  <div
                                    className={`${styles.add2PanelBackground}`}
                                  >
                                    <div className="row">
                                      <div className="col-md-6 mb-2">
                                        <label>কর্মটির শিরোনাম</label>
                                        <ClientFormikFormFieldMultiple
                                          value={"বাংলার স্বাধীনতা"}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label>
                                          {values?.workPublishtype === 1
                                            ? "কর্ম প্রকাশনার বছর"
                                            : "কর্ম সম্পন্নের বছর"}
                                        </label>

                                        <ClientFormikFormFieldMultiple
                                          value={"১০/০২/২০২৩"}
                                        />
                                      </div>
                                      <div className="col-md-12 ">
                                        {values?.writerdetails?.map(
                                          (item, index) => (
                                            <div className="row pt-2">
                                              <div className="col-md-6">
                                                <div className="form-group pb-2">
                                                  <label
                                                    htmlFor={`writerdetails[${index}].writerName`}
                                                  >
                                                    লেখকের নাম ({index + 1})
                                                  </label>
                                                  <ClientFormikFormFieldMultiple
                                                    value={
                                                      "মোঃ শাহনেওয়াজ চৌধুরী"
                                                    }
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-md-6">
                                                <div className="form-group pb-2">
                                                  <label
                                                    htmlFor={`writerdetails[${index}].writerAddress`}
                                                  >
                                                    লেখকের ঠিকানা ({index + 1})
                                                  </label>
                                                  <ClientFormikFormFieldMultiple
                                                    value={
                                                      "গ্রামঃ রামনগরের চর, পোঃ আদদিয়া, থানাঃ নড়াইল, জেলাঃ নড়াইল"
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {values.certificate === 2 && (
                              <div className="row">
                                <div className="col-md-12">
                                  <div
                                    className={`${styles.add2PanelBackground}`}
                                  >
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <div className="form-group mb-2">
                                              <label>Title of the work</label>
                                              <ClientFormikFormFieldMultiple
                                                value={"Independence of Bengal"}
                                              />
                                            </div>
                                          </div>

                                          <div className="col-md-6">
                                            <div className="form-group mb-2">
                                              <label>
                                                {values?.workPublishtype === 1
                                                  ? "Year of Publication"
                                                  : "Year of Completion"}
                                              </label>

                                              <ClientFormikFormFieldMultiple
                                                value={"10/02/2023"}
                                              />
                                            </div>
                                          </div>

                                          {values.certificatelanguages.map(
                                            (item, index) => (
                                              <div
                                                key={index}
                                                className="col-md-12"
                                              >
                                                <div className="row">
                                                  <div className="col-md-10">
                                                    <div className="row">
                                                      <div className="col-md-4">
                                                        <div className="form-group">
                                                          <label>
                                                            Author / Creator
                                                          </label>
                                                          <ClientFormikFormFieldMultiple
                                                            value={
                                                              "Md. Shahnewaz Chowdhury"
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-md-4">
                                                        <div className="form-group mb-2">
                                                          <label>
                                                            Owner of the rights
                                                          </label>
                                                          <ClientFormikFormFieldMultiple
                                                            placeholder="Write Name in English"
                                                            value={
                                                              "Mahin Sarkar"
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                      <div className="col-md-4">
                                                        <div className="form-group mb-2">
                                                          <label>Address</label>
                                                          <ClientFormikFormFieldMultiple
                                                            value={
                                                              "Village: Ramnagar Char, Po: Addia, Thana: Narail, District: Narail"
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="col-md-2 centeraddremovebtn">
                                                    <div className="form-group">
                                                      <div className="mt-4 mtop3">
                                                        {(values
                                                          .certificatelanguages
                                                          .length ===
                                                          index + 1 ||
                                                          values
                                                            .certificatelanguages
                                                            .length === 1) && (
                                                          <ImPlus
                                                            className="customAddButton  rounded"
                                                            onClick={
                                                              handleAddFieldSetEngCertificate
                                                            }
                                                          />
                                                        )}
                                                        {values
                                                          .certificatelanguages
                                                          .length > 1 &&
                                                          index !== 0 && (
                                                            <IoCloseSharp
                                                              className="customRemoveButton rounded"
                                                              onClick={() =>
                                                                handleRemoveFieldSetEngCertificate(
                                                                  index,
                                                                  item?.id
                                                                )
                                                              }
                                                            />
                                                          )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="submit-area mt-5 mtop4">
                      <div className="add2-section-15 mt-4">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="add2-section-title">
                              <div className="row">
                                <div className="col-md-12 col-12">
                                  <div
                                    className={`${styles.add2PanelBackground}`}
                                  >
                                    <div className="d-flex align-items-center gap-3">
                                      <div className="d-flex">
                                        <div
                                          className={`${styles.customCheckbox}`}
                                        >
                                          <label
                                            className="form-check-label d-flex align-items-center"
                                            htmlFor="checkIsAgree"
                                          >
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              name="checkIsAgree"
                                              checked={checkIsAgree}
                                              id="checkIsAgree"
                                              onClick={toggleCheckIsAgree}
                                            />
                                            <span
                                              class={`${styles.checkmark}`}
                                              title="অঙ্গীকারনামা ভালো করে পড়ুন , সম্মত হলে নির্বাচন করে দাখিল করুন ।"
                                            ></span>
                                          </label>
                                        </div>
                                      </div>
                                      <p className="mb-0">
                                        আমি / আমরা ঘোষণা করছি যে, কপিরাইট
                                        রেজিস্ট্রেশনের আবেদনে আমার পূরণকৃত সকল
                                        তথ্য ও সংযুক্ত দলিলসমূহ সত্য ও নির্ভুল
                                        এবং এ কর্মটিকে কেন্দ্র করে কোন মামলা কোন
                                        বিজ্ঞ আদালত বা ট্রাইব্যুনালে বিচারাধীন
                                        নেই । উল্লিখিত তথ্য বা দলিলে কোন ভুল ও
                                        অসত্য তথ্য উদ্‌ঘাটিত হলে আমি / আমরা এর
                                        জন্য ব্যক্তিগতভাবে দায়ী থাকব এবং দেশের
                                        প্রচলিত আইন অনুযায়ী যেকোন দন্ড গ্রহণে
                                        বাধ্য হবো ।
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CopyrightTwo;
