import React, { useState } from "react";
import { ListGroup, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import ApplicationForCopyright from "./copyright-form/form_one/ApplicationForCopyright";
import CopyrightTwo from "./copyright-form/form_two/CopyrightTwo";
import DemoPayment from "./copyright-form/payment/DemoPayment";

const DemoFormLiterature = () => {
  const [cat, setCat] = useState(1);
  const [applications, setApplications] = useState("1");

  const handleSelect = (eventKey) => setCat(eventKey);
  const handleApplications = (eventKey) => setApplications(eventKey);

  const getSubCat = (cat) => {
    switch (cat) {
      case "1":
        return "15";
      case "2":
        return "27";
      case "6":
        return "61";
      case "7":
        return "67";
      case "8":
        return "70";
      case "9":
        return "72";
      case "11":
        return "99";
      case "13":
        return "88";
      default:
        return "";
    }
  };

  return (
    <div>
      {/* Start Breadcrumbs */}
      <div className="breadcrumbs" data-stellar-background-ratio="0.5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12">
              <div className="breadcrumbs-content left">
                <h1 className="page-title">পূরণকৃত ফরম ও নমুনা</h1>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div className="breadcrumbs-content right">
                <ul className="breadcrumb-nav">
                  <li>
                    <Link to="/" style={{ fontSize: 20 }}>
                      হোম পেইজ
                    </Link>
                  </li>
                  <li style={{ fontSize: 20 }}>পূরণকৃত ফরম ও নমুনা</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Breadcrumbs */}

      {/* Start Contact Area */}
      <div className="pt-5 my-3 pb-5">
        <div className="container">
          <div className="border shadow p-3 rounded">
            <div className="row">
              <div className="col-md-3">
                <div className="demo-form-list">
                  <ListGroup as="ul">
                    <ListGroup.Item
                      as="li"
                      active={cat === 1}
                      className="cursor-pointer"
                      onClick={() => {
                        handleSelect(1);
                      }}
                    >
                      সাহিত্য কর্ম
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      active={cat === 2}
                      className="cursor-pointer"
                      onClick={() => {
                        handleSelect(2);
                      }}
                    >
                      সংগীত কর্ম
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      active={cat === 3}
                      className="cursor-pointer"
                      onClick={() => {
                        handleSelect(3);
                      }}
                    >
                      শিল্প কর্ম
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      active={cat === 6}
                      className="cursor-pointer"
                      onClick={() => {
                        handleSelect(6);
                      }}
                    >
                      রেকর্ড কর্ম
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      active={cat === 7}
                      className="cursor-pointer"
                      onClick={() => {
                        handleSelect(7);
                      }}
                    >
                      চলচ্চিত্র কর্ম
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      active={cat === 8}
                      className="cursor-pointer"
                      onClick={() => {
                        handleSelect(8);
                      }}
                    >
                      নাট্য কর্ম
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      active={cat === 9}
                      className="cursor-pointer"
                      onClick={() => {
                        handleSelect(9);
                      }}
                    >
                      সফটওয়্যার কর্ম
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      active={cat === 10}
                      className="cursor-pointer"
                      onClick={() => {
                        handleSelect(10);
                      }}
                    >
                      টেলিভিশন সম্প্রচার
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      active={cat === 11}
                      className="cursor-pointer"
                      onClick={() => {
                        handleSelect(11);
                      }}
                    >
                      বেতার সম্প্রচার
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      active={cat === 13}
                      className="cursor-pointer"
                      onClick={() => {
                        handleSelect(13);
                      }}
                    >
                      ওয়েবসাইট, ই-মেইল ও <br /> ইলেকট্রনিক্স যোগাযোগসহ অন্য কোন
                      মাধ্যম
                    </ListGroup.Item>
                  </ListGroup>
                </div>
              </div>
              <div className="col-md-9">
                <div
                  style={{
                    backgroundColor: "#f8f9fa",
                    padding: "20px",
                    borderRadius: "10px",
                    maxHeight: "700px",
                    overflowY: "auto",
                  }}
                >
                  <div className="px-2 mt-3">
                    <Nav
                      justify
                      variant="pills"
                      activeKey={applications}
                      onSelect={handleApplications}
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="1">আবেদন পত্র - ১ম অংশ</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="2">আবেদন পত্র - ২য় অংশ</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="3">পেমেন্ট</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div>
                    {applications === "1" && (
                      <div className="slideUp">
                        <ApplicationForCopyright />
                      </div>
                    )}
                    {applications === "2" && (
                      <div className="slideUp">
                        <CopyrightTwo
                          category={cat}
                          subCategory={getSubCat(cat)}
                        />
                      </div>
                    )}
                    {applications === "3" && (
                      <div className="slideUp">
                        <DemoPayment />
                      </div>
                    )}
                    {/* Add more components for other applications if needed */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoFormLiterature;
