import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import { useGetLatestReplyOrderedShowMutation } from "../redux/api/submittedCopyrightAgreementApiSlice";
import { useAuth } from "../utils/authorization";
import { localDateFormat } from "../utils/dateFormat";

const LibrarianReply = ({ id, show, onHide }) => {
  const { authUser } = useAuth();
  const [reply, { data, isLoading }] = useGetLatestReplyOrderedShowMutation(id);

  useEffect(() => {
    // Fetch latest reply when component mounts
    if (id) {
      reply(id);
    }
  }, [id]);

  console.log(id, authUser);
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {isLoading ? (
        <BeatLoader />
      ) : (
        <>
          <Modal.Header>
            <div className="">
              <h5>
                {Number(data?.apptype) === 1 ? "কপিরাইট " : "চুক্তিপত্র "}{" "}
                {data?.fileStatusName}
              </h5>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="py-2">
              <div className="card p-3">
                {data?.userId !== authUser?.id && (
                  <>
                    <label htmlFor="">আদেশ,</label>
                    <p className="m-0">{data?.userName}</p>
                    <span>তারিখঃ {localDateFormat(data?.date)}</span>
                  </>
                )}
                <label className="mt-5">জাবাব,</label>
                <p className="my-2"> {data?.reply}</p>

                <span>তারিখঃ {localDateFormat(data?.replyDate)}</span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Header>
            <div className="text-center">
              <Button size="sm" variant="secondary" onClick={onHide}>
                বন্ধ করুন
              </Button>
            </div>
          </Modal.Header>
        </>
      )}
    </Modal>
  );
};

export default LibrarianReply;
