import * as Yup from "yup";

export const validationSchema = Yup.object({
  communicationAddress: Yup.string().required("যোগাযোগের ঠিকানা প্রযোজ্য"),
  place: Yup.string().required("স্থান প্রযোজ্য"),
  sign: Yup.string().when("editId", {
    is: (editId) => !editId,
    then: () => Yup.string().required("স্বাক্ষর প্রযোজ্য"),
    otherwise: () => Yup.string(),
  }),

  puname: Yup.string().required("নাম প্রযোজ্য"),
  profileteamtypes: Yup.array().when("teamType", {
    is: (teamType) => Number(teamType) === 1,
    then: () =>
      Yup.array().of(
        Yup.object().shape({
          teamName: Yup.string().required("নাম প্রযোজ্য"),
          teamAddress: Yup.string().required("ঠিকানা প্রযোজ্য"),
          teamEmail: Yup.string()
            .email("ই-মেইল ফরম্যাট সঠিক নয়")
            .required("ই-মেইল প্রযোজ্য"),
          teamMobile: Yup.string()
            .matches(
              /^01\d{9}$/,
              "মোবাইল নাম্বার অবশ্যই ০১ দিয়ে শুরু হতে হবে এবং মোট ১১ সংখ্যা হতে হবে"
            )
            .required("মোবাইল প্রযোজ্য"),
          teamSenddate: Yup.date().required("তারিখ প্রযোজ্য").nullable(),
        })
      ),
    otherwise: () =>
      Yup.array().of(
        Yup.object().shape({
          teamName: Yup.string().nullable(),
          teamAddress: Yup.string().nullable(),
          teamEmail: Yup.string().nullable(),
          teamMobile: Yup.string().nullable(),
          teamSenddate: Yup.date().nullable(),
        })
      ),
  }),
});

export const initialValues = {
  teamType: 2,
  communicationAddress: "",
  place: "",
  puname: "",
  sign: "",
  profileteamtypes: [
    {
      teamName: "",
      teamAddress: "",
      teamEmail: "",
      teamMobile: "",
      teamSenddate: "",
    },
  ],
};
