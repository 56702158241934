import { Handshake, Home, Note, Print, ReplyAll } from "@mui/icons-material";
import React, { useRef } from "react";
import { Card, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import IconBreadcrumbs from "../../../../../components/custom/IconBreadcrumbs";
import Loader from "../../../../../components/custom/Loader";
import { useShowEndorseQuery } from "../../../../../redux/api/submittedCopyrightAgreementApiSlice";

const PrintAgreementEndorse = () => {
  const printRef = useRef();
  const { id, user_id } = useParams();
  const { data, isLoading, refetch } = useShowEndorseQuery({ id, user_id });
  const navigate = useNavigate();
  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: Handshake,
      title: "চুক্তিপত্র - এন্ডোর্স",
    },
    {
      icon: Note,
      title: "চুক্তিপত্র রেজিস্ট্রেশন সনদ",
      active: true,
    },
  ];

  const handleBack = () => {
    navigate(-1);
  };

  const documents = data?.data?.documents;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <div className="py-3">
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </div>{" "}
      <div className="dashboard-layout slideUp">
        <div className="">
          <div className="pt-4 text-center">
            <span>
              <Card variant="outlined">
                <div className="d-flex p-2 gap-2">
                  <div className="cursor-pointer">
                    <ReactToPrint
                      trigger={() => (
                        <div>
                          <Print /> Print
                        </div>
                      )}
                      documentTitle="certificate"
                      content={() => printRef.current}
                    />
                  </div>
                  <span className="border"></span>
                  <div className="cursor-pointer" onClick={handleBack}>
                    <ReplyAll />
                    Back
                  </div>
                </div>
              </Card>
            </span>
          </div>
          <div className="m-4">
            {" "}
            <Table striped bordered hover>
              <tbody>
                <tr>
                  <th>আবেদনকারীর নাম</th>
                  <td>{data?.data?.applicantName}</td>
                  <th>কর্মের শ্রেণি</th>
                  <td>{documents?.category?.name}</td>
                </tr>
                <tr>
                  <th>ট্র্যাকিং নাম্বার</th>
                  <td>{data?.data?.trackReg}</td>
                  <th>কর্মের উপশ্রেণি</th>
                  <td>{documents?.subCategory?.name}</td>
                </tr>
                <tr>
                  <th>নিবন্ধন নাম্বার</th>
                  <td>{data?.data?.archive?.registration_no}</td>
                  <th>কর্মের নাম</th>
                  <td>{documents?.worktitle}</td>
                </tr>
              </tbody>
            </Table>
            <div className="mt-3 pb-4">
              <div
                className="rounded mb-4"
                style={{
                  background: "#f4f4f4",
                  padding: "10px",
                }}
              >
                <div ref={printRef}>
                  <div
                    style={{
                      background: "#020202",
                      padding: "5px",
                    }}
                    className="d-flex rounded justify-content-between align-items-center"
                  >
                    <label className="text-white">
                      চুক্তিনামার মূলকপি ও নোটারি পাবলিক দ্বারা সত্যায়িত ফটোকপি
                      -
                    </label>
                    <label htmlFor="" className="show-print text-white">
                      নিবন্ধন নাম্বার: {data?.data?.archive?.registration_no}
                    </label>
                  </div>
                  {data?.data?.istructure ? (
                    <img
                      style={{
                        width: "100%",
                        height: "auto",
                      }}
                      src={data?.data?.istructure}
                      alt=""
                    />
                  ) : (
                    <p>Data not found</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintAgreementEndorse;
