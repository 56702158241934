import { FindInPage, Home, Print, Search } from "@mui/icons-material";
import { Box } from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useReactToPrint } from "react-to-print";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { assets } from "../../assets";
import IconBreadcrumbs from "../../components/custom/IconBreadcrumbs";
import EmailForCorrection from "../../components/email_for_correction/EmailForCorrection";
import AdminFullFormAgreement from "../../components/full-form-admin/AdminFullFormAgreement";
import AdminFullFormCopyright from "../../components/full-form-admin/AdminFullFormCopyright";
import NoteSheet from "../../components/NoteSheet";
import { useAuthorized } from "../../hooks/useAuthorized";
import {
  useLazyGetFileLocationSearchQuery,
  usePostCommentAndOrderedSearchMutation,
} from "../../redux/api/backendUtilsApiSlice";
import { useGetRolesQuery } from "../../redux/api/userApiSlice";
import { accessRoles } from "../../utils/accessRoles";
import { AgreementKeyAttachArray } from "../../utils/agreementGenerateAttachArray";
import { CopyrightKeyAttachArray } from "../../utils/CopyrightGenerateAttachArray";

const validationSchema = Yup.object({
  comments: Yup.string()
    .required("মন্তব্য আবশ্যক")
    .min(10, "মন্তব্য কমপক্ষে ১০ অক্ষর হতে হবে"),
});

const ApplicationsTracking = () => {
  const printFullFormRef = useRef();
  const [previewData, setPreviewData] = useState("");
  const { hasRole } = useAuthorized();
  const [trackNo, setTrackNo] = React.useState("");
  const navigate = useNavigate();
  const printRef = useRef();
  const { data: roles } = useGetRolesQuery();

  const [openCorrectionModal, setOpenCorrectionModal] = useState(false);
  const [correctionData, setCorrectionData] = useState(null);

  // get query regTrack
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const regTrack = searchParams.get("regTrack");

  // post comment
  const [submitComment] = usePostCommentAndOrderedSearchMutation();

  const [fetchFileData, { data, isLoading, error, isFetching }] =
    useLazyGetFileLocationSearchQuery();

  const breadcrumbsData = [
    { icon: Home, title: "হোম", url: "/" },
    {
      icon: FindInPage,
      title: hasRole([accessRoles.Register, accessRoles.CopyrightAdmin])
        ? `নথি সন্ধান`
        : `আবেদনের অবস্থান অনুসন্ধান`,
      active: true,
    },
  ];

  const handleSearch = () => {
    if (trackNo) {
      fetchFileData(trackNo);
      navigate(`/admin/applications-tracking?regTrack=${trackNo}`);
    }
  };

  useEffect(() => {
    if (regTrack) {
      setTrackNo(regTrack);
      fetchFileData(regTrack);
    }
  }, [regTrack]);

  const info = data?.data;
  const status = info?.comments?.[0]?.status;

  // Add condition to ensure that info is available before calling CopyrightKeyAttachArray
  const infoTable = status
    ? Number(info.apptype) === 1
      ? CopyrightKeyAttachArray({ st: Number(info.st), status })
      : AgreementKeyAttachArray({ st: Number(info.st), status })
    : null;

  const commentsNoteSheet = {
    noteSheetList: info?.comments,
    noteSheetValues: status,
  };

  const infoForNote = {
    noteNumber: info?.trackReg,
    applicantName: info?.name,
    workTitle: info?.workTitle,
    category: info?.catName,
    amount: info?.amount,
    challan: Number(info?.st) === 3 ? "চালান" : "ট্রেজারি",
    challanNumber: Number(info?.st) === 3 ? info?.echallanId : info?.treasuryNo,
    treasuryDate: info?.treasuryDate || new Date(),
  };

  const handleEditStepOne = () => {
    if (Number(info?.apptype) === 1) {
      navigate(`/admin/copyright-first-part-edit/${info.id}/${info.trackReg}`);
    } else {
      navigate(`/admin/agreement-first-part-edit/${info.id}/${info.trackReg}`);
    }
  };

  const handleEditStepTwo = () => {
    if (Number(info?.apptype) === 1) {
      navigate(
        `/admin/copyright-second-part-edit/${info.pdDocId}/${info.trackReg}`
      );
    } else {
      navigate(
        `/admin/agreement-second-part-edit/${info.pdDocId}/${info.trackReg}`
      );
    }
  };
  const handlePayment = () => {
    navigate(`/admin/treasury-payment-edit/${info.id}/${info.trackReg}`);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const handleFullFormPrint = useReactToPrint({
    content: () => printFullFormRef.current,
  });

  useEffect(() => {
    if (info) {
      setPreviewData({
        id: info.id,
        trackReg: info.trackReg,
      });
    }
  }, [info]);

  const handleCorrection = () => {
    setOpenCorrectionModal(true);
    setCorrectionData(info);
  };

  const requiredFiles = [1, 2, 3, 10, 13, 14, 15, 17, 18, 23];

  return (
    <div>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        py={1}
        mb={1}
      >
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </Box>
      <div className="slideUp dashboard-layout p-4">
        <div className="py-2">
          <div className="row">
            <div className="col-md-4">
              <label htmlFor="">ট্র্যাকিং নাম্বার</label>
              <div className="d-flex gap-2">
                <Form.Control
                  value={trackNo}
                  onChange={(e) => setTrackNo(e.target.value)}
                />
                <Button
                  disabled={isLoading}
                  className="d-flex"
                  onClick={handleSearch}
                >
                  {isLoading || isFetching ? (
                    <BeatLoader color="#fff" />
                  ) : (
                    <>
                      {" "}
                      <Search /> খুঁজুন
                    </>
                  )}
                </Button>
              </div>
            </div>
            {error && (
              <div className="p-3">
                <div className="p-4 card">
                  <label htmlFor="">
                    এই ট্র্যাকিং নাম্বার / আবেদনকারীর নাম / কর্মের শিরোনাম সহ
                    কোন আবেদন পাওয়া যায়নি ।
                  </label>
                </div>
              </div>
            )}
            {info && !error && (
              <>
                <div className="col-md-12 mt-4">
                  <div className="card">
                    <div className="row">
                      <div className="d-flex align-items-center justify-content-center col-md-4">
                        <div className="p-3 text-center card m-3">
                          <img
                            src={info?.image || assets.avatar}
                            className="rounded"
                            alt=""
                          />
                          <div className="mt-3">
                            <label htmlFor="" className="border rounded p-2">
                              ট্র্যাকিং নাম্বার - {info?.trackReg}
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-8">
                        <div className="p-3">
                          <div className="text-left">
                            <h5 className="fw-bold">
                              {Number(info?.apptype) === 1
                                ? "কপিরাইটের"
                                : "চুক্তিপত্রের"}{" "}
                              আবেদন
                            </h5>
                          </div>
                          <div className="mt-4">
                            <Table striped bordered>
                              <tbody>
                                <tr>
                                  <th>নথির অবস্থান</th>
                                  <td>
                                    <span className="fw-semibold">
                                      {info?.fileStatusName}
                                    </span>{" "}
                                    <br /> {info?.admin?.name} <br />{" "}
                                    {info?.admin?.role?.bnName}
                                  </td>
                                </tr>
                                <tr>
                                  <th>আবেদনকারীর নাম</th>
                                  <td>{info?.name}</td>
                                </tr>
                                <tr>
                                  <th>কর্মের শ্রেণি</th>
                                  <td>{info?.catName}</td>
                                </tr>
                                <tr>
                                  <th>কর্মের উপশ্রেণি</th>
                                  <td>{info?.subCatName}</td>
                                </tr>
                                <tr>
                                  <th>কর্মের শিরোনাম</th>
                                  <td>{info?.workTitle}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 my-3">
                  {hasRole([accessRoles.CopyrightAdmin]) && (
                    <div className="card p-3">
                      <label className="mb-2 fs-6">সংশোধন</label>
                      <div
                        class="btn-group"
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        <Button
                          variant="btn btn-outline-secondary"
                          for="btnradio2"
                          onClick={handleEditStepOne}
                        >
                          ১ম অংশ
                        </Button>

                        <Button
                          variant="btn btn-outline-secondary"
                          for="btnradio3"
                          onClick={handleEditStepTwo}
                        >
                          ২য় অংশ
                        </Button>
                        <Button
                          variant="btn btn-outline-secondary"
                          for="btnradio3"
                          onClick={handlePayment}
                        >
                          পেমেন্ট{" "}
                          {Number(info?.st) === 2 ? "(ট্রেজারি)" : "(ই-চালান)"}
                        </Button>

                        <Button
                          variant="btn btn-outline-secondary"
                          for="btnradio1"
                          onClick={handleCorrection}
                          disabled={
                            !requiredFiles.includes(Number(info?.fileStatus))
                          }
                        >
                          ই-মেইল পাঠান
                        </Button>

                        <Button
                          variant="btn btn-outline-secondary"
                          for="btnradio3"
                          onClick={() => {
                            setPreviewData({
                              id: info.id,
                              trackReg: info?.trackReg,
                            });
                            handleFullFormPrint();
                          }}
                        >
                          প্রিন্ট করুন
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                {hasRole([
                  accessRoles.CopyrightAdmin,
                  accessRoles.Register,
                ]) && (
                  <>
                    <div className="col-md-12 mt-3">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="bg-slate p-2 d-flex justify-content-between">
                            <div className="fw-semibold">
                              নথি অনুসন্ধান আদেশ
                            </div>
                            <div className="fw-semibold">
                              প্রতি,{" "}
                              {
                                roles?.data?.find((item) => item.id === 9)
                                  ?.bn_name
                              }
                            </div>
                          </div>
                          {Number(info?.fileStatus) &&
                          [6, 19, 25].includes(Number(info?.fileStatus)) ? (
                            info?.profileComment ? (
                              <Alert className="mt-3">
                                ইতিমধ্যে মন্তব্য সহ নথি অনুসন্ধানের আদেশ প্রেরণ
                                করা হয়েছে।
                              </Alert>
                            ) : info?.archive &&
                              Number(info?.archive?.status) === 1 ? (
                              Number(info?.archive?.certificate_canceled) !==
                              1 ? (
                                <Formik
                                  initialValues={{ comments: "" }}
                                  validationSchema={validationSchema}
                                  onSubmit={async (values) => {
                                    const result = await Swal.fire({
                                      text: "আপনি কি নিশ্চিত? আপনি কি দাখিল করতে চান?",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      cancelButtonText: "না",
                                      confirmButtonText: "হ্যাঁ",
                                    });

                                    if (result.isConfirmed) {
                                      try {
                                        await submitComment({
                                          comment: values.comments,
                                          trackReg: info?.trackReg,
                                        }).unwrap();

                                        toast.success(
                                          "নথি অনুসন্ধান আদেশ পাঠানো হয়েছে।"
                                        );
                                      } catch (err) {
                                        toast.error(
                                          "নথি অনুসন্ধান আদেশ পাঠানো হয়নি। আবার চেষ্টা করুন।"
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {({
                                    values,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    errors,
                                    touched,
                                    isSubmitting,
                                  }) => (
                                    <form onSubmit={handleSubmit}>
                                      <div className="mt-2">
                                        <label
                                          className="my-2 fw-semibold"
                                          htmlFor="comments"
                                        >
                                          মন্তব্য
                                        </label>
                                        <Form.Control
                                          name="comments"
                                          placeholder="এখানে লিখুন..."
                                          as="textarea"
                                          rows={6}
                                          value={values.comments}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          isInvalid={
                                            touched.comments &&
                                            !!errors.comments
                                          }
                                        />
                                        {touched.comments &&
                                          errors.comments && (
                                            <div className="text-danger">
                                              {errors.comments}
                                            </div>
                                          )}
                                      </div>

                                      <div className="text-center my-3">
                                        <Button
                                          variant="primary"
                                          type="submit"
                                          disabled={isSubmitting}
                                        >
                                          {isSubmitting ? (
                                            <BeatLoader />
                                          ) : (
                                            "দাখিল করুন"
                                          )}
                                        </Button>
                                      </div>
                                    </form>
                                  )}
                                </Formik>
                              ) : (
                                <Alert className="mt-3">
                                  {Number(info?.apptype) === 1
                                    ? "সনদ বাতিল করা হয়েছে"
                                    : "এন্ডোর্স বাতিল করা হয়েছে"}
                                </Alert>
                              )
                            ) : (
                              <Alert className="fw-semibold mt-3">
                                {info?.fileStatusName}
                              </Alert>
                            )
                          ) : [24].includes(Number(info?.fileStatus)) ? (
                            <Alert className="mt-3">
                              {
                                roles?.data?.find((item) => item.id === 9)
                                  ?.bn_name
                              }{" "}
                              মন্তব্য এবং অনুসন্ধান আদেশ অনুযায়ী নথি ফেরত
                              পাঠিয়েছেন।
                            </Alert>
                          ) : (
                            <Alert className="fw-semibold mt-3">
                              {info?.fileStatusName}
                            </Alert>
                          )}
                        </div>
                        <div className="col-md-6">
                          <div className="d-flex justify-content-between align-items-center bg-slate p-2 ">
                            <div className="fw-semibold">সম্পূর্ণ নোট শীট</div>

                            <span onClick={handlePrint}>
                              <Print className="text-primary fs-4 cursor-pointer" />
                            </span>
                          </div>
                          {status && (
                            <div className="mt-3">
                              <div
                                className="card my-2 p-2"
                                style={{ height: "800px", overflow: "auto" }}
                              >
                                <NoteSheet
                                  commentsNoteSheet={commentsNoteSheet}
                                  info={infoForNote}
                                  infoTable={infoTable}
                                  printRef={printRef}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {previewData && data?.data && (
        <>
          {Number(info?.apptype) === 1 ? (
            <AdminFullFormCopyright
              printRef={printFullFormRef}
              info={previewData}
            />
          ) : (
            <AdminFullFormAgreement
              printRef={printFullFormRef}
              info={previewData}
            />
          )}
        </>
      )}
      <EmailForCorrection
        open={openCorrectionModal}
        info={correctionData}
        onClose={() => {
          setOpenCorrectionModal(false);
          setCorrectionData(null);
        }}
      />
    </div>
  );
};

export default ApplicationsTracking;
