import React from "react";
import toast from "react-hot-toast";
import { ImPlus } from "react-icons/im";
import { IoCloseSharp } from "react-icons/io5";
import Swal from "sweetalert2";
import { assets } from "../../../../assets";
import ClientFormikFormFieldMultiple from "../../../../components/custom/form/ClientFormikFormFieldMultiple";
import {
  useDeleteAuthorizeTypeMutation,
  useDeleteBasicPublishMutation,
  useDeleteWorkTypeMutation,
} from "../../../../redux/api/editFormApiSlice";
import styles from "./Add2.module.css";

const WorkMoulikBox = ({ values, baseIndex, setValues }) => {
  const [deleteWorkType] = useDeleteWorkTypeMutation();
  const [deleteBasicPublish] = useDeleteBasicPublishMutation();
  const [deleteAuthorizeType] = useDeleteAuthorizeTypeMutation();

  const handleWorkOnubadok = (val) => {
    setValues((prevValues) => ({
      ...prevValues,
      basicworktypes: prevValues.basicworktypes.map((item, i) =>
        i === baseIndex
          ? {
              ...item,
              authorize: val,
              authorizetypes: [
                { name: "", address: "", nationality: "", approval_sc: "" },
              ],
            }
          : item
      ),
    }));
  };

  const handleAddFieldSetWorkOwnerLife = () => {
    const newFieldSet = {
      name: "",
      address: "",
      nationality: "",
      deadDate: null,
    };
    setValues((prevValues) => ({
      ...prevValues,
      basicworktypes: prevValues.basicworktypes.map((item, i) =>
        i === baseIndex
          ? {
              ...item,
              worktypes: [...item.worktypes, newFieldSet],
            }
          : item
      ),
    }));
  };

  const handleRemoveFieldSetWorkOwnerLife = async (index, itemId) => {
    const updateCheckboxState = () => {
      setValues((prevValues) => ({
        ...prevValues,
        basicworktypes: prevValues.basicworktypes.map((item, i) =>
          i === baseIndex
            ? {
                ...item,
                worktypes: item.worktypes.filter((_, j) => j !== index),
              }
            : item
        ),
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteWorkType({
            id: itemId,
            fromData: {
              basicworktype_id: values?.basicworktypes[baseIndex]?.id,
            },
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  const handleAddFieldSetWorkPublisher = () => {
    const newFieldSet = {
      name: "",
      address: "",
      nationality: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      basicworktypes: prevValues.basicworktypes.map((item, i) =>
        i === baseIndex
          ? {
              ...item,
              basicpublishes: [...item.basicpublishes, newFieldSet],
            }
          : item
      ),
    }));
  };

  const handleRemoveFieldSetWorkPublisher = async (index, itemId) => {
    const updateCheckboxState = () => {
      setValues((prevValues) => ({
        ...prevValues,
        basicworktypes: prevValues.basicworktypes.map((item, i) =>
          i === baseIndex
            ? {
                ...item,
                basicpublishes: item.basicpublishes.filter(
                  (_, j) => j !== index
                ),
              }
            : item
        ),
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteBasicPublish({
            id: itemId,
            fromData: {
              basicworktype_id: values?.basicworktypes[baseIndex]?.id,
            },
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  const handleAddFieldSetWorkOnubadok = () => {
    const newFieldSet = {
      name: "",
      address: "",
      nationality: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      basicworktypes: prevValues.basicworktypes.map((item, i) =>
        i === baseIndex
          ? {
              ...item,
              authorizetypes: [...item.authorizetypes, newFieldSet],
            }
          : item
      ),
    }));
  };

  const handleRemoveFieldSetWorkOnubadok = async (index, itemId) => {
    const updateCheckboxState = () => {
      setValues((prevValues) => ({
        ...prevValues,
        basicworktypes: prevValues.basicworktypes.map((item, i) =>
          i === baseIndex
            ? {
                ...item,
                authorizetypes: item.authorizetypes.filter(
                  (_, j) => j !== index
                ),
              }
            : item
        ),
      }));
    };

    if (itemId) {
      const result = await Swal.fire({
        text: "আপনি কি মুছে ফেলতে চান?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "না",
        confirmButtonText: "হ্যাঁ",
      });

      if (result.isConfirmed) {
        try {
          await deleteAuthorizeType({
            id: itemId,
            fromData: {
              basicworktype_id: values?.basicworktypes[baseIndex]?.id,
            },
          }).unwrap();
          updateCheckboxState(); // Update checkbox state after successful deletion
          toast.success("মুছে ফেলা হয়েছে");
        } catch (err) {
          toast.error(err?.data?.message || "মুছে ফেলতে ব্যর্থ হয়েছে");
        }
      }
    } else {
      updateCheckboxState(); // Update checkbox state directly when itemId is not present
    }
  };

  return (
    <div className="work-moulik-box-content copyrightformone mt-3 mleft0 ms-3">
      <div className="form-group mb-2">
        <div className="row">
          <div className="col-md-3">
            <label className="">(ক) মৌলিক কর্মটির শিরোনাম</label>
          </div>
          <div className="col-md-9 mb-2">
            <ClientFormikFormFieldMultiple value={"বাংলার স্বাধীনতা"} />
          </div>
        </div>
      </div>
      <div className="form-group mb-2">
        <div className="row">
          <div className="col-md-3">
            <label className="">(খ) মৌলিক কর্মটির ভাষা</label>
          </div>
          <div className="col-md-9 mb-2">
            <ClientFormikFormFieldMultiple value={"বাংলা"} />
          </div>
        </div>
      </div>

      <div className="form-group mb-2">
        <div className="row">
          <div className="col-md-12 me-5 mb-2">
            <label className="">
              (গ) মৌলিক কর্মটির লেখক/ রচয়িতা/ প্রণেতা জীবিত না মৃত ?
            </label>
          </div>
          <div className="col-md-12 me-5 mb-2">
            {values?.basicworktypes[baseIndex]?.worktypes?.map(
              (item, index) => (
                <div key={index} className="row">
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label>নাম</label>
                          <ClientFormikFormFieldMultiple
                            value={"মোঃ শাহনেওয়াজ চৌধুরী"}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label>ঠিকানা</label>
                          <ClientFormikFormFieldMultiple
                            value={
                              "গ্রামঃ রামনগরের চর, পোঃ আদদিয়া, থানাঃ নড়াইল, জেলাঃ নড়াইল"
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label>জাতীয়তা</label>
                          <ClientFormikFormFieldMultiple value={"বাংলাদেশী"} />
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label>মৃত্যুর তারিখ</label>
                          <ClientFormikFormFieldMultiple value={"২৬/০৪/২০১৭"} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-2 centeraddremovebtn">
                    <div className="form-group">
                      <div className="mtop2 mt-4">
                        {(values.basicworktypes[baseIndex].worktypes.length ===
                          index + 1 ||
                          values.basicworktypes[baseIndex].worktypes.length ===
                            1) && (
                          <ImPlus
                            className="customAddButton rounded"
                            onClick={handleAddFieldSetWorkOwnerLife}
                          />
                        )}
                        {values.basicworktypes[baseIndex].worktypes.length >
                          1 &&
                          index !== 0 && (
                            <IoCloseSharp
                              className="customRemoveButton rounded"
                              onClick={() =>
                                handleRemoveFieldSetWorkOwnerLife(
                                  index,
                                  item?.id
                                )
                              }
                            />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <div className="form-group mb-2">
        <div className="row">
          <div className="col-md-12 mb-2">
            <label>(ঘ) কর্মটির প্রকাশক(যদি থাকে)</label>
          </div>
          <div className="col-md-12 mb-2">
            {values?.basicworktypes[baseIndex]?.basicpublishes?.map(
              (publisher, index) => (
                <div key={index} className="row">
                  <div className="col-md-10">
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label>নাম</label>
                          <ClientFormikFormFieldMultiple
                            value={"মোঃ অপূর্ব চৌধুরী"}
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label>ঠিকানা</label>
                          <ClientFormikFormFieldMultiple
                            value={
                              "গ্রামঃ কৈমারী (ছাতুপাড়া) , পোঃ কৈমারী, থানাঃ জলঢাকা, জেলাঃ নীলফামারী"
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="form-group">
                          <label>জাতীয়তা</label>
                          <ClientFormikFormFieldMultiple value={"বাংলাদেশী"} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 centeraddremovebtn">
                    <div className="form-group">
                      <div className="mt-4 mtop2">
                        {(values.basicworktypes[baseIndex].basicpublishes
                          .length ===
                          index + 1 ||
                          values.basicworktypes[baseIndex].basicpublishes
                            .length === 1) && (
                          <ImPlus
                            className="customAddButton rounded"
                            onClick={handleAddFieldSetWorkPublisher}
                          />
                        )}
                        {values.basicworktypes[baseIndex].basicpublishes
                          .length > 1 &&
                          index !== 0 && (
                            <IoCloseSharp
                              className="customRemoveButton rounded"
                              onClick={() =>
                                handleRemoveFieldSetWorkPublisher(
                                  index,
                                  publisher?.id
                                )
                              }
                            />
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <div className="form-group mb-2">
        <div className="row">
          <div className="col-md-12 mb-2">
            <div className="row">
              <div className="col-md-5 mt-2 mtop0 ">
                <label>
                  (ঙ) কর্মটির অনুবাদ কিংবা অভিযোজন করার ক্ষমতাপ্রাপ্ত ব্যক্তি কি
                  আছেন?
                </label>
              </div>
              <div className="col-md-7 my-1 col-12">
                <span className="d-flex ptop0 pt-1">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`basicworktypes[${baseIndex}].authorize1`}
                      id={`basicworktypes[${baseIndex}].authorize1`}
                      checked={
                        values.basicworktypes[baseIndex]?.authorize === 1
                      }
                      onClick={() => handleWorkOnubadok(1)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`basicworktypes[${baseIndex}].authorize1`}
                    >
                      হ্যাঁ
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`basicworktypes[${baseIndex}].authorize2`}
                      id={`basicworktypes[${baseIndex}].authorize2`}
                      checked={
                        values.basicworktypes[baseIndex]?.authorize === 2
                      }
                      onClick={() => handleWorkOnubadok(2)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`basicworktypes[${baseIndex}].authorize2`}
                    >
                      না
                    </label>
                  </div>
                </span>
              </div>
            </div>
          </div>

          {values.basicworktypes[baseIndex]?.authorize === 1 && (
            <div className="col-md-12 mb-2">
              {values?.basicworktypes[baseIndex].authorizetypes.map(
                (translator, index) => (
                  <div key={index} className="row">
                    <div className="col-md-10">
                      <div className="row">
                        <div className="col-md-3 mb-2">
                          <div className="form-group">
                            <label>নাম</label>
                            <ClientFormikFormFieldMultiple
                              value={"মোঃ ইমরুল হোসেন"}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 mb-2">
                          <div className="form-group">
                            <label>ঠিকানা</label>
                            <ClientFormikFormFieldMultiple
                              value={
                                "গ্রামঃ নতুন ভাঙ্গাবাড়ি, পোঃ ভাঙ্গাবান, থানাঃ সিরাজগঞ্জ, জেলাঃ সিরাজগঞ্জ"
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-3 mb-2">
                          <div className="form-group">
                            <label>জাতীয়তা</label>
                            <ClientFormikFormFieldMultiple
                              value={"বাংলাদেশী"}
                            />
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="border marginBottomm p-1 bordercolorr margin-10px rounded paddingg0 mb-3 ptop0">
                            <div className="form-group text-center imgmffargin">
                              <label className="text-center" style={{}}>
                                অনুমোদনের স্ক্যান কপি
                              </label>
                              <br />
                              <div className={styles.imagePreviewAdd2}>
                                <img src={assets.approve} alt="" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2 centeraddremovebtn">
                      <div className="form-group">
                        <div className="mt-4 mtop0">
                          {(values.basicworktypes[baseIndex].authorizetypes
                            .length ===
                            index + 1 ||
                            values.basicworktypes[baseIndex].authorizetypes
                              .length === 1) && (
                            <ImPlus
                              className="customAddButton rounded"
                              onClick={handleAddFieldSetWorkOnubadok}
                            />
                          )}
                          {values.basicworktypes[baseIndex].authorizetypes
                            .length > 1 &&
                            index !== 0 && (
                              <IoCloseSharp
                                className="customRemoveButton rounded"
                                onClick={() =>
                                  handleRemoveFieldSetWorkOnubadok(
                                    index,
                                    translator?.id
                                  )
                                }
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkMoulikBox;
