import { CheckCircle, MoreHoriz, Search } from "@mui/icons-material";
import { Divider } from "@mui/material";
import React, { useState } from "react";
import {
  Alert,
  Badge,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { useLazyGetApplicationsQuery } from "../redux/api/reportApiSlice";

// ──────────────────────────────────────────────────────────────────────────────
// Component: ApplicantApplicationsTrack
// Description:
//    - This component displays the tracking status of an application.
//    - It accepts `show` and `onHide` as props for modal control and manages
//      the tracking data using API calls.
// ──────────────────────────────────────────────────────────────────────────────

const ApplicantApplicationsTrack = ({ show, onHide }) => {
  // ─── State Management ──────────────────────────────────────────────────────
  const [trackReg, setTrackReg] = useState("");
  const [ee, setEe] = useState(false);

  // ─── Lazy API Call for Tracking Data ───────────────────────────────────────
  const [
    getReportTrack,
    { isLoading: isLoadingTrack, data: dataTrack, isError, isFetching },
  ] = useLazyGetApplicationsQuery();

  // ─── Function: onSubmit ────────────────────────────────────────────────────
  // Description: Executes the tracking API call when a tracking number is provided.
  const onSubmit = async () => {
    if (trackReg) {
      getReportTrack({ trackReg });
      setEe(false); // Reset error if submission is valid
    } else {
      setEe(true); // Show error if trying to submit an empty input
    }
  };

  const onBlur = (e) => {
    if (e.target.value) {
      setEe(false);
    } else {
      setEe(true);
    }
  };

  const data = dataTrack?.data; // Extracting data from API response
  console.log(dataTrack?.data); // Logging for debug purposes

  return (
    <Modal show={show} onHide={onHide}>
      <div className="modal-content">
        {/* ─── Modal Header ───────────────────────────────────────────────────── */}
        <div className="modal-header user-modal-header">
          <h5 className="modal-title text-light" id="applicationTrackingLabel">
            আবেদন পত্র ট্র্যাকিং
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => onHide()}
          ></button>
        </div>

        {/* ─── Modal Body: Input for Tracking Number ──────────────────────────── */}
        <div className="modal-body">
          <div className="row">
            <div className="col-md-12">
              <InputGroup>
                <Form.Control
                  placeholder="ট্র্যাকিং নাম্বার লিখুন "
                  value={trackReg}
                  onChange={(e) => setTrackReg(e.target.value)}
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  id="basic-addon1"
                  onBlur={onBlur}
                />
                <InputGroup.Text
                  onClick={!isLoadingTrack && !isFetching ? onSubmit : null} // Disable onClick when loading
                  // className={` ${
                  //   isLoadingTrack || isFetching ? "disabled" : "cursor-pointer"
                  // }`} // Add 'disabled' style
                  style={{
                    width: "100px",
                    cursor:
                      isLoadingTrack || isFetching ? "not-allowed" : "pointer",
                  }} // Adjust cursor style
                >
                  <label
                    htmlFor="basic-addon1"
                    style={{
                      cursor:
                        isLoadingTrack || isFetching
                          ? "not-allowed"
                          : "pointer",
                    }}
                  >
                    {isLoadingTrack || isFetching ? (
                      <Spinner
                        size="sm"
                        className="mx-3"
                        style={{
                          margin: "2px 0",
                        }}
                      />
                    ) : (
                      <>
                        <Search /> খুঁজুন
                      </>
                    )}
                  </label>
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
        </div>
        <Divider />

        {/* ─── Tracking Status Display ────────────────────────────────────────── */}
        <div className="py-3 mx-3">
          {data && !isError ? (
            <>
              <Row className="timeline">
                {/* ─── Step 1: Application Submitted ───────────────────────────── */}
                <Col md={12} className="mb-4">
                  <Card className="shadow-sm h-100 tackIng3">
                    <Card.Body className="d-flex justify-content-between align-items-center">
                      <label>আবেদন সম্পন্ন</label>
                      <CheckCircle className="text-success" />
                    </Card.Body>
                  </Card>
                </Col>
                {/* ───────────────────────────────────────────── Step 1 End ────── */}

                {/* ─── Step 2: Application Verification ───────────────────────── */}
                {![6, 19, 25].includes(Number(data?.fileStatus)) &&
                !data?.processId ? (
                  <>
                    <Col md={12} className="mb-4">
                      <Card className="shadow-sm h-100 tackIng">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                          <label>আবেদনপত্র পরীক্ষাকরণ</label>
                          <MoreHoriz className="text-secondary" />
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                ) : ![6, 19, 25].includes(Number(data?.fileStatus)) &&
                  data?.processId ? (
                  <>
                    <Col md={12} className="mb-4">
                      <Card className="shadow-sm h-100 tackIng2">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                          <label>আবেদনপত্র প্রক্রিয়াধীন</label>
                          <Badge className="text-dark" bg="warning">
                            {data?.admin?.role?.bn_name}
                          </Badge>
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col md={12} className="mb-4">
                      <Card className="shadow-sm h-100 tackIng3">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                          <label>আবেদনপত্র প্রক্রিয়াকরণ সম্পন্ন</label>
                          <CheckCircle className="text-success" />
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                )}
                {/* ───────────────────────────────────────────── Step 2 End ────── */}

                {/* ─── Step 3: Application in Progress ─────────────────────────── */}
                {![5, 6, 8, 19, 25].includes(Number(data?.fileStatus)) && (
                  <Col md={12}>
                    <Card className="shadow-sm h-100 tackIng">
                      <Card.Body className="d-flex justify-content-between align-items-center">
                        <label>
                          {Number(data?.apptype) === 1
                            ? "সনদ প্রদান"
                            : "চুক্তিপত্র এন্ড্রোস"}
                        </label>
                        <MoreHoriz className="text-secondary" />
                      </Card.Body>
                    </Card>
                  </Col>
                )}

                {[5, 8].includes(Number(data?.fileStatus)) && (
                  <Col md={12}>
                    <Card className="shadow-sm h-100 tackIng3">
                      <Card.Body className="d-flex justify-content-between align-items-center">
                        <label>
                          {Number(data?.apptype) === 1
                            ? "সনদ প্রক্রিয়াধীন"
                            : " এন্ড্রোস প্রক্রিয়াধীন"}
                        </label>
                        <Badge className="text-dark" bg="warning">
                          {data?.admin?.role?.bn_name}
                        </Badge>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {[6, 19].includes(Number(data?.fileStatus)) && (
                  <Col md={12}>
                    <Card className="shadow-sm h-100 tackIng3">
                      <Card.Body className="d-flex justify-content-between align-items-center">
                        <label>
                          {[6].includes(Number(data?.fileStatus)) ? (
                            "আননুমোদিত"
                          ) : [19].includes(Number(data?.fileStatus)) ? (
                            "নথিজাত"
                          ) : (
                            <></>
                          )}
                        </label>
                        <CheckCircle className="text-success" />
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {[25].includes(Number(data?.fileStatus)) &&
                  (data?.archive &&
                  Number(data?.archive?.status) === 1 &&
                  data?.archive?.certificate_canceled ? (
                    <Col md={12}>
                      <Card className="shadow-sm h-100 tackIng3">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                          <label>
                            {Number(data?.apptype) === 1
                              ? "সনদ প্রদান"
                              : "চুক্তিপত্র এন্ড্রোস"}
                          </label>
                          <CheckCircle className="text-success" />
                        </Card.Body>
                      </Card>
                    </Col>
                  ) : (
                    <Col md={12}>
                      <Card className="shadow-sm h-100 tackIng3">
                        <Card.Body className="d-flex justify-content-between align-items-center">
                          <label>
                            {Number(data?.apptype) === 1
                              ? "সনদ বাতিল"
                              : "এন্ড্রোস বাতিল"}
                          </label>
                          <CheckCircle className="text-success" />
                          <Badge bg="danger">বাতিল</Badge>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                <Col md={12} className=" mb-2 mt-3">
                  <div className="d-flex gap-3 mt-2 px-3">
                    <div className="d-flex gap-3 align-items-center">
                      <div className="circle bg-secondary"></div>
                      <label htmlFor="">শুরু হয়নি</label>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <div className="circle bg-warning"></div>
                      <label htmlFor="">প্রক্রিয়া চলছে</label>
                    </div>
                    <div className="d-flex gap-3 align-items-center">
                      <div className="circle bg-success"></div>
                      <label htmlFor="">সম্পন্ন হয়েছে</label>
                    </div>
                  </div>
                </Col>

                {/* ───────────────────────────────────────────── Step 3 End ────── */}
              </Row>
            </>
          ) : (
            (isError || ee) && (
              <Alert variant="danger" className="m-0">
                {isError || !ee
                  ? "কোনো রেকর্ড পাওয়া যায়নি।"
                  : "ট্র্যাকিং নাম্বার দিয়ে ফাইল খুঁজুন।"}
              </Alert>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ApplicantApplicationsTrack;
