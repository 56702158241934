import { Search } from "@mui/icons-material";
import { Box, InputBase, Typography } from "@mui/material";
import React, { memo } from "react";
import { Table } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import { BeatLoader } from "react-spinners";
import { convertToBanglaNumerals } from "../../../utils/convertToBanglaNumerals";
import "./table_styles.css";

const ReusableTable = ({
  columns,
  data,
  isLoading,
  currentPage,
  lastPage,
  totalData,
  onSearchChange,
  searchText,
  onPageChange,
  title,
}) => {
  const generatePaginationItems = () => {
    let items = [];
    let startPage, endPage;
    if (lastPage <= 5) {
      startPage = 1;
      endPage = lastPage;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 2 >= lastPage) {
        startPage = lastPage - 4;
        endPage = lastPage;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => onPageChange(number)}
        >
          {convertToBanglaNumerals(number)}
        </Pagination.Item>
      );
    }
    return items;
  };

  return (
    <div className="slideUp">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography fontWeight="bold">{title}</Typography>
        {onSearchChange && (
          <Box display="flex" alignItems="center">
            <Search />
            <InputBase
              placeholder="Search…"
              inputProps={{ "aria-label": "search" }}
              value={searchText}
              onChange={(e) => onSearchChange(e.target.value)}
              sx={{ ml: 1, flex: 1 }}
            />
          </Box>
        )}
      </Box>
      {isLoading ? (
        <div className="text-center py-4">
          <BeatLoader />
        </div>
      ) : (
        <div className="slideUp">
          <Box minHeight={"40vh"}>
            <Table bordered hover responsive>
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th
                      key={column.name}
                      className="py-2"
                      style={{ backgroundColor: "#f5f5f5" }}
                    >
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.length <= 0 ? (
                  <tr className="text-center">
                    <td colSpan={columns.length}>No data found</td>
                  </tr>
                ) : (
                  <>
                    {data?.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {columns.map((column, colIndex) => {
                          const value = row[column.name];
                          if (
                            column.options &&
                            column.options.customBodyRender
                          ) {
                            return (
                              <td
                                key={colIndex}
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                {column.options.customBodyRender(value, {
                                  rowData: row,
                                })}
                              </td>
                            );
                          }
                          return (
                            <td
                              key={colIndex}
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              {value}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </Table>
          </Box>
        </div>
      )}
      {data?.length > 0 && (
        <div className="d-flex justify-content-between align-items-center gap-3 flex-wrap">
          <div className="d-flex justify-content-between align-items-center gap-3">
            <Typography sx={{ fontSize: "14px" }}>
              মোট সংখ্যা: {convertToBanglaNumerals(totalData)}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              পৃষ্ঠা নাম্বার: {convertToBanglaNumerals(currentPage)}
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              মোট পৃষ্ঠ: {convertToBanglaNumerals(lastPage)} টি
            </Typography>
          </div>
          <Pagination className="m-0">
            <Pagination.First
              disabled={currentPage === 1}
              onClick={() => onPageChange(1)}
            />
            <Pagination.Prev
              disabled={currentPage === 1}
              onClick={() => onPageChange(currentPage - 1)}
            />
            {/* <Pagination.Ellipsis disabled /> */}
            {generatePaginationItems()}
            {/* <Pagination.Ellipsis disabled /> */}
            <Pagination.Next
              disabled={currentPage === lastPage}
              onClick={() => onPageChange(currentPage + 1)}
            />
            <Pagination.Last
              disabled={currentPage === lastPage}
              onClick={() => onPageChange(lastPage)}
            />
          </Pagination>
        </div>
      )}
    </div>
  );
};

export default memo(ReusableTable);
