import {
  AdjustOutlined,
  Copyright,
  DashboardOutlined,
  Handshake,
  Home,
  Notes,
  Numbers,
  PendingOutlined,
  RefreshOutlined,
} from "@mui/icons-material";
import { Card, Grid } from "@mui/material";
import React, { useState } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import CalendarOfHearings from "../../components/CalendarOfHearings";
import IconBreadcrumbs from "../../components/custom/IconBreadcrumbs";
import { useAuthorized } from "../../hooks/useAuthorized";
import {
  useGetCertificateEndorseCountGroupCategoryQuery,
  useGetCurrentActivitiesQuery,
  useGetDashboardApplicationReportQuery,
  useGetStatisticsAtAGlanceQuery,
} from "../../redux/api/dashboardApiSlice";
import { useGetActivityCountHistoryQuery } from "../../redux/api/reportApiSlice";
import { theme } from "../../theme";
import { accessRoles } from "../../utils/accessRoles";
import { convertToBanglaNumerals } from "../../utils/convertToBanglaNumerals";

const Dashboard = () => {
  const { hasRole } = useAuthorized();
  const breadcrumbsData = [
    { icon: Home, title: "হোম" },
    { icon: DashboardOutlined, title: "ড্যাশবোর্ড", active: true },
  ];

  const { data, isLoading, refetch, isFetching } =
    useGetActivityCountHistoryQuery({});

  const [date, setDate] = useState("");

  // First call: with only the date parameter
  const {
    data: dateData,
    error: dateError,
    isLoading: isDateLoading,
    refetch: dateRefetch,
    isFetching: isFilterFetching,
  } = useGetDashboardApplicationReportQuery({ date, filter: "" });

  // Second call: with both filter and date
  const {
    data: filteredData,
    error: filterError,
    isLoading: isFilterLoading,
    refetch: filterRefetch,
    isFetching: isFilterDateFetching,
  } = useGetDashboardApplicationReportQuery({ date, filter: "month" });

  const {
    data: currentActivitiesData,
    error: currentActivitiesError,
    isLoading: isCurrentActivitiesLoading,
    refetch: currentActivitiesRefetch,
    isFetching: isCurrentActivitiesFetching,
  } = useGetCurrentActivitiesQuery();

  const {
    data: certificateEndorseCountData,
    error: certificateEndorseCountError,
    isLoading: isCertificateEndorseCountLoading,
    refetch: certificateEndorseCountRefetch,
    isFetching: isCertificateEndorseCountFetching,
  } = useGetCertificateEndorseCountGroupCategoryQuery();

  const {
    data: statisticsData,
    error: statisticsError,
    isLoading: isStatisticsLoading,
    refetch: statisticsRefetch,
    isFetching: isStatisticsFetching,
  } = useGetStatisticsAtAGlanceQuery();
  const statistics = statisticsData?.data;

  const ajkerKarjokrom =
    data?.data?.total?.sent_files +
    data?.data?.total?.received_files +
    data?.data?.total?.draft_files;

  return (
    <>
      <div className="py-3">
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </div>
      <Grid container spacing={1.5}>
        <Grid item xs={12} md={6}>
          <div className=" slideUp h-100">
            <Card
              sx={{
                boxShadow: theme.palette.boxShadow,
                padding: "10px",
                borderRadius: "10px",
                position: "relative",
                width: "100%",
                height: "100%",
                bgcolor: "#ffffff",
              }}
            >
              {isLoading || isFetching ? (
                <div className="text-center">
                  <BeatLoader />
                </div>
              ) : (
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-1">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <h6 className="fw-bold">
                              <Notes sx={{ fontSize: "18px" }} /> আজকের
                              কার্যক্রম
                            </h6>
                            <small>
                              <AdjustOutlined
                                sx={{
                                  fontSize: "17px",
                                  mr: "7px",
                                }}
                              />{" "}
                              মোট কার্যক্রম -{" "}
                              {convertToBanglaNumerals(ajkerKarjokrom)} টি
                            </small>
                          </div>
                          <div>
                            <Button size="sm" onClick={() => refetch()}>
                              <RefreshOutlined />
                            </Button>
                          </div>
                        </div>
                        <ListGroup as="ol">
                          <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                          >
                            <div className="ms-2 me-auto">
                              <label>প্রেরিত নথি</label>
                            </div>
                            <div>
                              <Numbers
                                sx={{
                                  fontSize: "17px",
                                  mr: "10px",
                                }}
                              />{" "}
                              {convertToBanglaNumerals(
                                data?.data?.total?.sent_files
                              )}{" "}
                              টি
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                          >
                            <div className="ms-2 me-auto">
                              <label>প্রাপ্ত নথি</label>
                            </div>
                            <div>
                              <Numbers
                                sx={{
                                  fontSize: "17px",
                                  mr: "10px",
                                }}
                              />{" "}
                              {convertToBanglaNumerals(
                                data?.data?.total?.received_files
                              )}{" "}
                              টি
                            </div>
                          </ListGroup.Item>

                          <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                          >
                            <div className="ms-2 me-auto">
                              <label>সংরক্ষিত নথি</label>
                            </div>
                            <div>
                              <Numbers
                                sx={{
                                  fontSize: "17px",
                                  mr: "10px",
                                }}
                              />{" "}
                              {convertToBanglaNumerals(
                                data?.data?.total?.draft_files
                              )}{" "}
                              টি
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className=" slideUp h-100">
            <Card
              sx={{
                boxShadow: theme.palette.boxShadow,
                padding: "10px",
                borderRadius: "10px",
                position: "relative",
                width: "100%",
                height: "100%",
                bgcolor: "#ffffff",
              }}
            >
              {isDateLoading || isFilterFetching ? (
                <div className="text-center">
                  <BeatLoader />
                </div>
              ) : (
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-1">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <h6 className="fw-bold">
                              <Notes sx={{ fontSize: "18px" }} /> আজকের আবেদন
                            </h6>
                            <small>
                              <AdjustOutlined
                                sx={{
                                  fontSize: "17px",
                                  mr: "7px",
                                }}
                              />{" "}
                              মোট আবেদন -{" "}
                              {convertToBanglaNumerals(
                                dateData?.data?.grandTotalApplications
                              )}{" "}
                              টি
                            </small>
                            <small>
                              {" "}
                              <PendingOutlined
                                sx={{
                                  fontSize: "17px",
                                  mx: "7px",
                                }}
                              />{" "}
                              মোট শাখা -{" "}
                              {convertToBanglaNumerals(
                                dateData?.data?.categoriesData?.length
                              )}{" "}
                              টি
                            </small>
                          </div>
                          <div>
                            <Button size="sm" onClick={() => dateRefetch()}>
                              <RefreshOutlined />
                            </Button>
                          </div>
                        </div>
                        <ListGroup as="ol">
                          {Array.isArray(dateData?.data?.categoriesData) &&
                            dateData.data.categoriesData.map((item) => (
                              <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                                key={item?.id || item?.categoryName} // Add a unique key
                              >
                                <div className="ms-2 me-auto">
                                  <label>{item?.name}</label>
                                  <div>
                                    মোট{" "}
                                    {convertToBanglaNumerals(
                                      item?.totalApplications
                                    )}{" "}
                                    টি
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <Copyright
                                      sx={{
                                        fontSize: "17px",
                                        mr: "10px",
                                      }}
                                    />
                                    {convertToBanglaNumerals(
                                      item?.totalCopyright
                                    )}{" "}
                                    টি
                                  </div>
                                  <div>
                                    <Handshake
                                      sx={{
                                        fontSize: "17px",
                                        mr: "10px",
                                      }}
                                    />
                                    {convertToBanglaNumerals(
                                      item?.totalAgreement
                                    )}{" "}
                                    টি
                                  </div>
                                </div>
                              </ListGroup.Item>
                            ))}
                        </ListGroup>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className=" slideUp h-100">
            <Card
              sx={{
                boxShadow: theme.palette.boxShadow,
                padding: "10px",
                borderRadius: "10px",
                position: "relative",
                width: "100%",
                height: "100%",
                bgcolor: "#ffffff",
              }}
            >
              {isFilterLoading || isFilterDateFetching ? (
                <div className="text-center">
                  <BeatLoader />
                </div>
              ) : (
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-1">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <h6 className="fw-bold">
                              <Notes sx={{ fontSize: "18px" }} /> এ মাসের আবেদন
                            </h6>
                            <small>
                              <AdjustOutlined
                                sx={{
                                  fontSize: "17px",
                                  mr: "7px",
                                }}
                              />{" "}
                              মোট আবেদন -{" "}
                              {convertToBanglaNumerals(
                                filteredData?.data?.grandTotalApplications
                              )}{" "}
                              টি{" ,"}
                            </small>
                            <small>
                              <PendingOutlined
                                sx={{
                                  fontSize: "17px",
                                  mx: "7px",
                                }}
                              />{" "}
                              মোট শাখা -{" "}
                              {convertToBanglaNumerals(
                                filteredData?.data?.categoriesData?.length
                              )}{" "}
                              টি
                            </small>
                          </div>
                          <div>
                            <Button size="sm" onClick={() => filterRefetch()}>
                              <RefreshOutlined />
                            </Button>
                          </div>
                        </div>
                        <ListGroup as="ol">
                          {Array.isArray(filteredData?.data?.categoriesData) &&
                            filteredData.data.categoriesData.map((item) => (
                              <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                                key={item?.id || item?.categoryName} // Add a unique key
                              >
                                <div className="ms-2 me-auto">
                                  <label>{item?.name}</label>
                                  <div>
                                    মোট{" "}
                                    {convertToBanglaNumerals(
                                      item?.totalApplications
                                    )}{" "}
                                    টি
                                  </div>
                                </div>
                                <div>
                                  <div>
                                    <Copyright
                                      sx={{
                                        fontSize: "17px",
                                        mr: "10px",
                                      }}
                                    />
                                    {convertToBanglaNumerals(
                                      item?.totalCopyright
                                    )}{" "}
                                    টি
                                  </div>
                                  <div>
                                    <Handshake
                                      sx={{
                                        fontSize: "17px",
                                        mr: "10px",
                                      }}
                                    />
                                    {convertToBanglaNumerals(
                                      item?.totalAgreement
                                    )}{" "}
                                    টি
                                  </div>
                                </div>
                              </ListGroup.Item>
                            ))}
                        </ListGroup>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className=" slideUp h-100">
            <Card
              sx={{
                boxShadow: theme.palette.boxShadow,
                padding: "10px",
                borderRadius: "10px",
                position: "relative",
                width: "100%",
                height: "100%",
                bgcolor: "#ffffff",
              }}
            >
              {isCertificateEndorseCountFetching ||
              isCertificateEndorseCountLoading ? (
                <div className="text-center">
                  <BeatLoader />
                </div>
              ) : (
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-1">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <h6 className="fw-bold">
                              <Notes sx={{ fontSize: "18px" }} /> এ মাসের
                              সার্টিফিকেট প্রদান
                            </h6>
                            <small>
                              <AdjustOutlined
                                sx={{
                                  fontSize: "17px",
                                  mr: "7px",
                                }}
                              />{" "}
                              মোট আবেদন -{" "}
                              {convertToBanglaNumerals(
                                certificateEndorseCountData?.data
                                  ?.grandTotalCertificateEndorse
                              )}{" "}
                              টি
                            </small>
                          </div>
                          <div>
                            <Button
                              size="sm"
                              onClick={() => certificateEndorseCountRefetch()}
                            >
                              <RefreshOutlined />
                            </Button>
                          </div>
                        </div>
                        <ListGroup as="ol">
                          <ListGroup as="ol">
                            {Array.isArray(
                              certificateEndorseCountData?.data?.categoriesData
                            ) &&
                              certificateEndorseCountData.data.categoriesData.map(
                                (item) => (
                                  <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                    key={item?.id || item?.categoryName} // Add a unique key
                                  >
                                    <div className="ms-2 me-auto">
                                      <label>{item?.name}</label>
                                      <div>
                                        মোট{" "}
                                        {convertToBanglaNumerals(
                                          item?.totalCertificateEndorse
                                        )}{" "}
                                        টি
                                      </div>
                                    </div>
                                    <div>
                                      <div>
                                        <Copyright
                                          sx={{
                                            fontSize: "17px",
                                            mr: "10px",
                                          }}
                                        />
                                        {convertToBanglaNumerals(
                                          item?.totalCertificate
                                        )}{" "}
                                        টি
                                      </div>
                                      <div>
                                        <Handshake
                                          sx={{
                                            fontSize: "17px",
                                            mr: "10px",
                                          }}
                                        />
                                        {convertToBanglaNumerals(
                                          item?.totalEndorse
                                        )}{" "}
                                        টি
                                      </div>
                                    </div>
                                  </ListGroup.Item>
                                )
                              )}
                          </ListGroup>
                        </ListGroup>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className=" slideUp h-100">
            <Card
              sx={{
                boxShadow: theme.palette.boxShadow,
                padding: "10px",
                borderRadius: "10px",
                position: "relative",
                width: "100%",
                height: "100%",
                bgcolor: "#ffffff",
              }}
            >
              {isCurrentActivitiesLoading || isCurrentActivitiesFetching ? (
                <div className="text-center">
                  <BeatLoader />
                </div>
              ) : (
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-1">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <h6 className="fw-bold">
                              <Notes sx={{ fontSize: "18px" }} /> বর্তমান
                              কার্যক্রম
                            </h6>
                            <small>
                              <AdjustOutlined
                                sx={{
                                  fontSize: "17px",
                                  mr: "7px",
                                }}
                              />{" "}
                              মোট আবেদন -{" "}
                              {convertToBanglaNumerals(
                                currentActivitiesData?.data
                                  ?.grandTotalApplications
                              )}{" "}
                              টি
                            </small>
                          </div>
                          <div>
                            <Button
                              size="sm"
                              onClick={() => currentActivitiesRefetch()}
                            >
                              <RefreshOutlined />
                            </Button>
                          </div>
                        </div>
                        <ListGroup as="ol">
                          {Array.isArray(
                            currentActivitiesData?.data?.rolesData
                          ) &&
                            currentActivitiesData.data.rolesData.map((item) => (
                              <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                                key={item?.roleName} // Add a unique key
                              >
                                <div className="ms-2 me-auto">
                                  <label>{item?.roleName}</label>
                                </div>
                                <div>
                                  <div>
                                    <Numbers
                                      sx={{
                                        fontSize: "17px",
                                        mr: "10px",
                                      }}
                                    />
                                    {convertToBanglaNumerals(
                                      item?.totalApplications
                                    )}{" "}
                                    টি
                                  </div>
                                </div>
                              </ListGroup.Item>
                            ))}
                        </ListGroup>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className=" slideUp h-100">
            <Card
              sx={{
                boxShadow: theme.palette.boxShadow,
                padding: "10px",
                borderRadius: "10px",
                position: "relative",
                width: "100%",
                height: "100%",
                bgcolor: "#ffffff",
              }}
            >
              {isStatisticsFetching || isStatisticsLoading ? (
                <div className="text-center">
                  <BeatLoader />
                </div>
              ) : (
                <div className="p-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-1">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <div>
                            <h6 className="fw-bold">
                              <Notes sx={{ fontSize: "18px" }} /> পরিসংখ্যান
                            </h6>
                            <small>
                              <AdjustOutlined
                                sx={{
                                  fontSize: "17px",
                                  mr: "7px",
                                }}
                              />{" "}
                              মোট আবেদন -{" "}
                              {convertToBanglaNumerals(
                                statistics?.[0]?.grandTotal
                              )}{" "}
                              টি
                            </small>
                          </div>
                          <div>
                            <Button
                              size="sm"
                              onClick={() => statisticsRefetch()}
                            >
                              <RefreshOutlined />
                            </Button>
                          </div>
                        </div>
                        <ListGroup as="ol">
                          <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                          >
                            <div className="ms-2 me-auto">
                              <label>প্রক্রিয়াধীন </label>
                            </div>
                            <div>
                              <div>
                                <Numbers
                                  sx={{
                                    fontSize: "17px",
                                    mr: "10px",
                                  }}
                                />{" "}
                                {convertToBanglaNumerals(
                                  statistics?.[0]?.totalProcessing
                                )}{" "}
                                টি
                              </div>
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                          >
                            <div className="ms-2 me-auto">
                              <label>চূরান্তভাবে অনুমোদিত </label>
                            </div>
                            <div>
                              <div>
                                <Numbers
                                  sx={{
                                    fontSize: "17px",
                                    mr: "10px",
                                  }}
                                />{" "}
                                {convertToBanglaNumerals(
                                  statistics?.[0]?.totalAuthorized
                                )}{" "}
                                টি
                              </div>
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                          >
                            <div className="ms-2 me-auto">
                              <label>চূরান্তভাবে অননুমোদিত </label>
                            </div>
                            <div>
                              <div>
                                <Numbers
                                  sx={{
                                    fontSize: "17px",
                                    mr: "10px",
                                  }}
                                />{" "}
                                {convertToBanglaNumerals(
                                  statistics?.[0]?.totalUnauthorized
                                )}{" "}
                                টি
                              </div>
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                          >
                            <div className="ms-2 me-auto">
                              <label>সার্টিফিকেট প্রদান</label>
                            </div>
                            <div>
                              <div>
                                <Numbers
                                  sx={{
                                    fontSize: "17px",
                                    mr: "10px",
                                  }}
                                />{" "}
                                {convertToBanglaNumerals(
                                  statistics?.[0]?.totalCertEndsIssued
                                )}{" "}
                                টি
                              </div>
                            </div>
                          </ListGroup.Item>
                          <ListGroup.Item
                            as="li"
                            className="d-flex justify-content-between align-items-start"
                          >
                            <div className="ms-2 me-auto">
                              <label>নথিজাত</label>
                            </div>
                            <div>
                              <div>
                                <Numbers
                                  sx={{
                                    fontSize: "17px",
                                    mr: "10px",
                                  }}
                                />{" "}
                                {convertToBanglaNumerals(
                                  statistics?.[0]?.totalRecorded
                                )}{" "}
                                টি
                              </div>
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Card>
          </div>
        </Grid>
        {hasRole([
          accessRoles.CopyrightAdmin,
          accessRoles.Register,
          accessRoles.DeputyRegister,
        ]) && (
          <Grid item xs={12}>
            <div className=" slideUp h-100">
              <Card
                sx={{
                  boxShadow: theme.palette.boxShadow,
                  padding: "10px",
                  borderRadius: "10px",
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  bgcolor: "#ffffff",
                }}
              >
                <CalendarOfHearings />
              </Card>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Dashboard;
