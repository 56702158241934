import { Copyright, Handshake, Print, RestartAlt } from "@mui/icons-material";
import { Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Select from "react-select";
import Loader from "../../../../components/custom/Loader";
import { useGetCategoriesQuery } from "../../../../redux/api/categoryApiSlice";
import { globalDateFormat } from "../../../../utils/dateFormat";
import BanglaCalender from "../../../frontend/userPanel/form_two/BanglaCalender";
const CommonAgreementCopyright = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  category_id,
  setCategoryId,
  setSubCategory,
  applicationPath,
  handlePrint,
}) => {
  const location = useLocation();
  const currentDate = moment().format("YYYY-MM-DD"); // Current date
  const threeMonthsAgo = moment().subtract(3, "months").format("YYYY-MM-DD");
  // category
  const { data: categoryData, isLoading: isLoadingCategory } =
    useGetCategoriesQuery();

  if (isLoadingCategory) return <Loader />;

  const categoryOption = categoryData?.data?.map((data) => ({
    value: data.id,
    label:
      data.name +
      (location.pathname === applicationPath.agreement
        ? " বিষয়ক চুক্তিপত্র"
        : ""),
  }));

  const handleDateFromChange = (d) => {
    setFromDate(globalDateFormat(d));
  };
  const handleDateToChange = (d) => {
    setToDate(globalDateFormat(d));
  };

  const handleReset = () => {
    setFromDate(threeMonthsAgo);
    setToDate(currentDate);
    setCategoryId("");
    setSubCategory("");
  };

  return (
    <>
      <div>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
          className="rounded mb-3 p-2"
        >
          <div className="row gap-2 gap-md-0">
            <div className="col-md-7 m-0">
              <div className="d-flex gap-4 align-items-center">
                <BanglaCalender
                  placeholder={"তারিখ"}
                  value={fromDate}
                  onChange={handleDateFromChange}
                />
                <span>হইতে</span>
                <BanglaCalender
                  placeholder={"তারিখ"}
                  value={toDate}
                  onChange={handleDateToChange}
                />
              </div>
            </div>
            <div className="col-md-3 m-0">
              <Select
                options={categoryOption}
                value={
                  category_id
                    ? categoryOption.find(
                        (option) => option.value === category_id
                      )
                    : { value: "", label: "--- বিভাগ ---", isDisabled: true }
                }
                onChange={(selectedOption) => {
                  setSubCategory("");
                  setCategoryId(selectedOption.value);
                }}
                classNamePrefix="react-select"
              />
            </div>

            <div className="col-md-2 m-0">
              <div className=" d-flex justify-content-end">
                <Button
                  onClick={handleReset}
                  variant="danger"
                  className="w-100 text-center"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <RestartAlt /> Reset
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex gap-3 mb-3 rounded justify-content-between align-items-center"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
        >
          <div>
            <Link to={applicationPath.copyright}>
              <Typography
                fontWeight="bold"
                className={`${
                  location.pathname === applicationPath.copyright &&
                  "text-dark  bg-success bg-opacity-10"
                } p-2  d-flex align-items-center gap-2`}
              >
                <Copyright /> কপিরাইট{" "}
                {/* <div>
                  <Badge>{convertToBanglaNumerals(total.copyright)}</Badge>
                </div> */}
              </Typography>
            </Link>
            <Link to={applicationPath.agreement}>
              <Typography
                fontWeight="bold"
                className={`${
                  location.pathname === applicationPath.agreement &&
                  "text-dark  bg-success bg-opacity-10"
                } p-2 d-flex align-items-center gap-2`}
              >
                <Handshake /> চুক্তিপত্র{" "}
                {/* <div>
                  <Badge>{convertToBanglaNumerals(total.agreement)}</Badge>
                </div> */}
              </Typography>
            </Link>
          </div>
          <div className="mx-1">
            <Button
              size="sm"
              variant="success"
              className="w-100  px-4"
              onClick={handlePrint}
            >
              <Print />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonAgreementCopyright;
