import { Handshake, Home } from "@mui/icons-material";
import debounce from "lodash/debounce";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import ReusableTable from "../../../../components/custom/data_table/ReusableTable";
import IconBreadcrumbs from "../../../../components/custom/IconBreadcrumbs";
import {
  useGetCopyrightReportsQuery,
  useLazyGetCopyrightReportsPrintQuery,
} from "../../../../redux/api/reportApiSlice";
import { localDateFormat } from "../../../../utils/dateFormat";
import CommonAgreementCopyright from "./CommonAgreementCopyright";
import PreviewAndPrint from "./PreviewAndPrint";

// Static column configuration outside component for readability
const columns = [
  { label: "তারিখ", name: "sendDate" },
  { label: "আবেদন নং", name: "trackReg" },
  { label: "আবেদনকারির নাম", name: "applicant" },
  { label: "শিরোনাম", name: "workTitle" },
  { label: "বিভাগ", name: "category" },
  { label: "উপবিভাগ", name: "subCategory" },
];

const breadcrumbsData = [
  { icon: Home, title: "হোম", url: "/" },
  { icon: Handshake, title: "কপিরাইট - প্রতিবেদন", active: true },
];

const CopyrightReport = () => {
  const currentDate = moment().format("YYYY-MM-DD");
  const threeMonthsAgo = moment().subtract(2, "months").format("YYYY-MM-DD");

  const [fromDate, setFromDate] = useState(threeMonthsAgo);
  const [toDate, setToDate] = useState(currentDate);
  const [category_id, setCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [contentReady, setContentReady] = useState(false);
  const printRef = useRef(null);

  const { data, isLoading, refetch } = useGetCopyrightReportsQuery({
    fromDate,
    toDate,
    category_id,
    page,
    searchValue,
  });

  const [getPrintData, { data: dataPrint, isLoading: isLoadingPrint }] =
    useLazyGetCopyrightReportsPrintQuery();

  const totalData = data?.meta?.total;
  const lastPage = data?.meta?.last_page;

  /// copyright application length
  const { data: copyright } = useGetCopyrightReportsQuery();
  /// agreement application length
  const { data: agreement } = useGetCopyrightReportsQuery();

  const total = {
    copyright: copyright?.meta?.total ?? 0,
    agreement: agreement?.meta?.total ?? 0,
  };

  const handlePrint = useReactToPrint({
    documentTitle: "Print application",
    content: () => printRef.current,
  });

  const initiatePrint = () => {
    setContentReady(false); // Reset the content ready state

    getPrintData({
      fromDate,
      toDate,
    }).then(() => {
      // Trigger print only when the content is ready
      if (contentReady) {
        handlePrint();
      }
    });
  };

  const handleContentReady = useCallback(() => {
    setContentReady(true);
    if (printRef.current) {
      handlePrint(); // Trigger print once content is ready
    }
  }, []);

  const debouncedSearch = useCallback(
    debounce((value) => {
      setPage(1);
      setSearchValue(value);
      refetch();
    }, 400),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearch?.cancel();
    };
  }, [debouncedSearch]);

  const handlePageChange = (page) => {
    setPage(page);
    refetch();
  };

  const dataTable = data?.data.map((item) => ({
    sendDate: localDateFormat(item?.sendDate),
    trackReg: item?.trackReg,
    applicant: item?.applicantName,
    workTitle: item?.profileDetail?.workTitle,
    category: item?.profileDetail?.category?.name,
    subCategory: item?.profileDetail?.subCategory?.name,
  }));

  const applicationPath = {
    agreement: "/admin/agreement-report",
    copyright: "/admin/copyright-report",
  };

  const pnt_data = dataPrint?.data.map((item) => ({
    sendDate: localDateFormat(item?.sendDate),
    trackReg: item?.trackReg,
    applicant: item?.applicantName,
    workTitle: item?.profileDetail?.workTitle,
    category: item?.profileDetail?.category?.name,
    subCategory: item?.profileDetail?.subCategory?.name,
  }));

  return (
    <div>
      <div className="py-3">
        <IconBreadcrumbs breadcrumbs={breadcrumbsData} />
      </div>
      <div className="slideUp dashboard-layout p-4">
        <div className="pb-1">
          <CommonAgreementCopyright
            total={total}
            handlePrint={initiatePrint}
            applicationPath={applicationPath}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            category_id={category_id}
            setCategoryId={setCategoryId}
            subCategory={subCategory}
            setSubCategory={setSubCategory}
          />
        </div>
        <ReusableTable
          columns={columns}
          data={dataTable}
          isLoading={isLoading}
          currentPage={page}
          lastPage={lastPage}
          totalData={totalData}
          onPageChange={handlePageChange}
        />
      </div>
      <div>
        <PreviewAndPrint
          data={pnt_data}
          isLoading={isLoadingPrint}
          onContentReady={handleContentReady}
          date={{
            fromDate,
            toDate,
            title: "কপিরাইট আবেদন",
          }}
          printRef={printRef}
        />
      </div>
    </div>
  );
};

export default CopyrightReport;
