import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap"; // Assuming you're using react-bootstrap
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import * as Yup from "yup";
import { useAuthUser } from "../hooks/useAuthUser";
import { useUpdateAdminProfileMutation } from "../redux/api/userApiSlice";
import { transformErrorsToObjectStructure } from "../utils/transformErrorsToObjectStructure";
import Loader from "./custom/Loader";

const AdminProfileData = () => {
  const { user: authUser, isLoading: isLoadingUser } = useAuthUser();
  const [userSubmit, { isError, isSuccess, error, data }] =
    useUpdateAdminProfileMutation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("নাম প্রযোজ্য")
      .max(255, "নামে সর্বাধিক 255 অক্ষর থাকতে পারে"),
    enname: Yup.string()
      .required("ইংরেজি নাম প্রযোজ্য")
      .max(255, "নামে সর্বাধিক 255 অক্ষর থাকতে পারে"),
    username: Yup.string()
      .required("ব্যবহারকারীর নাম প্রযোজ্য")
      .max(255, "ব্যবহারকারীর নাম সর্বাধিক 255 অক্ষর থাকতে পারে"),
    cell: Yup.string()
      .required("মোবাইল নাম্বার প্রযোজ্য")
      .max(15, "মোবাইল নাম্বার সর্বাধিক 15 অক্ষর থাকতে পারে"),
    signature: Yup.mixed()
      .nullable()
      .test(
        "fileType",
        "স্বাক্ষর অবশ্যই jpeg, png, jpg ধরনের হতে হবে",
        function (value) {
          if (value) {
            return ["image/jpeg", "image/png", "image/jpg"].includes(
              value.type
            );
          }
          return true; // If the value is null, return true to pass the validation
        }
      ),
  });

  const initialValues = {
    name: "",
    enname: "",
    username: "",
    cell: "",
    signature: "",
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formData = new FormData();
        formData.append("_method", "PATCH");
        Object.keys(values).forEach((key) => {
          if (values[key]) {
            formData.append(key, values[key]);
          }
        });
        setSubmitting(true);
        await userSubmit(formData);
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (isError) {
      setErrors(transformErrorsToObjectStructure(error?.data?.data || {}));
      toast.error(error?.data?.message);
    }
    if (isSuccess) {
      toast.success(data?.message);
    }
  }, [isError, isSuccess, error, data]);

  useEffect(() => {
    if (authUser) {
      setFieldValue("name", authUser.name);
      setFieldValue("enname", authUser.enname);
      setFieldValue("username", authUser.username);
      setFieldValue("cell", authUser.cell);
    }
  }, [authUser]);

  if (isLoadingUser) {
    return <Loader />;
  }
  return (
    <Form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6 mt-3">
          <Form.Group>
            <Form.Label>নাম</Form.Label>
            <Form.Control
              isInvalid={!!errors.name && touched.name}
              isValid={touched.name && !errors.name}
              type="text"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              name="name"
            />
            {errors.name && touched.name && (
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-md-6 mt-3">
          <Form.Group>
            <Form.Label>ইংরেজি নাম</Form.Label>
            <Form.Control
              isInvalid={!!errors.enname && touched.enname}
              isValid={touched.enname && !errors.enname}
              type="text"
              value={values.enname}
              onChange={handleChange}
              onBlur={handleBlur}
              name="enname"
            />
            {errors.enname && touched.enname && (
              <Form.Control.Feedback type="invalid">
                {errors.enname}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-md-6 mt-3">
          <Form.Group>
            <Form.Label>ব্যবহারকারীর নাম</Form.Label>
            <Form.Control
              isInvalid={!!errors.username && touched.username}
              isValid={touched.username && !errors.username}
              type="text"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              name="username"
            />
            {errors.username && touched.username && (
              <Form.Control.Feedback type="invalid">
                {errors.username}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-md-6 mt-3">
          <Form.Group>
            <Form.Label>মোবাইল নাম্বার</Form.Label>
            <Form.Control
              isInvalid={!!errors.cell && touched.cell}
              isValid={touched.cell && !errors.cell}
              type="text"
              value={values.cell}
              onChange={handleChange}
              onBlur={handleBlur}
              name="cell"
            />
            {errors.cell && touched.cell && (
              <Form.Control.Feedback type="invalid">
                {errors.cell}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-md-6 mt-3">
          <Form.Group>
            <Form.Label>স্বাক্ষর</Form.Label>
            <Form.Control
              isInvalid={!!errors.signature && touched.signature}
              isValid={touched.signature && !errors.signature}
              type="file"
              onChange={(event) =>
                setFieldValue("signature", event.currentTarget.files[0])
              }
              onBlur={handleBlur}
              name="signature"
            />
            {errors.signature && touched.signature && (
              <Form.Control.Feedback type="invalid">
                {errors.signature}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>
        <div className="col-md-12 mt-4">
          <div className="text-center">
            <Button
              type="submit"
              className="px-4"
              variant="success"
              disabled={isSubmitting}
            >
              {!isSubmitting ? "আপডেট করুন" : <BeatLoader color="#fff" />}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default AdminProfileData;
