import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { assets } from "../../../../assets";
import ApplicantApplicationsTrack from "../../../../components/ApplicantApplicationsTrack";
import { apiSlice } from "../../../../redux/api/apiSlice";
import { useLogoutMutation } from "../../../../redux/api/authApiSlice";
import { logout } from "../../../../redux/features/auth/authSlice";

export default function PanelHeader() {
  const publicUrl = process.env.PUBLIC_URL;
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [show, setShow] = useState(false);

  const handleNavLinkClick = () => {
    if (isNavbarOpen) {
      setIsNavbarOpen(false);
    }
  };

  const handleTogglerClick = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  // logout event
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutApiCall, { isSuccess, isLoading, data }] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      dispatch(apiSlice.util.resetApiState());
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.message);
    }
  }, [isSuccess]);

  return (
    <div>
      <header className="header navbar-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="nav-inner">
                <nav className="navbar navbar-expand-lg">
                  <Link className="navbar-brand" to="/">
                    <img className src={assets.logo} alt="Logo" />
                  </Link>

                  <button
                    className={`navbar-toggler ${isNavbarOpen ? "active" : ""}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded={isNavbarOpen}
                    aria-label="Toggle navigation"
                    onClick={handleTogglerClick}
                  >
                    <span className="toggler-icon" />
                    <span className="toggler-icon" />
                    <span className="toggler-icon" />
                  </button>

                  <div
                    className={`collapse navbar-collapse sub-menu-bar ${
                      isNavbarOpen ? "show" : ""
                    }`}
                    id="navbarSupportedContent"
                  >
                    <ul id="nav" className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          to="/user-profile"
                          onClick={handleNavLinkClick}
                        >
                          প্রোফাইল
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          to="/applicant"
                          onClick={handleNavLinkClick}
                        >
                          আবেদন সমুহ
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          type="button"
                          className="page-scroll"
                          onClick={() => setShow(true)}
                        >
                          আবেদন ট্রাকিং
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          to="/applicant/applications"
                          onClick={handleNavLinkClick}
                        >
                          কপিরাইটের আবেদন
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="page-scroll"
                          to="/agreement-form-add"
                          onClick={handleNavLinkClick}
                        >
                          চুক্তিপত্রের নিবন্ধন
                        </Link>
                      </li>
                      <li className="nav-item rounded py-3 userLogoutBtn">
                        <Link
                          onClick={logoutHandler}
                          className="btn white-bg mouse-dir"
                        >
                          লগ আউট
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="signin-btn">
                    <Link onClick={logoutHandler}>
                      {isLoading ? <BeatLoader color="#fff" /> : "লগ আউট"}
                      <span className="dir-part" />
                    </Link>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>

      {show && (
        <ApplicantApplicationsTrack
          show={show}
          onHide={() => {
            setShow(false);
          }}
        />
      )}
    </div>
  );
}
