import {
  AdjustOutlined,
  CalendarMonth,
  CalendarToday,
  RefreshOutlined,
} from "@mui/icons-material";
import moment from "moment";
import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Button, ListGroup, Modal } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import {
  useGetCalendarOfHearingsQuery,
  useLazyGetDateOfHearingQuery,
} from "../redux/api/reportApiSlice";
import { convertToBanglaNumerals } from "../utils/convertToBanglaNumerals";
import { localDateFormat, localTime } from "../utils/dateFormat";

const CalendarOfHearings = () => {
  const [date, setDate] = useState("");

  const { data, isLoading, isFetching, refetch } =
    useGetCalendarOfHearingsQuery(date);
  const localizer = momentLocalizer(moment);

  const [showHInfo, { isLoading: isLoadingH, data: dataH }] =
    useLazyGetDateOfHearingQuery();

  const [showModal, setShowModal] = useState(false);

  const myEventsList =
    data?.data?.map((item, i) => ({
      id: i,
      title: `মোট শুনানী: ${convertToBanglaNumerals(item.totalHearing)}`,
      start: new Date(item.hearingDate + "T09:00:00"),
      end: new Date(item.hearingDate + "T17:00:00"),
    })) || [];

  const totalHearing = data?.data?.reduce(
    (acc, item) => acc + item.totalHearing,
    0
  );

  const handleSelectEvent = (event) => {
    const d = event?.start
      ? moment(event.start).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");

    showHInfo(d);
    setShowModal(true);
  };

  const day = data?.data?.map((item) => {
    return new Date(item.hearingDate + "T17:00:00").getDate();
  });

  const customDayPropGetter = (date) => {
    if (day.includes(date.getDate()))
      return {
        style: {
          border: "solid 3px #afa",
        },
      };
    else return {};
  };

  const handleDateChange = (newDate) => {
    const formattedDate = moment(newDate).format("YYYY-MM-DD");
    setDate(formattedDate);
  };

  return (
    <div className="slideUp">
      {isLoading || isFetching ? (
        <div className="text-center">
          <BeatLoader />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <h6 className="fw-bold">
                <CalendarMonth sx={{ fontSize: "18px" }} /> শুনানীর ক্যালেন্ডার
              </h6>
              <small>
                <AdjustOutlined
                  sx={{
                    fontSize: "17px",
                    mr: "7px",
                  }}
                />{" "}
                মোট শুনানী - {convertToBanglaNumerals(totalHearing)} টি
              </small>
            </div>
            <div>
              <Button size="sm" onClick={() => refetch()}>
                <RefreshOutlined />
              </Button>
            </div>
          </div>
          <div>
            <Calendar
              localizer={localizer}
              events={myEventsList}
              startAccessor="start"
              endAccessor="end"
              style={{
                height: 500,
                width: "100%",
                borderRadius: "20px",
                border: "1px solid #eee",
                overflow: "hidden",
                backgroundColor: "#f9f9f9",
                padding: "20px",
                margin: "0 auto",
              }}
              onSelectEvent={handleSelectEvent}
              dayPropGetter={customDayPropGetter}
              onNavigate={(newDate) => handleDateChange(newDate)}
            />
          </div>
        </>
      )}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        {isLoadingH ? (
          <div className="text-center py-5 my-5">
            <BeatLoader />
          </div>
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                শুনানী তারিখঃ {localDateFormat(dataH?.data?.[0].hearingDate)}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ListGroup as="ol">
                {dataH?.data?.length > 0 ? (
                  dataH?.data.map((item, i) => (
                    <ListGroup.Item key={i} as="li">
                      <div>
                        <CalendarToday
                          sx={{
                            fontSize: "17px",
                            mr: "10px",
                          }}
                        />{" "}
                        <strong>সময়:</strong> {localTime(item?.hearingTime)}
                      </div>
                    </ListGroup.Item>
                  ))
                ) : (
                  <p>কোন তথ্য পাওয়া যায়নি</p>
                )}
              </ListGroup>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                বন্ধ
              </Button>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
};

export default CalendarOfHearings;
