import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import { BeatLoader } from "react-spinners";
import * as Yup from "yup";
import {
  useShowContactWebsiteSettingQuery,
  useUpdateContactWebsiteSettingMutation,
} from "../redux/api/websiteSettingsApiSlice";
import { transformErrorsToObjectStructure } from "../utils/transformErrorsToObjectStructure";
import Loader from "./custom/Loader";
import FormikFormField from "./custom/form/FormikFormField";

const initialValues = {
  phone: "",
  email: "",
  admin_email: "",
  fax: "",
  website: "",
  help_line: "",
  address: "",
};

const validationSchema = Yup.object().shape({
  phone: Yup.string().required("মোবাইল নাম্বার প্রয়োজন"),
  email: Yup.string().required("ই-মেইল প্রয়োজন"),
  admin_email: Yup.string().required("এডমিন ইমেইল প্রয়োজন"),
  fax: Yup.string().required("ফ্যাক্স প্রয়োজন"),
  website: Yup.string().required("ওয়েবসাইট প্রয়োজন"),
  help_line: Yup.string().required("হেল্প লাইন প্রয়োজন"),
  address: Yup.string().required("ঠিকানা প্রয়োজন"),
});

const ContactWebsiteSetting = () => {
  const { data, isLoading } = useShowContactWebsiteSettingQuery();

  const [
    UpdateContactWebsiteSetting,
    {
      isLoading: isLoadingUpdate,
      isError: isErrorUpdate,
      error: errorUpdate,
      isSuccess: isSuccessUpdate,
      data: dataUpdate,
    },
  ] = useUpdateContactWebsiteSettingMutation();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const formData = { ...values, _method: "PUT" };
      await UpdateContactWebsiteSetting(formData);
      setSubmitting(false);
    },
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    resetForm,
  } = formik;

  useEffect(() => {
    if (data?.data) {
      setValues(data.data);
    }
  }, [data?.data, setValues]);

  useEffect(() => {
    if (isErrorUpdate) {
      setErrors(
        transformErrorsToObjectStructure(errorUpdate?.data?.data || {})
      );
      toast.error(errorUpdate?.data?.message);
    }
    if (isSuccessUpdate) {
      toast.success(dataUpdate?.message);
    }
  }, [isErrorUpdate, isSuccessUpdate]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="row p-2">
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>মোবাইল নাম্বার</label>
            <FormikFormField
              placeholder="মোবাইল নাম্বার"
              value={values.phone}
              name="phone"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>ই-মেইল</label>
            <FormikFormField
              placeholder="ই-মেইল"
              value={values.email}
              name="email"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>এডমিন ই-মেইল</label>
            <FormikFormField
              placeholder="এডমিন ই-মেইল"
              value={values.admin_email}
              name="admin_email"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>ফ্যাক্স</label>
            <FormikFormField
              placeholder="ফ্যাক্স"
              value={values.fax}
              name="fax"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>হেল্প লাইন</label>
            <FormikFormField
              placeholder="হেল্প লাইন "
              value={values.help_line}
              name="help_line"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="form-group">
            <label>ওয়েব সাইট</label>
            <FormikFormField
              placeholder="ওয়েব সাইট "
              value={values.website}
              name="website"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="col-md-12 col-12">
          <div className="form-group">
            <label>ঠিকানা</label>
            <FormikFormField
              placeholder="ঠিকানা"
              value={values.address}
              name="address"
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              additional={{
                as: "textarea",
                rows: "5",
              }}
            />
          </div>
        </div>
      </div>
      <div className="text-center mb-2">
        <Button type="submit" disabled={isLoadingUpdate}>
          {isLoadingUpdate ? <BeatLoader color="#fff" /> : "আপডেট করুন"}
        </Button>
      </div>
    </Form>
  );
};

export default ContactWebsiteSetting;
