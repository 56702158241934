import React, { useState } from "react";
import styles from "../../../pages/frontend/userPanel/agreement/ContractCopy.module.css";
import ShowFilePreviewModal from "../../../pages/frontend/userPanel/form_two/ShowPreviewFiles";
import { useAuth } from "../../../utils/authorization";
import { localDateFormat } from "../../../utils/dateFormat";

const AgreementPreviewFormOne = ({ data }) => {
  const mainData = data;
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const handlePreview = (file) => {
    setPreviewFile(file);
    setPreviewModal(true);
  };
  const publicUrl = process.env.PUBLIC_URL;
  const { authUser } = useAuth();

  return (
    <div
      className="tab-pane fade active show"
      id="nav-application"
      role="tabpanel"
      aria-labelledby="nav-application-tab"
    >
      <div className="applicationForm">
        <div className="print-header printheaderrr" style={{ display: "none" }}>
          <img src={`${publicUrl}/assets/images/logoo.png`} alt="Logo" />
        </div>

        <div className="applicationForm-header text-center fontttsizeprint add2-application-header">
          <h4>
            {" "}
            মেধাস্বত্ব হস্তান্তর / লাইসেন্স প্রদান সংক্রান্ত দলিল রেজিস্ট্রেশন
            ফরম
          </h4>
        </div>

        <div className="applicationForm-body">
          <div className="applicationForm-body-top mt-5">
            <div className="d-flex justify-content-between">
              <div className="div">
                <p className="mb-0 mt-2">বরাবর</p>
                <p className="unbold">
                  রেজিস্ট্রার অব কপিরাইটস <br />
                  বাংলাদেশ কপিরাইট অফিস।
                </p>
              </div>
              <div className="div">
                <p className="mb-0 mt-2">
                  {" "}
                  ট্র্যাকিং নাম্বার : <span> {mainData?.trackReg} </span>{" "}
                </p>
              </div>
            </div>

            <p className="mb-0">জনাব</p>
            <p className="unbold">
              কপিরাইট আইন (২০২৩ সালের ৩৪ নং আইন এবং সংশোধিত- ২০০৫) এর ৫৪ ধারা
              অনুযায়ী আমি মেধাস্বত্ব হস্তান্তর / লাইসেন্স প্রদান সংক্রান্ত দলিল
              নিবন্ধনের জন্য আবেদন করছি এবং দলিল নিবন্ধন বহিতে হস্তান্তরিত কর্ম
              সংক্রান্ত তথ্যসমূহ লিপিবদ্ধ করার জন্য দুইকপি সংশ্লিষ্ট কর্ম ও
              বিবরণী সংযুক্ত করছি।
            </p>

            <div className="my-3">
              <div className="row">
                <div class="col-sm-3 col-lg-3 col-md-3">
                  <p className="mb-0">১. আবেদন কারীর ধরণ -</p>
                </div>

                <div className="col-sm-9 col-lg-9 col-md-9">
                  <label className="radio-inline">
                    <input
                      name="sourceee"
                      type="radio"
                      value="1"
                      checked={Number(mainData?.teamType) === 1}
                      readOnly
                    />{" "}
                    ক. সত্মনিয়োগ কর্ম
                  </label>
                  <label className="radio-inline mx-2">
                    <input
                      name="sourceee"
                      type="radio"
                      value="2"
                      checked={Number(mainData?.teamType) === 2}
                      readOnly
                    />{" "}
                    খ. লাইসেন্স প্রদান কর্ম
                  </label>
                </div>
              </div>
            </div>

            <div className="my-2">
              <div className={styles.add2sectiontitle}>
                <p className="mb-1"> ২. সংযুক্তিঃ</p>
              </div>
              <div className="table-responsive">
                <table className="table text-center table-striped mb-2 table-bordered hover">
                  <thead>
                    <tr>
                      <th>আবেদনকারীর ছবি</th>
                      <th>পরিচয় পত্রের স্ক্যান কপি</th>
                      <th>মূল দলিল ও নোটারি পাবলিক ফটোকপি</th>
                      <th>ট্রেড লাইসেন্সের স্ক্যান কপি</th>
                      <th>টিন সার্টিফিকেটের স্ক্যান কপি</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {[
                        { label: "applicant" },
                        { label: "nid" },
                        { label: "istructure" },
                        { label: "tradelicense" },
                        { label: "tincertificate" },
                      ].map(({ label }, index) => (
                        <td key={label}>
                          {index === 0 ? (
                            <div className={`${styles.imagePreviewAdd2}`}>
                              {mainData?.[label] ? (
                                <>
                                  <img
                                    src={mainData[label]}
                                    alt={label}
                                    style={{ width: "100px", height: "auto" }}
                                  />
                                </>
                              ) : (
                                <>
                                  <span className="custombtn55 d-none printiconn">
                                    <img
                                      src={`${publicUrl}/assets/images/cros.png`}
                                      alt="cros Icon"
                                    />
                                  </span>
                                  <span className="custombtn55 printnone">
                                    কোনো ফাইল নেই
                                  </span>
                                </>
                              )}
                            </div>
                          ) : (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => handlePreview(mainData?.[label])}
                            >
                              <div className={`${styles.imagePreviewAdd2}`}>
                                {mainData?.[label] ? (
                                  <>
                                    <span className="custombtn55 d-none printiconn">
                                      <img
                                        src={`${publicUrl}/assets/images/check.png`}
                                        alt="Check Icon"
                                      />
                                    </span>
                                    <span className="custombtn55 printnone">
                                      এখানে দেখুন
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span className="custombtn55 d-none printiconn">
                                      <img
                                        src={`${publicUrl}/assets/images/cros.png`}
                                        alt="cros Icon"
                                      />
                                    </span>
                                    <span className="custombtn55 printnone">
                                      কোনো ফাইল নেই
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
              {previewModal && (
                <ShowFilePreviewModal
                  file={previewFile}
                  onClose={() => setPreviewModal(false)}
                />
              )}
            </div>
          </div>

          <div className="applicationForm-body-bottom mt-5">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="form-group">
                  <div className="div">
                    <p className="border-bottom-dotted">
                      {mainData?.user?.email}
                    </p>
                  </div>
                  <div className="div">
                    <p className="border-bottom-dotted">{mainData?.place}</p>
                  </div>
                  <div className="div">
                    <p className="">
                      {mainData?.sendDate &&
                        localDateFormat(mainData?.sendDate)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-4"></div>

              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="form-group text-center">
                  <div className="div">
                    <p className="border-bottom-dotted mb-2">আপনার বিশ্বস্ত</p>
                  </div>
                  <div className="mb-1 attachment-container">
                    {mainData?.sign && (
                      <img
                        className="img-fluid"
                        src={mainData?.sign}
                        alt="Signature Preview"
                      />
                    )}
                  </div>
                  <div>
                    <p>{mainData?.puname}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {previewModal && (
            <ShowFilePreviewModal
              setPreviewModal={setPreviewModal}
              previewModal={previewModal}
              previewUrl={previewFile}
              setPreviewUrl={setPreviewFile}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AgreementPreviewFormOne;
