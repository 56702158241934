import { RestartAlt } from "@mui/icons-material";
import moment from "moment";
import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import Select from "react-select";
import { globalDateFormat } from "../../../../utils/dateFormat";
import BanglaCalender from "../../../frontend/userPanel/form_two/BanglaCalender";

const CommonAgreementCopyright = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  appType,
  setAppType,
  trackReg,
  setTrackReg,
  status,
  setStatus,
}) => {
  const currentDate = moment().format("YYYY-MM-DD"); // বর্তমান তারিখ
  const threeMonthsAgo = moment().subtract(3, "months").format("YYYY-MM-DD");

  const appTypeOption = [
    { label: "কপিরাইট", value: 1 },
    { label: "চুক্তিপত্র", value: 2 },
  ];

  const statusOptions = [
    { label: "Complete", value: "Complete" },
    { label: "Processing", value: "Processing" },
    { label: "Pending", value: "Pending" },
    { label: "Failed", value: "Failed" },
    { label: "Canceled", value: "Canceled" },
  ];

  const handleDateFromChange = (d) => {
    setFromDate(globalDateFormat(d));
  };

  const handleDateToChange = (d) => {
    setToDate(globalDateFormat(d));
  };

  const handleReset = () => {
    setFromDate(threeMonthsAgo);
    setToDate(currentDate);
    setAppType("");
    setTrackReg("");
    setStatus("");
  };

  return (
    <>
      <div>
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          }}
          className="rounded mb-3 p-2"
        >
          <div className="row gap-2 gap-md-0">
            <div className="col-md-6 m-0">
              <div className="d-flex gap-4 align-items-center">
                <BanglaCalender
                  placeholder={"তারিখ"}
                  value={fromDate}
                  onChange={handleDateFromChange}
                />
                <span>হইতে</span>
                <BanglaCalender
                  placeholder={"তারিখ"}
                  value={toDate}
                  onChange={handleDateToChange}
                />
              </div>
            </div>
            <div className="col-md-2 m-0">
              <Select
                options={appTypeOption}
                value={
                  appType
                    ? appTypeOption.find((option) => option.value === appType)
                    : { value: "", label: "--- টাইপ ---", isDisabled: true }
                }
                onChange={(selectedOption) => {
                  setAppType(selectedOption.value);
                }}
                classNamePrefix="react-select"
              />
            </div>

            <div className="col-md-2 m-0">
              <div className="d-flex justify-content-end">
                <input
                  placeholder="আবেদন নং"
                  type="text"
                  className="form-control"
                  value={trackReg}
                  onChange={(e) => {
                    setTrackReg(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="col-md-2 m-0">
              <div className="d-flex justify-content-end">
                <Button
                  onClick={handleReset}
                  variant="danger"
                  className="w-100 text-center"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <RestartAlt /> Reset
                  </div>
                </Button>
              </div>
            </div>

            <div className="col-md-12 m-0 mt-3">
              <ButtonGroup>
                {statusOptions.map((option) => (
                  <Button
                    key={option.value}
                    variant={
                      status === option.value
                        ? "secondary"
                        : "outline-secondary"
                    }
                    onClick={() => setStatus(option.value)}
                  >
                    {option.label}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonAgreementCopyright;
