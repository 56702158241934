import { useFormik } from "formik";
import React, { useState } from "react";
import { ImPlus } from "react-icons/im";
import { IoCloseSharp } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { assets } from "../../../../../assets";
import ClientFormikFormFieldMultiple from "../../../../../components/custom/form/ClientFormikFormFieldMultiple";
import {
  useDeleteApplicationFormOneMutation,
  useFindApplicationFormOneQuery,
} from "../../../../../redux/api/copyrightApplicationFormOneApiSlice";
import { useAuth } from "../../../../../utils/authorization";
import { localDateFormat } from "../../../../../utils/dateFormat";
import styles from "./ApplicationForCopyright.module.css";

export default function ApplicationForCopyright() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const editId = query.get("id");
  const {
    data: editData,
    isLoading: isLoadingEdit,
    refetch: refetchEdit,
  } = useFindApplicationFormOneQuery(editId);
  const [isPreview, setIsPreview] = useState(null);
  const navigate = useNavigate();

  const { authUser } = useAuth();
  const [sideHas, setSideHas] = useState(2);
  const [signature, setSignature] = useState(null);
  const publicUrl = process.env.PUBLIC_URL;

  const [
    deleteFormOne,
    {
      isError: isErrorDelete,
      error: errorDelete,
      isSuccess: isSuccessDelete,
      data: dataDelete,
    },
  ] = useDeleteApplicationFormOneMutation();

  const handleDeleteFormOne = (id, index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFormOne(id);
        handleRemoveFieldSet(index);
      }
    });
  };

  const toggleSideHas = (side) => {
    setSideHas(side);
    setValues((prevValues) => ({
      ...prevValues,
      teamType: side,
      profileteamtypes: [
        {
          teamName: "",
          teamAddress: "",
          teamEmail: "",
          teamMobile: "",
          teamSenddate: "",
        },
      ],
    }));
  };

  const { values, touched, errors, handleBlur, handleChange, setValues } =
    useFormik({
      initialValues: {
        teamType: 2,
        communicationAddress:
          "গ্রামঃ রামনগরের চর, পোঃ আদদিয়া, থানাঃ নড়াইল, জেলাঃ নড়াইল, মোবাইল নংঃ ০১৫৩৪-২৪৯৮৩৩,",
        place: "ঢাকা ।",
        puname: "মোঃ শাহনেওয়াজ চৌধুরী",
        sign: "",
        profileteamtypes: [
          {
            teamName: "মোঃ শাহনেওয়াজ চৌধুরী",
            teamAddress:
              "গ্রামঃ রামনগরের চর, পোঃ আদদিয়া, থানাঃ নড়াইল, জেলাঃ নড়াইল।",
            teamEmail: "np_123@gmail.com",
            teamMobile: "+৮৮০১৫৩৪-২৪৯৮৩৩",
            teamSenddate: "২৪/০৪/২০১৭",
          },
        ],
      },
      onSubmit: async (values, { setSubmitting }) => {
        //
      },
    });

  const handleAddFieldSet = () => {
    const newRow = {
      teamName: "",
      teamAddress: "",
      teamEmail: "",
      teamMobile: "",
      teamSenddate: "",
    };
    setValues((prevValues) => ({
      ...prevValues,
      profileteamtypes: [...prevValues.profileteamtypes, newRow],
    }));
  };

  const handleRemoveFieldSet = (index) => {
    const filteredRows = values.profileteamtypes.filter(
      (_, idx) => idx !== index
    );
    setValues({ ...values, profileteamtypes: filteredRows });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSignature(file);
    if (file) {
      setValues((prevValues) => ({
        ...prevValues,
        sign: file,
      }));
    }
  };

  const toDay = localDateFormat(new Date());
  return (
    <div>
      <section className="section py-5  service-single">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <div className="card border-0 w-100">
              <div
                className="tab-content rounded border py-4 paddddingnone px-5 border-1"
                id="nav-tabContent"
              >
                <div className="text-end me-4">
                  <div className="">
                    {" "}
                    <img
                      src={`${publicUrl}/assets/images/p1.png`}
                      alt="Logo"
                      style={{
                        height: "70px",
                        width: "60px",
                        marginTop: "-47px",
                      }}
                      className="img-fluid pataresponsive"
                    />{" "}
                  </div>
                </div>

                <div
                  className="tab-pane fade active show"
                  id="nav-application"
                  role="tabpanel"
                  aria-labelledby="nav-application-tab"
                >
                  <div className="applicationForm">
                    <div
                      style={{ marginTop: "-20px" }}
                      className="applicationForm-header text-center"
                    >
                      <h5 className="mb-0 form2fontsizeee">ফরম - ১.</h5>
                      <b className="form2fontsizeee2">
                        কপিরাইট রেজিস্ট্রেশনের আবেদন পত্র
                      </b>
                      <h5 className="mt-1 form2fontsizeee">
                        [ বিধি ৪ (১) দ্রষ্টব্য ]
                      </h5>
                    </div>
                    <div className="applicationForm-body">
                      <div className="applicationForm-body-top">
                        <div className="d-flex justify-content-between">
                          <div className="div">
                            <p className="mb-0 mt-2">
                              <b>বরাবর</b>
                            </p>
                            <p className="unbold">
                              রেজিস্ট্রার অব কপিরাইটস <br />
                              বাংলাদেশ কপিরাইট অফিস।
                            </p>
                          </div>
                        </div>

                        <p className="mb-0">
                          <b>জনাব,</b>
                        </p>
                        <p className="unbold">
                          কপিরাইট আইন (২০২৩ সালের ৩৪ নং আইন এবং সংশোধিত- ২০০৫)
                          এর ৫৪ ধারা অনুযায়ী আমি মেধাস্বত্ব হস্তান্তর / লাইসেন্স
                          প্রদান সংক্রান্ত দলিল নিবন্ধনের জন্য আবেদন করছি এবং
                          দলিল নিবন্ধন বহিতে হস্তান্তরিত কর্ম সংক্রান্ত তথ্যসমূহ
                          লিপিবদ্ধ করার জন্য দুইকপি সংশ্লিষ্ট কর্ম ও বিবরণী
                          সংযুক্ত করছি।
                        </p>

                        <p className="mb-0">
                          ১. এই কর্মের সঙ্গে সম্পর্কযুক্ত অন্যান্য বিষয়াবলীর
                          বিবরণী ও যথাযথভাবে পূরণ করিয়া পাঠাইলাম ।{" "}
                        </p>

                        <div className="d-flex mt-2">
                          <p className="mb-0 ">২. কোন পক্ষ আছে কি ?</p>

                          <span className="d-flex mx-3">
                            <div className="form-check mx-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="sideRadio"
                                id="sideRadio1"
                                checked={Number(values.teamType) === 1}
                                onClick={() => toggleSideHas(1)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="sideRadio1"
                              >
                                হ্যাঁ
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="sideRadio"
                                id="sideRadio2"
                                checked={Number(values.teamType) === 2}
                                onClick={() => toggleSideHas(2)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="sideRadio2"
                              >
                                না
                              </label>
                            </div>
                          </span>
                        </div>
                        {Number(values.teamType) === 1 && (
                          <div>
                            {values?.profileteamtypes?.map((item, index) => (
                              <div key={index} className="isSideHas my-2">
                                <div className="row  pb-3 border-bottom">
                                  <div className="col-md-4 my-1">
                                    <div className="form-group">
                                      <label>পক্ষের নাম</label>
                                      <ClientFormikFormFieldMultiple
                                        value={"মোঃ শাহনেওয়াজ চৌধুরী"}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 my-1">
                                    <div className="form-group">
                                      <label>পক্ষের ঠিকানা </label>
                                      <ClientFormikFormFieldMultiple
                                        value={
                                          "গ্রামঃ রামনগরের চর, পোঃ আদদিয়া, থানাঃ নড়াইল, জেলাঃ নড়াইল।"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 my-1">
                                    <div className="form-group">
                                      <label>
                                        প্রেরণের তারিখ / ডেসপাচের তারিখ
                                        <ClientFormikFormFieldMultiple
                                          value={"২৪/০৪/২০১৭"}
                                        />
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-4 my-1">
                                    <div className="form-group">
                                      <label>ই-মেইল</label>

                                      <ClientFormikFormFieldMultiple
                                        placeholder="ই-মেইল"
                                        value={item.teamEmail}
                                        name={`profileteamtypes[${index}].teamEmail`}
                                        type="email"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 my-1">
                                    <div className="form-group">
                                      <label>মোবাইল নাম্বার</label>
                                      <ClientFormikFormFieldMultiple
                                        value={"np_123@gmail.com"}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 my-1">
                                    <div className="form-group centeraddremovebtn addremovemargin mt-4">
                                      <div className="">
                                        {(values.profileteamtypes.length ===
                                          index + 1 ||
                                          values.profileteamtypes.length ===
                                            1) && (
                                          <ImPlus
                                            className="customAddButton rounded"
                                            onClick={handleAddFieldSet}
                                          />
                                        )}

                                        {values.profileteamtypes.length > 1 &&
                                          index !== 0 && (
                                            <IoCloseSharp
                                              className="customRemoveButton rounded"
                                              onClick={() => {
                                                if (item.id) {
                                                  handleDeleteFormOne(
                                                    item.id,
                                                    index
                                                  );
                                                } else {
                                                  handleRemoveFieldSet(index);
                                                }
                                              }}
                                            />
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        <br />
                        <p className="mb-3">
                          কপিরাইট বিধিমালা, ২০০৬ এর বিধি-৪ এর উপ-বিধি (৩)
                          অনুযায়ী আমি এই পত্র এবং তদসংযুক্ত বিবরণীসমূহের কপি
                          নিম্নোক্ত পদ্ধতিতে অন্যান্য পক্ষের নিকট হাতে
                          হাতে/অগ্রিম রেজিস্টার্ড ডাকযোগে প্রেরণ করিয়াছি"
                          শব্দগুলির স্থলে "কপিরাইট বিধিমালা, ২০০৬ এর ৪(৩) উপ-
                          বিধি অনুযায়ী, অনলাইনে দাখিলকৃত আবেদনপত্রের হার্ডকপি
                          বাংলাদেশ কপিরাইট অফিসে দাখিলের পূর্বে আমি সংশ্লিষ্ট
                          কর্মের কপিরাইট স্বত্বের সহিত স্বার্থসংশ্লিষ্ট সকল
                          ব্যক্তির নিকট আবেদনপত্রের কপি এবং তদসংযুক্ত
                          বিবরণীসমূহের কপি হাতে হাতে। রেজিস্টার্ড ডাকযোগে প্রেরণ
                          করিব এবং উক্ত কার্যের প্রমাণক আবেদনপত্রের হার্ডকপির
                          সহিত বাংলাদেশ কপিরাইট অফিসে দাখিল করিব
                        </p>

                        <p className="m-0">৩. যোগাযোগের ঠিকানা :</p>
                        <div className="row pb-5 pt-1">
                          <div className="col-md-12">
                            <div className="applicationAddress">
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  rows="4"
                                  name="communicationAddress"
                                  value={values.communicationAddress}
                                ></textarea>
                                {touched.communicationAddress &&
                                  errors.communicationAddress && (
                                    <small
                                      className="text-danger mt-1"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {errors.communicationAddress}
                                    </small>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="applicationForm-body-bottom">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="applicationUserInfoPartLeft mt-10">
                              <div className="form-group">
                                <input
                                  type="email"
                                  defaultValue={"np_123@gmail.com"}
                                  disabled
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group my-3">
                                <ClientFormikFormFieldMultiple
                                  placeholder="স্থানের নাম লিখুন"
                                  value={values.place}
                                  name={"place"}
                                />
                              </div>

                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={toDay}
                                  disabled
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4"></div>

                          <div className="col-md-4">
                            <div className="applicationUserInfoPartRight">
                              <div className="text-center">
                                <p className="mb-0 mt-1">
                                  আপনার বিশ্বস্ত :{" "}
                                  <span className="text-danger">*</span>{" "}
                                </p>
                              </div>
                              <div className="form-group">
                                <ClientFormikFormFieldMultiple
                                  placeholder="নাম লিখুন"
                                  value={values.puname}
                                  name={"puname"}
                                />
                              </div>
                              <div className="form-group text-center">
                                <label>স্বাক্ষর (200px * 80px)</label>
                                <br />
                                <div className={styles.imagePreviewSignature}>
                                  <img
                                    src={assets.sign2}
                                    id="Signature"
                                    alt="Preview"
                                  />
                                </div>

                                <div>
                                  {touched.sign && errors.sign && (
                                    <small
                                      className="text-danger mt-0"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {errors.sign}
                                    </small>
                                  )}
                                </div>

                                <label className="custombtnn5">
                                  <input
                                    type="file"
                                    className="d-none"
                                    name="sign"
                                    onChange={handleFileChange}
                                    accept=".jpg, .jpeg, .png"
                                  />
                                  {signature || editData?.data?.sign
                                    ? "পরিবর্তন করুন"
                                    : "নির্বাচন করুন"}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
