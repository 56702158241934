import {
  ACTIVITY_COUNT_HISTORY,
  AGREEMENTS_REPORT,
  AGREEMENTS_REPORT_PRINT,
  APPLICATIONS_REPORT,
  APPLICATIONS_REPORT_PRINT,
  APPLICATIONS_TRACK,
  CALENDAR_OF_HEARINGS,
  COUNT_BY_FILE_STATUS_GROUP,
  PAYMENTS_LIST,
  PAYMENTS_VIEW,
  REPORT_FILE_ACTIVITY_LIST,
  SHOW_DATE_OF_HEARING,
  USER_TO_USER_FILE_TRANSFER,
  USERS_BY_ROLE_WITH_FILE_COUNT,
} from "../constants";
import { apiSlice } from "./apiSlice";

export const reportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFileActivity: builder.query({
      query: ({ sendFrom = "", page = 1, sendTo = "", date = "" }) => {
        let url = `${REPORT_FILE_ACTIVITY_LIST}?page=${page}&per_page=10`;
        if (sendTo) {
          url += `&sendTo=${sendTo}`;
        }
        if (sendFrom) {
          url += `&sendFrom=${sendFrom}`;
        }
        if (date) {
          url += `&date=${date}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
    }),
    getUserFileCount: builder.query({
      query: ({ role_id = "", user_id = "", appType = "" }) => {
        let url = `${USERS_BY_ROLE_WITH_FILE_COUNT}?appType=${appType}`;
        if (user_id) {
          url += `&user_id=${user_id}`;
        }
        if (role_id) {
          url += `&role_id=${role_id}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
    }),
    getActivityCountHistory: builder.query({
      query: ({ date = "", user_id = "" }) => {
        let url = `${ACTIVITY_COUNT_HISTORY}?user_id=${user_id}`;

        if (date) {
          url += `&date=${date}`;
        }
        return {
          url: url,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
    }),
    getFileStatusGroup: builder.query({
      query: (user_id) => {
        let url = `${COUNT_BY_FILE_STATUS_GROUP}?user_id=${user_id || ""}`;
        return {
          url: url,
          method: "GET",
        };
      },
      providesTags: ["file_transfer"],
    }),
    // USER_TO_USER_FILE_TRANSFER
    userToUserFileTransfer: builder.mutation({
      query: (formData) => {
        let url = `${USER_TO_USER_FILE_TRANSFER}`;
        return {
          url: url,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["file_transfer"],
    }),
    // CALENDAR_OF_HEARINGS
    getCalendarOfHearings: builder.query({
      query: (date) => {
        return {
          url: CALENDAR_OF_HEARINGS,
          method: "GET",
          params: { date },
        };
      },
    }),
    // SHOW_DATE_OF_HEARING
    getDateOfHearing: builder.query({
      query: (date) => {
        return {
          url: SHOW_DATE_OF_HEARING,
          method: "GET",
          params: { date },
        };
      },
    }),
    // agreements reports
    getAgreementReports: builder.query({
      query: ({ fromDate, toDate, category_id }) => ({
        url: AGREEMENTS_REPORT,
        method: "GET",
        params: { fromDate, toDate, category_id },
      }),
    }),
    // agreements reports
    getAgreementReportsPrint: builder.query({
      query: ({ fromDate, toDate }) => ({
        url: AGREEMENTS_REPORT_PRINT,
        method: "GET",
        params: { fromDate, toDate },
      }),
    }),
    // COPYRIGHT reports
    getCopyrightReports: builder.query({
      query: ({ fromDate, toDate, category_id }) => ({
        url: APPLICATIONS_REPORT,
        method: "GET",
        params: { fromDate, toDate, category_id },
      }),
    }),
    // COPYRIGHT reports
    getCopyrightReportsPrint: builder.query({
      query: ({ fromDate, toDate }) => ({
        url: APPLICATIONS_REPORT_PRINT,
        method: "GET",
        params: { fromDate, toDate },
      }),
    }),

    getApplications: builder.query({
      query: ({ trackReg }) => ({
        url: APPLICATIONS_TRACK,
        method: "GET",
        params: { trackReg },
      }),
    }),
    // PAYMENTS_LIST
    getPaymentsList: builder.query({
      query: ({ trackReg, fromDate, toDate, status, filter, appType }) => ({
        url: PAYMENTS_LIST,
        method: "GET",
        params: { trackReg, fromDate, toDate, status, filter, appType },
      }),
      keepUnusedDataFor: 5,
    }),
    // PAYMENTS_VIEW
    getPaymentView: builder.query({
      query: (id) => ({
        url: `${PAYMENTS_VIEW}/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useGetFileActivityQuery,
  useGetUserFileCountQuery,
  useGetActivityCountHistoryQuery,
  useGetFileStatusGroupQuery,

  useLazyGetActivityCountHistoryQuery,
  useLazyGetFileStatusGroupQuery,

  useUserToUserFileTransferMutation,
  // other exported functions...
  // CALENDAR_OF_HEARINGS
  useGetCalendarOfHearingsQuery,
  // SHOW_DATE_OF_HEARING
  useLazyGetDateOfHearingQuery,
  // agreements reports
  useGetAgreementReportsQuery,
  useLazyGetAgreementReportsPrintQuery,
  // COPYRIGHT reports
  useGetCopyrightReportsQuery,
  useLazyGetCopyrightReportsPrintQuery,
  // APPLICATIONS_TRACK
  useLazyGetApplicationsQuery,
  // PAYMENTS_LIST
  useGetPaymentsListQuery,
  // PAYMENTS_VIEW
  useGetPaymentViewQuery,
} = reportApiSlice;
